import React from "react";

export class Table extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (

            <div className="table abstractComponent">


                <div data-t-name="Table" className="m-table table-responsive " data-t-id="14">
                    <table style={ { tableLayout: 'fixed'}}>
                        <tbody>
                        <tr className="m-table__fake-head">
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                            <th style={{ width: '190px'}}></th>
                        </tr>
                        <tr className="m-table__scroll">
                            <td colSpan="9">
                                <div className="m-table__scroll-arrows" style={ {left: '30px', width: '1080px'} }>
                                    <i className="m-table__scroll-previous m-table__scroll-previous--disabled"></i>
                                    <i className="m-table__scroll-next"></i>
                                </div>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Example of a table</b></td>
                            <td><b>with a second column</b></td>
                            <td><b>and a third column</b></td>
                            <td><b>and many more columns</b></td>
                            <td><b>and many more columns</b><br />
                            </td>
                            <td><b>and many more columns</b><br />
                            </td>
                            <td><b>and many more columns</b><br />
                            </td>
                            <td><b>and many more columns</b><br />
                            </td>
                            <td><b>and many more columns</b><br />
                            </td>
                        </tr>
                        <tr>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                        </tr>
                        <tr>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem <a title="link" className="a-link--external"
                                         href="/">ipsum
                                dolor</a> sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem <b>ipsum </b>dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                        </tr>
                        <tr>
                            <td>Lorem ipsum dolor <b>sit amet, consetetur sadipscing elitr.</b><br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                            <td>amet, consetetur sadipscing elitr.<br />
                            </td>
                            <td>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</td>
                        </tr>
                        </tbody>
                    </table>

                </div>


            </div>
        )
    }
}