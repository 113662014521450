import cookie from 'react-cookies';
import jwtDecode from "jwt-decode";

export default class Auth
{
    static jwt;
    static isAuthenticated()
    {
        if(process.env.NODE_ENV !== 'development')
            return false;
        if(!this.jwt)
            this.jwt = cookie.load('jwt');

        if(!this.jwt)
            return false;

        const decoded = jwtDecode(this.jwt);
        if (Date.now() >= decoded.exp * 1000) {
            return false;
        }

        return true;
    }
    static getToken()
    {
        if(!this.jwt)
            this.jwt = cookie.load('jwt');

        return this.jwt;
    }

    static setToken(jwt)
    {

        this.jwt = jwt;
        if(!jwt)
        {
            cookie.remove('jwt');
        } else {
            cookie.save('jwt', jwt);

        }
        return this.jwt;
    }

    static logout()
    {

        cookie.remove('jwt');
        this.jwt = undefined;
    }
}