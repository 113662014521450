import React from "react";
import EventItem from "./EventItem";
import Strapi from "strapi-sdk-javascript";
import Moment from "react-moment";
import InfiniteScroll from 'react-infinite-scroller';
import '../../../styles/makaio.css'
import {createEventFilterQueryString} from "../../../helper";

const moment = require('moment');

export default class EventItems extends React.Component {
    constructor(props) {
        super(props);

        if(props.events)
        {

            /*
            for(let item of props.events)
            {
                if(eventYears.indexOf(item.eventyear) < 0)
                {
                    eventYears.push(item.eventyear);
                }
            }
            debugger;
            */

        }

        let eventYears = [];
        let localeKey = props.langKey;
        if(localeKey === 'se')
            localeKey = 'sv';

        moment.locale(localeKey);
        let newDateObj = moment(new Date()).add(18, 'M').toDate();
        let firstYear= moment(new Date()).year();
        let lastYear = newDateObj.getFullYear();

        for(let i = firstYear; i <= lastYear; i++)
        {
            eventYears.push(i);
        }

        this.pageSize = 25;
        let pageStart = 0;

        /*
        if(props.events && props.events.length > 0)
        {
            pageStart = props.events.length / this.pageSize;
        }
        debugger;*/

        this.state = {
            events: props.events || [],
            hasMoreEvents: false,
            searchQuery: props.searchQuery,
            pageIndex: pageStart,
            filter: props.filter,
            eventYears: eventYears
        };

        this.loadMore = this.loadMore.bind(this);
        this.generateList = this.generateList.bind(this);



    }
    loadMore(pageIndex) {
        console.log(pageIndex);
        this.getEvents(pageIndex);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.isTeaser && prevState.events !== nextProps.events)
        {
            return {
                events: nextProps.events
            }
        }
        if(nextProps.searchQuery !== prevState.searchQuery || nextProps.filter !== prevState.filter)
        {
            return {
                searchQuery: nextProps.searchQuery,
                events: [],
                pageIndex: 0,
                hasMoreEvents: false,
                filter: nextProps.filter
            };
        } else {
            return null;
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.searchQuery !== prevProps.searchQuery || this.props.filter !== prevProps.filter) {
            this.getEvents(0);
        }
    }
    getEvents(pageIndex, isInitial)
    {

        const strapi = new Strapi((process.env.STRAPI_PUBLIC_HOST));
        const self = this;

       let searchQuery = createEventFilterQueryString(self.state.filter, self.state.searchQuery, self.pageSize, pageIndex);

        strapi.request("GET", `eventsearch/${searchQuery}`).then(
            (res) => {
                let existingItems = (isInitial) ? [] : this.state.events;

                let newItems = existingItems.concat(res);
                let eventYears = this.state.eventYears;

                for(let item of newItems)
                {
                    if(eventYears.indexOf(item.eventyear) < 0)
                    {
                        eventYears.push(item.eventyear);
                    }
                }

                this.setState({
                    events: newItems,
                    eventYears: eventYears,
                    hasMoreEvents: res.length > 0
                });

            }
        );
    }
    componentDidMount() {
        if(!this.props.isTeaser)
        {
            if(this.state.events.length > 0)
            {
                this.getEvents(0, true);
            } else {
                this.getEvents(0);
            }
        }

    }
    generateList()
    {
        let content = [];
        const self = this;
        let lastStartMonth;
        this.state.events.forEach((event, idx) => {

            const startMonth = event.startdate.substr(5,2);

            if(!self.props.isTeaser && (!lastStartMonth || startMonth !== lastStartMonth))
            {
                content.push(
                    <div key={ `month_${event.id}`} className="ex-search-result-segment"><h1
                        className="ex-search-result-segment__label"><Moment format="MMMM">{event.startdate}</Moment></h1>
                    </div>
                );
                lastStartMonth = startMonth;
            }
            content.push(
                <EventItem item={ event } langKey={this.props.langKey} key={ `event_${event.id}` } right={ (idx+1) % 2 === 0 } />
            );

        });
        return content;
    }
    render() {
        return (
            <div className={ this.props.className || "ex-event-search-results" }>
                <div>
                    <div>
                        {
                            typeof document === 'undefined' ?  this.generateList() : (
                                <InfiniteScroll
                                    pageStart={ this.state.pageIndex}
                                    loadMore={this.loadMore }
                                    hasMore={ this.state.hasMoreEvents }
                                    key={this.state.searchQuery}
                                    loader={<div className="loader" key={0}>...</div>}
                                    useWindow={ typeof document !== 'undefined' }
                                    threshold={1000}
                                >
                                    {
                                        this.generateList()
                                    }
                                </InfiniteScroll>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}