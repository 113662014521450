import React from "react";
import Auth from "../../modules/Auth";
import strapi from "../../helper";
import {Link} from "react-router-dom";


let EditTools;
let HeadlineEditor;
if(process.env.NODE_ENV !== 'production') {
    EditTools = require('../admin/ContentEditTools').default;
    HeadlineEditor = require('../admin/HeadlineEditor').default;
}


const regex = /<[^>]*>/gm;

export default class PageHeader extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            isEditing: Auth.isAuthenticated(),
            inSettings: this.props.inSettings
        };
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleHeadlineChange = this.handleHeadlineChange.bind(this);

        this.handleSettingsClick = this.handleSettingsClick.bind(this);
        this.handleSettingsCancel = this.handleSettingsCancel.bind(this);
        this.handleSettingsSave = this.handleSettingsSave.bind(this);
        this.editedValue = null;
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.inSettings !== prevState.inSettings)
        {
            return {
                inSettings: nextProps.inSettings
            }
        } else {
            return null;
        }
    }
    handleSettingsClick()
    {
        this.props.onShowSettings();
    }
    handleSettingsCancel()
    {
        this.props.onSettingsCancel();
    }
    handleSettingsSave()
    {
        this.props.onSettingsSave();
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {
            let val = this.editedValue;
            if(val)
            {
                val = val.replace(regex, '');

                if(this.props.language.id === this.props.primaryLanguage.id)
                {
                    await strapi.updateEntry("pages", this.props.page.id, {
                        Pagetitle: val
                    } );
                } else {
                    let contentUpdate = this.props.page.translations;
                    delete contentUpdate[this.props.primaryLanguage.short];
                    if(!contentUpdate[this.props.language.short])
                        contentUpdate[this.props.language.short] = {};

                    contentUpdate[this.props.language.short].Pagetitle = val;

                    await strapi.updateEntry("pages", this.props.page.id, {
                        ContentTranslation: contentUpdate
                    } );
                }

                this.props.page.Pagetitle = val;
                this.setState({
                    edit: false
                });

                setTimeout(async () =>  {
                    await strapi.request("GET", `pages/${this.props.page.id}/rebuild`);

                }, 100);

            } else {
                this.setState({
                    edit: false
                });
            }


        } else {
            this.setState({
                edit: true
            });
        }
    }
    handleCancelClick()
    {
        this.setState({
            edit: false
        });
    }
    handleHeadlineChange(val)
    {
        this.editedValue = val;
    }
    render(){

        let content;

        if(this.state.edit)
        {
            content = <HeadlineEditor onChange={this.handleHeadlineChange} headlineTag="h1" headline={this.props.page.Pagetitle || this.props.page.Navigationtitle} />;
        } else {
            content =  <h1
                dangerouslySetInnerHTML={ {__html: this.props.page.Pagetitle || this.props.page.Navigationtitlee } } />;
        }


        return (
            <div id="heading">

                <div className="m-page-heading" data-t-name="PageHeading" data-t-id="9">
                    <div className="container">
                        {
                            (!this.state.isEditing) ?  null :
                                <EditTools edit={this.state.edit}
                                           inSettings={ this.state.inSettings }
                                           showSettings={(this.props.page.PageType === "Normal")}
                                           onCancel={ this.handleCancelClick }
                                           onEditClick={this.handleEditClick }
                                           onSettingsClick={ this.handleSettingsClick }
                                           onSettingsCancel={this.handleSettingsCancel}
                                           onSettingsSave={this.handleSettingsSave}
                                />
                        }

                        { content }
                    </div>
                </div>
            </div>
        )
    }
}
