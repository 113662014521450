import React from "react";
import strapi, {mf_ImageSizes, ResponsiveImage} from "../../../helper";
import Auth from "../../../modules/Auth";
import {ModuleHead} from "../ModuleHead";
import {DataBinder} from "../../admin/DataBinder";
import {replaceLinks} from "../../../containers/Page";
import EditableModule from "../Module";

let ContentEditTools;
let RichTextEditor;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
     ContentEditTools = require('../../admin/ContentEditTools').default;
     RichTextEditor = require('../../admin/RichTextEditor').default;
    useDropzone = require('react-dropzone').useDropzone;
}



function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
            accept: 'image/png, image/jpg, image/jpeg'
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                {
                    (img) ? <ResponsiveImage width="200px" img={img}/> : (
                        <span>Drop a file here, or click to select a file from your local files</span>)

                }
            </div>
        )
    }
}

export class TextImage extends EditableModule {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            dataBinder: null
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }
    static createForReference(refName)
    {
        return <TextImage blockEdit={true} referenceName={refName} content={
            {
                headline: 'Text and Image',
                image: {
                    hash: '4a47d8e494014edda20d447b9aa3d3f0',
                    ext: '.jpg'
                },
                text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. \n' +
                    '</p><p>' +
                    'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>'}} siteUrls={[]} />;
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    handleRemoveImage()
    {
        this.state.dataBinder.bind('image').updateValue(null);
        this.forceUpdate();
    }
    async handleDrop(acceptedFiles)
    {

        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id
            };

            await strapi.request('GET', `/generateThumbnail/${uploadedFile.hash}${uploadedFile.ext}`);

            let imageBound = this.state.dataBinder.bind("image");

            imageBound.updateValue(uploadedFile);

            this.forceUpdate();
        }
    }
    async handleEditClick()
    {

        if(this.state.edit)
        {
            const changes = this.state.dataBinder.getChanges();

            if(changes)
            {
                let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = changes;

                } else {
                    contentUpdate = {

                        content: changes

                    };

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );

                this.exitEditState(changes);
            } else {
                this.cancelEditState();
            }

        } else {
            this.enterEditState();
        }
    }
    render()
    {
        if(this.state.content.image)
        {
            const imageUrl = this.state.content.image.hash + this.state.content.image.ext;
            this.imageUrl = `/content/uploads/300w/${imageUrl}`;
            this.imageSet = mf_ImageSizes.map((size) => {
                return  `/content/uploads/${size}w/${imageUrl} ${size}w`
            }).join(', ');
        } else {
        }

        let className = "imageText abstractComponent";

        let text = replaceLinks(this.state.content.text, this.props.siteUrls);

        let content = (<React.Fragment>


            <div className={ className } data-reference={ this.props.referenceName }>
                {
                    (!this.state.allowEdit) ?  null :
                        (
                            <ContentEditTools
                                edit={this.state.edit}
                                onCancel={ this.handleCancelClick }
                                onEditClick={this.handleEditClick }
                                onDelete={this.handleDelete }
                                onChange={this.handleHeadlineChange}
                                showMove={!this.state.edit}
                                showSettings={ false }
                                showDelete={this.props.userCreated}
                            />
                        )

                }
                <div className="m-text-image
     m-text-image--image-right

     clearfix" data-t-name="TextImage">
                <ModuleHead
                    edit={ this.state.edit }
                    binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                    headline={ this.state.content.headline } />
                    {
                        (this.state.edit) ? null : (
                            <div className="m-text-image__image "
                                 data-t-lightbox="0e120491a5708401181c9b7287347f72eb983e88ab562e37dfa8a9b7d23407d6">
                                <div className="a-image" data-t-name="Image" data-t-id="17">
                                    <figure>
                                        <img className="img-responsive"
                                             src={this.imageUrl}
                                             srcSet={this.imageSet}
                                             alt="" />

                                    </figure>
                                </div>
                            </div>
                        )
                    }


                    {
                        (this.state.edit) ? <div>
                                <RichTextEditor binding={ this.state.dataBinder.bind("text")} />
                            <div className="image-edit-container">

                                <strong>Image</strong><br/>
                                {
                                    (!this.state.dataBinder.bind("image").getValue()) ? null : (
                                        <div className="mo-edit-icon-container">
                                            <div className="mo-edit-icon-container-items">
                                                <div className="mo-edit-icon" onClick={this.handleRemoveImage}></div>
                                            </div>
                                        </div>
                                    )
                                }
                            <MyDropzone img={this.state.dataBinder.bind("image").getValue()} onDrop={this.handleDrop} />
                            </div>
                        </div> :
                            (
                                <div dangerouslySetInnerHTML={ {__html: text } }  />
                            )
                    }
                </div>

            </div>

        </React.Fragment>);


        return content;
    }
}
