import React from "react";
import '../../../styles/app.css'
import AccordionItem, {AccordionItemEdit} from "./AccordionItem";
import strapi from "../../../helper";
import Auth from "../../../modules/Auth";
import {ModuleHead} from "../ModuleHead";
import {DataBinder} from "../../admin/DataBinder";
import EditableModule from "../Module";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";

let ContentEditTools;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
}


const SortableItem = SortableElement(({item, index, formItems, siteUrls, onRemove, binderItems}) => {


    return ( <AccordionItemEdit key={index}  onDelete={onRemove}  edit={true} siteUrls={siteUrls} dataBinder={item} /> )
});

const SortableList = SortableContainer(({items, formItems, isSorting, onRemove, siteUrls, binderItems}) => {
    let className = "";
    if(isSorting)
    {
        className = "mo-sortable-container mo-sortable-container-active"
    }

    return (
        <div className={className}>
            {
                items.map((item, index) => (
                    <React.Fragment key={`frag-${index}`}>
                        <SortableItem key={`item-${index}`}
                                      item={item}
                                      index={index}
                                      siteUrls={siteUrls}
                                      onRemove={(item) => onRemove(item, index)}
                                      formItems={formItems} />

                    </React.Fragment>

                ))
            }
        </div>
    );
});

export default class Accordion extends EditableModule {
    constructor(props)
    {
        super(props);






        this.state = {
            content: props.content,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            dataBinders: [],
            hasChanges: false
        };

        this.handleTabClick = this.handleTabClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);
        this.handleDeleteItemClick = this.handleDeleteItemClick.bind(this);
    }
    onSortEnd = async ({oldIndex, newIndex}) =>  {


        const sortedArray = arrayMove(this.state.dataBinders, oldIndex, newIndex);

        this.setState({
            dataBinders: sortedArray,
            hasChanges: true
        });

    };
    static createForReference(refName)
    {
        return <Accordion blockEdit={true} referenceName={refName} content={
            {
                headline: "Accordion",
                items: [
                    {
                        headline: 'Item 1',
                        text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. \n' +
                            '</p><p>' +
                            'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>'
                    },
                    {
                        headline: 'Item 2',
                    } ,
                    {
                        headline: 'Item 3',
                    }

                ]
                }} siteUrls={[]} />;
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {

            let items = [];
            let hasChanges = this.state.hasChanges;

            for(let dataBinder of this.state.dataBinders)
            {
                let changes = dataBinder.getChanges();
                if(changes)
                {
                    hasChanges = true;
                } else {
                    changes = dataBinder.getRawContent();
                }

              items.push(changes);


            }

            const mainChanges = this.state.dataBinder.getChanges();
            let content = this.state.content;

            // headline changed
            if(mainChanges)
            {
                content.headline = mainChanges.headline;
                hasChanges = true;
            }

            if(hasChanges || this.state.dataBinder.getChanges())
            {


                let contentUpdate = {
                    content: content
                };


                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = this.state.content;
                    contentUpdate.ContentTranslation[this.props.language.short].items = items;


                    content =  contentUpdate.ContentTranslation[this.props.language.short];
                } else {
                    contentUpdate.content.items = items;

                    content = contentUpdate.content;

                }



                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(content);
            } else {
                this.cancelEditState();
            }
        } else {
            let dataBinders = [];
            if(this.state.content.items)
            {
                for(let item of this.state.content.items)
                {
                    dataBinders.push(new DataBinder(item));
                }
            }


            this.enterEditState({
                dataBinders: dataBinders
            });
        }
    }
    handleTabClick(src)
    {
        debugger;
    }
    handleAddItemClick()
    {
        this.state.dataBinders.push(new DataBinder({headline:'New item'}));
        this.setState({
            hasChanges: true
        });
    }
    handleDeleteItemClick(item)
    {
        const itemIdx = this.state.dataBinders.indexOf(item);
        this.state.dataBinders.splice(itemIdx, 1);

        this.setState({
            dataBinders: this.state.dataBinders,
            hasChanges: true
        });
    }
    renderItems()
    {
        return (
            <div className="m-accordion__container" role="tablist" aria-multiselectable="true">

                {
                    (!this.state.content.items) ? null : (
                        this.state.content.items.map((item, idx) => {
                            return <AccordionItem onDelete={this.handleDeleteItemClick }
                                                  key={idx}
                                                  edit={this.state.edit}
                                                  siteUrls={this.props.siteUrls}
                                                  content={item}  />
                        }))
                }


            </div>
        );
    }
    renderEdit()
    {
        const items = this.state.dataBinders;
        return (
            <div className="m-accordion__container" role="tablist" aria-multiselectable="true">
                <SortableList  useDragHandle={true} items={items} formItems={this.formItems}
                                    helperClass={"mo-sortable-item mo-sortable-content"}
                                    onSortEnd={this.onSortEnd}

                                    updateBeforeSortStart={ this.onBeforeStart }
                                    useWindowAsScrollContainer={true} isSorting={this.state.sorting}
                                    onRemove={this.handleDeleteItemClick}
                               siteUrls={this.props.siteUrls}
                >


                </SortableList><span className="btn btn-default" onClick={this.handleAddItemClick}>Add item</span>


            </div>
        );
    }
    render() {
        return (
            <div className="accordion abstractComponent" data-reference={ this.props.referenceName }>
                <div className="m-accordion " data-t-name="Accordion">
                    {
                        (!this.state.allowEdit) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onDelete={this.handleDelete }
                                    onChange={this.handleHeadlineChange}
                                    showMove={!this.state.edit}
                                    showSettings={ false }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }
                    <ModuleHead
                        edit={ this.state.edit }
                        binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                        headline={ this.state.content.headline } />

                    {
                        (this.state.edit) ? this.renderEdit() : this.renderItems()
                    }

                </div>


            </div>
        )
    }
}