import React from "react";
import '../../styles/makaio.css';
import '@ckeditor/ckeditor5-build-inline/build/translations/ar';
import {useRouteData, useSiteData} from "react-static";
import {isRTL} from "../../helper";
const CKEditor = require('@ckeditor/ckeditor5-react');
const InlineEditor = require('@ckeditor/ckeditor5-build-inline');

function plainTextToHtml( text ) {

    text = text
        .replace(/<h\d{1}[^>]*>/gm, '<p>') // remove starting headline (h1)
        .replace(/<\/h\d{1}[^>]*>/gm, '</p>') // remove trailing headline (h1)
        .replace( /<p>\s?<br data-cke-filler[^>]*>\s?<\/p>/gm, '') // remove empty line
        .replace( /<p>\s?&nbsp;\s?<\/p>/gm, '') // remove empty line
    ;
    // Encode <>.
        /*.replace( /</g, '&lt;' )
        .replace( />/g, '&gt;' )
        // Creates paragraphs for double line breaks and change single line breaks to <br>s.
        .replace( /\n\n/g, '</p><p>' )
        .replace( /\n/g, '<br>' )
        // Preserve trailing spaces (only the first and last one – the rest is handled below).
        .replace( /^\s/, '&nbsp;' )
        .replace( /\s$/, '&nbsp;' )
        // Preserve other subsequent spaces now.
        .replace( /\s\s/g, ' &nbsp;' );*/
/*
    if ( text.indexOf( '</p><p>' ) > -1 ) {
        // If we created paragraphs above, add the trailing ones.
        text = `<p>${ text }</p>`;
    }
*/
    // TODO:
    // * What about '\nfoo' vs ' foo'?

    return text;
}

class RichTextEditorInternal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.binding.getValue()
        }
        // value={textContent} onChange={ this.handleTextChange }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        return {
            value: nextProps.binding.getValue()
        };

    }
    render() {

        return (<CKEditor
            editor={ InlineEditor }
            data={ this.state.value }
            config={
            {
                language: (isRTL(this.props.language)) ? 'ar' : 'en',
                toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'direction' ],

            }
            }
            onInit={ editor => {
                const clipboardPlugin = editor.plugins.get( 'Clipboard' );
                const editingView = editor.editing.view;

                editor.editing.view.focus();

                editor.plugins.get( 'Clipboard' ).on( 'inputTransformation', ( evt, data ) => {
                    const inData = editor.getData();
                    const outData = plainTextToHtml(inData,this.props.headlineTag);
                    const model = editor.model;
                    const schema = model.schema;

                    editor.setData(outData);

                    model.change( writer => {
                        //debugger;
                    } );
                } , { priority: 'lowest' });

            }}

            onChange={ ( event, editor ) => {
                const data = editor.getData();

                if(this.props.onChange)
                {
                    this.props.onChange(data);
                }

                if(this.props.binding)
                {
                    this.props.binding.updateValue(data);
                }
            } }

        />)
    }
}

export default function RichTextEditor (props)
{
    const {  language  } =  useRouteData();
    const { primaryLanguage } = useSiteData();


    return <RichTextEditorInternal primaryLanguage={primaryLanguage} language={language} { ... props }  />;
}
