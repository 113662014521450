import React from "react";
import {useRouteData, useSiteData} from "react-static";
import {Link} from "react-router-dom";

import '../../styles/async_fonts.css'
import '../../styles/makaio.css'

import {getSpecialLinkUrl} from "../../containers/Page";
import {Translator} from "../../translator";


class SubNavigation extends  React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            hidden: props.hidden,
            language: props.language,
            selected: props.selected
        };

        this.handleBackClick = this.handleBackClick.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.hidden !== nextProps.hidden || prevState.selected !== nextProps.selected){
            return {
                hidden: nextProps.hidden,
                selected: nextProps.selected
            };
        } else {
            return null;
        }

    }

    handleBackClick() {

        this.setState(
            {
                hidden: true
            }
        );
        this.props.onSubMenuBack();
    }
    render() {


        if(!this.props.items || this.props.items.length === 0)
            return null;


        let cssClass = "o-off-canvas__layer";

        const self = this;

        if(!this.state.hidden)
            cssClass +=  " o-off-canvas__layer--focus o-off-canvas__layer--open";

        return (
            <div className={cssClass}
                 aria-hidden={ this.state.hidden}>
                <div className="o-off-canvas__layer-content">
                    <div className="o-off-canvas__layer-scrollable">
                        <button className="o-off-canvas__back js-o-off-canvas__back"
                                onClick={ this.handleBackClick }>
        <span className="o-off-canvas__back-text icon icon-arrow-previous-before">
        Back
    </span>
                        </button>
                        <button className="o-off-canvas__close
                   js-o-off-canvas__close"  onClick={ this.props.onSubMenuClose }>
                            <span className="sr-only" />
                        </button>
                        <h2 className="o-off-canvas__layer-title js-off-canvas__layer-title"  dangerouslySetInnerHTML={ {__html:this.props.parent.Navigationtitle || this.props.parent.Pagetitle } } />




                        <ul className="o-off-canvas__list
               o-main-navigation__list
               o-main-navigation__list--main">

                            {

                                this.props.items.map((item) => {
                                    if(!item)
                                    {
                                        //debugger;
                                        return null;
                                    }
                                    const isActive = self.state.selected && self.state.selected.Slug === item.Slug;
                                    return <Link  key={item.id} className="o-off-canvas__item-link" data-active={ isActive } to={`${self.props.baseUrl}/${item.Slug}`} dangerouslySetInnerHTML={ {__html: item.Navigationtitle || item.Pagetitle } }/>
                                })
                            }
                        </ul>

                    </div>
                </div>
            </div>
        )
    }
}

class NavigationBarItem extends React.Component {
    constructor(props)
    {
        super(props);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            showSubnavi: (props.active && props.item.children && props.item.children.length > 0),
            active: props.active,
            language: props.language,
            item: props.item,
            selected: props.selected
        };
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.active !== nextProps.active || nextProps.item !== prevState.item || nextProps.selected !== prevState.selected){
            return {
                showSubnavi: (nextProps.active && nextProps.item.children && nextProps.item.children.length > 0),
                active: nextProps.active,
                selected: nextProps.selected

            };
        } else {
            return null;
        }
    }

    handleClick(e)
    {
        if(this.state.item && this.state.item.children)
        {
            for (let child of this.state.item.children)
            {
                if(child.id === this.state.selected.id)
                {
                    e.nativeEvent.preventDefault();
                    return;
                }
            }
        }
        this.props.onChange(this.state.item);
        e.nativeEvent.preventDefault();

    }
    render() {
       let link;
        if(this.state.item.id !== 3 && this.state.item.children && this.state.item.children.length > 0)
        {
            link = <span className="o-off-canvas__item-link js-o-off-canvas__item-link o-off-canvas__item-link--has-children"
                         data-active={ this.state.active }
                         dangerouslySetInnerHTML={ {__html: this.state.item.Navigationtitle || this.state.item.Pagetitle } }
                         onClick={ this.handleClick } />
        } else {
            link = <Link className="o-off-canvas__item-link" data-active={ this.state.active }
                         to={`${this.props.baseUrl}/${this.state.item.Slug}`}
                         dangerouslySetInnerHTML={ {__html: this.state.item.Navigationtitle || this.state.item.Pagetitle } }
                         onClick={ () => this.props.onChange(this.state.item) } />
        }

        /* FIXME event details*/
        //console.log(this.state.item);

        return (
            <li className="o-off-canvas__item">
                { link }
                <span className = "o-off-canvas__item-subline"  dangerouslySetInnerHTML={ {__html: this.state.item.Description } } />

                {
                    (this.state.item.id === 3) ? null : (
                    <SubNavigation
                    onSubMenuBack={ this.props.onSubMenuBack }
                    onSubMenuClose={ this.props.onSubMenuClose }
                    selected={ this.state.selected }
                    hidden={!this.state.showSubnavi}
                    baseUrl={this.props.baseUrl}
                    parent={this.state.item}
                    language={this.state.language}
                    items={this.state.item.children} />
                    )
                }


            </li>

        )
    }
}

/*

 */

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected,
            subnaviAllowed: true,
            homeUrl: props.homeUrl,
            primaryLanguage: props.primaryLanguage,
            language: props.language,
            changeFromRouter: props.changeFromRouter
        };



        this.navRef = React.createRef();
        this.handleInnerClick = this.handleInnerClick.bind(this);
        this.handleOuterClick = this.handleOuterClick.bind(this);
        this.handleNavigationChange = this.handleNavigationChange.bind(this);
        this.handleSubMenuClose = this.handleSubMenuClose.bind(this);
        this.handleSubMenuBack = this.handleSubMenuBack.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        let newState = {
            subnaviAllowed: true,

        };


        if(nextProps.selected !== prevState.selected)
        {
            if(prevState.changeFromRouter)
            {
                newState.selected = nextProps.selected;
            } else {
                newState.changeFromRouter = true;
            }

        }
        if(nextProps.language !== prevState.language)
        {
            newState.language = nextProps.language;

            let homeUrl = (prevState.primaryLanguage.short === nextProps.language.short) ? '/' : `/${nextProps.language.short}`;
            newState.homeUrl = homeUrl;
        }
        return newState;
    }

    handleNavigationChange(item)
    {
        if(item.children && item.children.length > 0)
        {
            this.setState({
                subnaviAllowed: true,
                selected: item,
                changeFromRouter: false
            });
        } else {
            this.setState({
                subnaviAllowed: true
            });
        }

    }
    handleSubMenuClose()
    {
        this.props.onMenuClose();
    }
    handleSubMenuBack()
    {
        this.setState({
            subnaviAllowed: false
        })
        //this.props.onMenuClose();
    }
    handleInnerClick(e)
    {
        e.stopPropagation();
    }
    handleOuterClick(e)
    {
        this.props.onMenuClose();
        this.setState({
            selected: this.props.selected
        });
    }
    render() {

        const newsletterLink = getSpecialLinkUrl(this.props.specialPages.newsletter, this.state.language, this.props.primaryLanguage);
        const contactLink = getSpecialLinkUrl(this.props.specialPages.contact, this.state.language, this.props.primaryLanguage);

        let classNames = "o-main-navigation o-off-canvas o-off-canvas--left";

        if(this.props.isOpen)
        {
            classNames += " o-off-canvas--open";
            if(
                (
                    (this.state.selected && this.state.selected.children && this.state.selected.children.length > 0)
                    ||
                    (this.state.selected.parent && this.state.selected.parent.id)
                ) &&
                this.state.subnaviAllowed
            )
            {
                classNames += " subnavi-open ";
            }
        }

        //this.props.specialPages.contact.Navigationtitle
        let contactText = this.props.specialPages.contact.Pagetitle;
        if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
            contactText = this.props.specialPages.contact.translations[this.props.language.short].Pagetitle;


        return (
            <nav  onClick={ this.handleOuterClick } ref={this.navRef} id="navigation" className={classNames} data-t-name="OffCanvas" data-t-decorator="MainNavigation">
                <div className="o-off-canvas__layer" aria-hidden="true" onClick={ this.handleInnerClick }>

                    <div className="o-off-canvas__layer-content">
                        <div className="o-off-canvas__layer-scrollable">

                            <button className="o-off-canvas__close o-off-canvas-main__close
                   js-o-off-canvas__close" onClick={ this.props.onMenuClose }>
                                <span className="sr-only" />
                            </button>


                            <Link to={ this.state.homeUrl } className="o-off-canvas__home js-o-off-canvas__home text-muted">
                                <span className="icon-home" />
                            </Link>


                            <ul className="o-off-canvas__list
               o-main-navigation__list
               o-main-navigation__list--main">

                                {
                                    (!this.props.items) ? null : (
                                    this.props.items.map((page) => {
                                        const selected = this.state.selected;

                                        const isActive = (page.Slug === this.state.selected.Slug || (selected.parent && selected.parent.Slug === page.Slug));


                                        return <NavigationBarItem
                                            onSubMenuClose={ this.handleSubMenuClose }
                                            onSubMenuBack={ this.handleSubMenuBack }
                                            onChange={this.handleNavigationChange }
                                            key={page.id} item={page}
                                            language={ this.state.language }
                                            baseUrl={ this.props.baseUrl }
                                            active={ isActive} selected={ selected } />
                                    }))
                                }

                        </ul>
                            { (contactLink) ?                             <Link className="btn btn-primary" to={ contactLink }>{ contactText }</Link>
                             : null }

                            {
                                (1 === 1 || !newsletterLink) ? null : (
                                    (this.state.language.short !== 'en') ? (
                                    <a target="_blank" className="btn btn-primary" href={ newsletterLink }>Newsletter</a>
                                        ) :
                                (                       <a target="_blank" className="btn btn-primary" href={ newsletterLink }>Newsletter</a> )
                                )
                            }
                    </div>
                </div>
            </div>

            </nav>
        );
    }
}


export default (props) => {
    const {  menu, page, language } = useRouteData();
    const { primaryLanguage, specialPages } = useSiteData();

    let localizedMenu = menu[language.short];

    let homeUrl = (primaryLanguage.short === language.short) ? '/' : `/${language.short}`;

    return (
        <NavigationBar
            homeUrl={ homeUrl }
            primaryLanguage={primaryLanguage}
            onMenuClose={ props.onMenuClose }
            language={language}
            isOpen={props.isOpen}
            selected={page}
            baseUrl={`/${language.short}`}
            changeFromRouter={true}
            specialPages={specialPages}
            items={localizedMenu} />
    )
}
