import React from "react";
import ContentDragHandle from "./ContentDragHandle";
import ReactTooltip from "react-tooltip";

export  class PageSettingTool extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            showSettings: props.show,
            active: props.active
        };

        this.handleSettingsClick = this.handleSettingsClick.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        return {
            active: nextProps.active,
            showSettings: nextProps.show,
        };
    }
    handleSettingsClick()
    {
        this.props.onClick();
    }
    render() {
        if(this.props.show)
        {
            if(!this.state.active)
            {

                return (
                    <div className="mo-edit-icon" onClick={this.handleSettingsClick }>
                        g
                    </div>
                )

            } else if(this.state.showSettings)
            {
                return (<React.Fragment>
                    <div className="mo-edit-icon" onClick={ this.props.onSave }>
                        n
                    </div>
                    <div className="mo-edit-icon mo-edit-icon-cancel" onClick={ this.props.onCancel }>
                        d
                    </div>
                </React.Fragment>)
            }
        }

        return null;
    }
}
export default class ContentEditTools extends React.Component {
    constructor(props)
    {
        super(props);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }
    handleDeleteClick()
    {

        const conf = window.confirm("Are you sure you want to remove this content?");
        if(conf)
        {
            this.props.onDelete();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild();
    }

    render()
    {

        let icon = (this.props.edit) ? "n" : "0";




        return (
            <div className="mo-edit-icon-container">

                <div className="mo-edit-icon-container-items">
                    {(this.props.inSettings) ? null : (
                    <div className="mo-edit-icon" onClick={ this.props.onEditClick } data-tip={ (!this.props.edit || this.props.inSettings) ? "Edit content" : "Save changes"}>
                        { icon }
                    </div>)
                    }
                   {
                       (!this.props.edit || this.props.inSettings) ? null :
                           (<React.Fragment>
                                   <div className="mo-edit-icon mo-edit-icon-cancel" onClick={ this.props.onCancel } data-position={"left"} data-tip={"Discard changes"}>
                                       d
                                   </div>

                               </React.Fragment>
                             )
                   }

                    {
                        (!this.props.showMove || this.props.edit || this.props.inSettings) ? null : (

                                <ContentDragHandle />

                        )
                    }

                    {
                        (!this.props.showSettings)  ? null : (
                            <PageSettingTool show={(this.props.onSettingsClick && !this.props.edit )}
                                             active={ this.props.inSettings}
                                             onClick={ this.props.onSettingsClick }
                                             onCancel={ this.props.onSettingsCancel }
                                             onSave={this.props.onSettingsSave}
                            />
                        )
                    }

                    {(this.props.inSettings || !this.props.showDelete || this.props.edit) ? null : (
                        <React.Fragment>
                            <div className="mo-edit-icon" onClick={ this.handleDeleteClick }
                                 data-tip="Delete this content">
                                
                            </div>

                        </React.Fragment>
                        )
                    }

                </div>
            </div>
        );
    }
}