import React from "react";
import strapi from "../../helper";
import {DataBinder} from "../admin/DataBinder";

export default class EditableModule extends React.Component {
    constructor(props)
    {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);

    }
    async prepareContentTranslation()
    {
        let existingItem = await strapi.getEntry("pagecontents", this.props.id);

        return {

            ContentTranslation: existingItem.ContentTranslation || {}
        };
    }
    async handleDelete()
    {

        if(this.props.id && this.props.userCreated)
        {
            await strapi.deleteEntry("pagecontents", this.props.id);
            window.location.reload();
        }
    }
    enterEditState(initialState)
    {
        let newState = initialState || {};
        let clonedContent = {};
        const databinder = new DataBinder(this.state.content);
        Object.assign(clonedContent, this.state.content);


        if(this.state.content.items)
        {
            const actualItems = this.state.content.items;
            let clonedItems = [];
            for(let item of actualItems)
            {
                let clonedItem = {};
                Object.assign(clonedItem, item);
                clonedItems.push(clonedItem);
            }
            databinder.updateProperty("items", clonedItems);
        }

        Object.assign(newState, {
            edit: true,
            dataBinder: databinder
        });

        this.setState(newState);
    }
    cancelEditState()
    {
        this.setState({
            edit: false,
            dataBinder: null,
        });
    }
    exitEditState(changes)
    {
        if(this.props.content)
        {
            Object.assign(this.props.content, changes);
        }

        this.setState({
            edit: false,
            content: changes,
            dataBinder: null
        });
    }
}