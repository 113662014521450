import React from "react";
import '../../../styles/app.css'
import {DataBinder} from "../../admin/DataBinder";
import {RichText} from "../RichText";
import {TextImage} from "../TextImage";
import ContentDragHandle from "../../admin/ContentDragHandle";

import strapi, {ResponsiveImage} from "../../../helper";

let RichTextEditor;
let HeadlineEditor;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
    RichTextEditor = require('../../admin/RichTextEditor').default;
    HeadlineEditor = require('../../admin/HeadlineEditor').default;
    useDropzone = require('react-dropzone').useDropzone;
}




function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
            accept: 'image/png, image/jpg, image/jpeg'
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                {
                    (img) ? <ResponsiveImage width="200px" img={img}/> : (
                        <span>Drop a file here, or click to select a file from your local files</span>)

                }
            </div>
        )
    }
}
export class AccordionItemEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataBinder: props.dataBinder
        };

        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if(prevState.dataBinder !== nextProps.dataBinder){
            return {
                dataBinder: nextProps.dataBinder
            }

        } else {
            return null;
        }
    }
    handleRemoveImage()
    {
        this.state.dataBinder.bind('image').updateValue(null);
        this.forceUpdate();
    }
    async handleDrop(acceptedFiles)
    {
        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id
            };

            await strapi.request('GET', `/generateThumbnail/${uploadedFile.hash}${uploadedFile.ext}`);

            let imageBound = this.state.dataBinder.bind("image");

            imageBound.updateValue(uploadedFile);

            this.forceUpdate();
        }
    }
    handleDeleteClick()
    {
        this.props.onDelete(this.state.dataBinder);
    }

    render() {
        return (
            <div data-t-name="AccordionItem" className="m-accordion-item mo-accordion-item-edit">
                <ContentDragHandle />
                <div className="mo-edit-icon" onClick={ this.handleDeleteClick }
                                          data-tip="Delete this content">
                    
                </div>

                <div className="mo-accordion-item-inner mo-accordion-item-inner-edit">
                    <HeadlineEditor binding={ this.state.dataBinder.bind("headline")} headlineTag="h4"/>
                    <RichTextEditor binding={ this.state.dataBinder.bind("text")} />

                    <div className="image-edit-container mo-accordion-item-image-edit">
                        <strong>Image</strong><br/>
                        {
                            (!this.state.dataBinder.bind("image").getValue()) ? null : (
                                <div className="mo-edit-icon-container">
                                    <div className="mo-edit-icon-container-items">
                                        <div className="mo-edit-icon" onClick={this.handleRemoveImage}></div>
                                    </div>
                                </div>
                            )
                        }
                        <MyDropzone img={this.state.dataBinder.bind("image").getValue()} onDrop={this.handleDrop} />

                    </div>
                </div>


            </div>
        );
    }
}

export default class AccordionItem extends React.Component {
    constructor(props) {
        super(props);

        this.handleTabClick = this.handleTabClick.bind(this);
        this.state = {
            expanded: false,
            edit: props.edit,
            content: props.content,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if(prevState.content !== nextProps.content){
            return {
                content: nextProps.content
            }

        } else {
            return null;
        }

    }
    handleTabClick(src) {
        this.setState(
            {
                expanded: !this.state.expanded
            }
        )
    }
    render() {
        let contentWithoutHeadline = {};
        Object.assign(contentWithoutHeadline, this.state.content);
        delete contentWithoutHeadline.headline;

        return (
            <div data-t-name="AccordionItem" className="m-accordion-item">
                <div className="mo-accordion-item-inner">
                    <h4 className="m-accordion-item__title" role="tab">
                        <a className={ this.state.expanded ? "": "collapsed"} role="button" data-toggle="collapse"
                           onClick={ this.handleTabClick }
                           aria-expanded={ this.state.expanded }
                           dangerouslySetInnerHTML={ {__html: this.state.content.headline } }
                         />
                    </h4>
                    <div
                        className={(this.state.expanded) ? "panel-collapse collapse in " : "panel-collapse collapse"} role="tabpanel"
                        aria-expanded={ this.state.expanded } >
                        {
                            (!this.state.expanded) ? null :

                                (
                                    <div className="m-accordion-item__content">

                                        {
                                            (contentWithoutHeadline.image) ?
                                                (
                                                    <TextImage blockEdit={true} siteUrls={this.props.siteUrls} content={contentWithoutHeadline}/>
                                                )
                                                : (
                                                    <RichText  blockEdit={true} siteUrls={this.props.siteUrls} content={contentWithoutHeadline}/>
                                                )
                                        }
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
