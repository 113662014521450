import React from "react";
import {useRouteData, useSiteData} from "react-static";


class MfLink extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            link: props.link,
            caption: props.caption,
            enabled: props.enabled || false
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){

        return {
            enabled: nextProps.enabled
        }
    }
    render() {
        let link = this.state.link;
        let isInternal = false;
        let hasChildren = this.props.children && this.props.children.length > 1;
        if (link) {
            for (let url of this.props.siteUrls) {
                if (link.indexOf(url) > -1) {
                    link = link.replace(`http://${url}`, '');
                    link = link.replace(`https://${url}`, '');
                    isInternal = true;
                }
            }
        }

        if (this.props.children) {
            if (!this.state.enabled) {
                return this.props.children;
            }
            return (
                <a href={this.state.link} target={(isInternal) ? "_self" : "_blank"}>
                    {this.props.children}
                </a>
            )
        }

        if (!this.state.enabled && !hasChildren) {
            return <span dangerouslySetInnerHTML={{__html: this.state.caption}}/>
        }


        //const target = ()


        if (hasChildren)
        {
        } else {
            return (


                <a href={link} target={(isInternal) ? "_self" : "_blank"} dangerouslySetInnerHTML={ {__html: this.state.caption }  }>

                </a>
            );
        }



    }
}

export default  (props) => {
    const { urls } = useSiteData();

    return (
        <MfLink siteUrls={urls}  { ...props } />
    );
}