import React from "react";
import {useSiteData} from "react-static";

export  function SocialLink({allLinks, network, link, iconClass}) {
    if(!link)
        link = allLinks[network];

    if(!link || link.length === 0)
        return null;

    if(!iconClass)
        iconClass = `icon-${network}`;

    return <li>
        <a className={iconClass} target="_blank" rel="noopener noreferrer"
           href={link}>
            <span className="sr-only">{network}</span>
        </a>
    </li>;
}

export default function FooterSocial({language,title}) {
    const { social } = useSiteData();

    if(!social)
        return <div />;
    
    const socialLinks = (social.data) ? social.data : {};
    if(!socialLinks || Object.getOwnPropertyNames(socialLinks).length === 0)
        return <div />;

    return <div className="o-footer__social-media h-background h-background--fullwidth">
        <div>
            <h3>{title}</h3>
        </div>
        <div>

            <ul>
                <SocialLink  network="facebook" allLinks={socialLinks} />
                <SocialLink  network="instagram" allLinks={socialLinks} />
                <SocialLink  network="twitter" allLinks={socialLinks} />
                <SocialLink  network="youtube" allLinks={socialLinks} />
                <SocialLink  network="pinterest" allLinks={socialLinks} />
                <SocialLink  network="xing" allLinks={socialLinks} />
                <SocialLink  network="linkedin" iconClass="icon-linkedinoutline" allLinks={socialLinks} />
            </ul>
        </div>
    </div>
}