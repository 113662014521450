import React from 'react'
import {useRouteData, useSiteData, Head} from 'react-static'

import PageContainer from "../components/ui/PageContainer";
import Content from "./Content";
import Auth from "../modules/Auth";

import {Link} from "react-router-dom";
import '../styles/makaio.css'
import MetaHeader from "./MetaHeader";
import FooterSocial from "../components/modules/FooterSocial";
import {Translator} from "../translator";
import LazyLoad from "react-lazyload";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import Moment from "react-moment";

let EditToolbar;
let ReactTooltip;
let SortableContent;

if(process.env.NODE_ENV !== 'production') {
    EditToolbar = require('../components/ui/EditToolbar').default;
    ReactTooltip = require('react-tooltip').default;
    SortableContent = require('./SortableContent').default;
}



export const getSpecialLink = (page, language, primaryLanguage) => {
    if(!page)
        return null;

    if(language.id === primaryLanguage.id)
    {
        return  <Link to={`/${primaryLanguage.short}/${page.Slug}`}>
            {page.Navigationtitle}
        </Link>
    } else {
        let translatedPage = page.translations[language.short];
        if(!translatedPage || !translatedPage.Slug)
            return null;

        return  <Link to={`/${language.short}/${translatedPage.Slug}`}>
            {translatedPage.Navigationtitle}
        </Link>
    }

};

export const getSpecialLinkUrl = (page, language, primaryLanguage) => {


    if(!page)
    {
        return null;
    }


    if(language.id === primaryLanguage.id)
    {
        return `/${primaryLanguage.short}/${page.Slug}`;
    } else {
        let translatedPage = page.translations[language.short];
        if(!translatedPage || !translatedPage.Slug)
            return null;

        return  `/${language.short}/${translatedPage.Slug}`;
    }

};

export const replaceLinks = (text, siteUrls) => {
    if(!text)
        return "";

    for(let url of siteUrls)
    {
        const regexContent = "http[s]?:\\/\\/"+url;
        const regex = new RegExp(regexContent,"gm");

        text = text.replace(regex, '');
    }

    const externalRegex = /<a href="http/g;

    text = text.replace(externalRegex, '<a target="_blank" href="http');

    return text;
};



export  default  function  Page() {
    const { page, language, menu } =  useRouteData();
    const { languages, primaryLanguage, specialPages, urls, translations, companyName,  disableNewsletter, externalNewsletter } = useSiteData();
    const newsletterLink = (externalNewsletter) ? externalNewsletter : getSpecialLinkUrl(specialPages.newsletter, language, primaryLanguage);


    if(!specialPages.contact)
    {
        debugger;
    }
    const contactLink = getSpecialLinkUrl(specialPages.contact, language, primaryLanguage);
    const lang_key = language.short;

    Translator.init(translations);

    let localeKey = lang_key;
    if(localeKey === 'se')
        localeKey = 'sv';

    Moment.localeKey = localeKey;

    let contactText = specialPages.contact.Pagetitle;
    if(language && language.short !== primaryLanguage.short)
        contactText = specialPages.contact.translations[language.short].Pagetitle;

    const privacyLink = getSpecialLink(specialPages.privacy, language, primaryLanguage);
    return (

        <PageContainer language={language} primaryLanguage={primaryLanguage} languages={languages} page={page} >

            <MetaHeader page={page} language={language} />
            {
                (Auth.isAuthenticated() && typeof document !== 'undefined') ?   <SortableContent menu={menu} siteUrls={urls} language={language} primaryLanguage={primaryLanguage} page={ page} /> :
                    <Content siteUrls={urls} language={language} menu={menu} primaryLanguage={primaryLanguage} page={ page} />
            }

            <footer className="o-footer" data-t-name="Footer" data-t-id="37">
                <div className="container">


                    {
                        (disableNewsletter) ? null : (
                            <div className="footerNewsletter abstractComponent">

                                {
                                    (!newsletterLink) ? null : (
                                        <div className="o-footer__newsletter h-background h-background--fullwidth">
                                            <h3 dangerouslySetInnerHTML={ {__html: Translator.translate("Stay up to date", lang_key) } } />



                                            <a target="_blank" className="btn btn-default" tabIndex="-1" href={ newsletterLink }
                                               dangerouslySetInnerHTML={ {__html: Translator.translate("Subscribe to our newsletter", lang_key) } } />

                                        </div>
                                    )
                                }
                            </div>
                        )
                    }

                    <div className="footerSocial abstractComponent">
                        <FooterSocial language={language} title={ Translator.translate("Follow us", lang_key)} />
                    </div>

                    {
                        (!contactLink) ? null : (
                            <div className="footerHelpteaser abstractComponent">
                                <LazyLoad offset={100}>
                                    <div className="o-footer__service">


                                    <div className="o-footer__service--left h-background h-background--fullwidth" style={{"width": "100%"}}>
                                        <h3 dangerouslySetInnerHTML={ {__html: Translator.translate("If you need more help, let us know", lang_key) }} />
                                        <div>
                                            <img src="/content/dam/contact.svg" />

                                            <Link className="btn btn-default" tabIndex="-1" to={ getSpecialLinkUrl(specialPages.contact, language, primaryLanguage) }>
                                                {
                                                    contactText

                                                }
                                            </Link>
                                        </div>
                                    </div>


                                </div>
                                </LazyLoad>

                            </div>
                        )
                    }


                    <div className="footerBasic abstractComponent">

                        <div className="o-footer__basic">


                            <ul className="o-footer__legal-standard list-inline">
                                <li>

                                    {
                                        getSpecialLink(specialPages.imprint, language, primaryLanguage)
                                    }

                                </li>

                                {
                                    (!privacyLink) ? null: <li>

                                        {
                                            privacyLink
                                        }

                                    </li>
                                }

                            </ul>
                            <p className="o-footer__copyright">{(companyName) ? `© ${companyName}` : null }</p>
                        </div>


                    </div>
                    {
                        (process.env.NODE_ENV !== 'production') ?  <ReactTooltip place="left" type="dark" effect="float" delayShow={200} /> : null
                    }

                </div>
            </footer>
            {
                (process.env.NODE_ENV !== 'production' && Auth.isAuthenticated()) ?  <EditToolbar page={page}/> : null

            }

        </PageContainer>

    )
}
