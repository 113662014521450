import * as React from "react";
import Auth from "../../modules/Auth";
import '../../styles/makaio.css'
import {RichText} from "../modules/RichText";
import html2canvas from "html2canvas";
import {TextImage} from "../modules/TextImage";
import Accordion from "../modules/Accordion/Accordion";
import ContactTeaser from "../modules/ContactTeaser/ContactTeaser";
import strapi from "../../helper";
import {TeaserTwoCol} from "../modules/TeaserTwoCol/TeaserTwoCol";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import {Teaser} from "../modules/Teaser/Teaser";
import {Downloads} from "../modules/Downloads/Downloads";
import { EventTeaserWithData } from "../modules/EventTeaser/EventTeaser";
import Publisher from "../admin/Publisher";


const customStyles = (containerWidth) => {
    return {
        overlay: {
            zIndex: 99999
        },
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            maxHeight: '90%',
            width: containerWidth
        }
    };

};

function getAllElementsWithAttribute(attribute)
{
    var matchingElements = [];
    var allElements = document.getElementsByTagName('*');
    for (var i = 0, n = allElements.length; i < n; i++)
    {
        if (allElements[i].getAttribute(attribute) !== null)
        {
            // Element exists with attribute. Add to array.
            matchingElements.push(allElements[i]);
        }
    }
    return matchingElements;
}


export default class EditToolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            containerWidth: 0,
            refItems: [],
            modalIsOpen: false
        };

        this.containerWidth = 0;

        this.handlePopup = this.handlePopup.bind(this);
        this.handleRefClick = this.handleRefClick.bind(this);
        this.getRefContainer = this.getRefContainer.bind(this);
        this.buildRefElements = this.buildRefElements.bind(this);
        this.showPublisher = this.showPublisher.bind(this);
    }
    async buildRefElements(references)
    {
        let refElements = [];
        let p = new Promise((resolve) => {
            let promises = [];
            for(let ref of references)
            {
                let img = document.createElement('img');
                refElements.push(img);
                let newP = new Promise((resolveInner) => {
                    html2canvas( ref, { scale: 1 })
                        .then((canvas) => {

                            let imgUrl = canvas.toDataURL('image/jpeg');


                            console.log('size: ' + imgUrl.length);
                            img.src = imgUrl;
                            img.onclick = async () => {
                                if(self.popupClose)
                                    self.popupClose();

                                await this.handleRefClick(ref)
                            };

                            resolveInner(true);
                        });
                });
                promises.push(newP);

            }
            Promise.all(promises).then(() => {
                resolve(true);
            })
        });

        await p;
        return refElements;
    }
    componentDidMount() {

        const self = this;
        if(typeof window === 'undefined' || !window)
            return;

        setTimeout(async () => {

            let referenceContainers = getAllElementsWithAttribute('data-reference');
            let refElements;

            try {
                //localStorage.setItem('refItems', null);
                let refCountInStorage = localStorage.getItem('refItems_count');

                let rebuildRequired = false;
                let refElementsInStorage = [];
                let refIds = [];

                if(!refCountInStorage || parseInt(refCountInStorage) !== referenceContainers.length)
                {
                    rebuildRequired = true;
                } /*else {
                    for(let item of referenceContainers)
                    {
                        debugger;
                    }
                }*/

                for(let item of referenceContainers)
                {
                    let refId = item.getAttribute("data-reference");
                    let refInStorage = localStorage.getItem(refId);
                    refIds.push(refId);
                    if(!refInStorage)
                    {
                        rebuildRequired = true;
                    } else {
                        refElementsInStorage.push(refInStorage)
                    }
                }

                if(rebuildRequired)
                {
                    refElements = await this.buildRefElements(referenceContainers);

                    let idx = 0;
                    for(let refEl of refElements)
                    {
                        let src = refEl.currentSrc;
                        localStorage.setItem(refIds[idx], src);
                        idx++;


                        refElementsInStorage.push(src);
                    }

                    localStorage.setItem('refItems_count', idx.toString());
                }

                refElements = [];
                let index = 0;

                for(let el of refElementsInStorage)
                {
                    let img = document.createElement("img");
                    img.src = el;
                    img.setAttribute("data-reference", refIds[index]);
                    img.onclick = async () => {
                        if(self.popupClose)
                            self.popupClose();

                        await this.handleRefClick(img)
                    };
                    refElements.push(img);
                    index++;
                }

            }
            catch (e)
            {
                console.error(e);
                refElements = await this.buildRefElements(referenceContainers);
            }



            self.setState({
                refItems: refElements,
                containerWidth: (document.getElementsByClassName('container').length > 0) ? document.getElementsByClassName('container')[0].clientWidth : 0
            });
            document.getElementById('mo-content-add-btn').setAttribute('class', 'btn btn-primary');
        }, 100);

    }

    handleLogout()
    {
        Auth.logout();
        if(window)
        {
            window.location.reload();
        }
    }
    async handleRefClick(ref)
    {
        let type = ref.getAttribute("data-reference");
        let page = this.props.page;

        let defaultContent = {'headline': 'New ' + type };


        switch (type)
        {
          case 'TeaserTwoCol':
            defaultContent = TeaserTwoCol.getReferenceContent()
                break;
            default:
                break;
        }

        await strapi.createEntry('pagecontents', {
            page: page.id,
            position: (page.pagecontents) ? page.pagecontents.length : 0,
            contentType: type,
            content: defaultContent,
            UserCreated: true
        });

        if(window)
            window.location.reload();



    }
    handlePopup(pop)
    {
        /*
        let target= document.getElementsByClassName('mo-edit-module-references');

        for (let ref of this.state.refItems)
        {
            target[0].appendChild(ref);
        }*/



    }
    getRefContainer()
    {
        const container = document.createElement('div');
        container.setAttribute('class', 'mo-edit-add-content mo-edit-module-references');
        for(let refItem of this.state.refItems)
        {
            container.appendChild(refItem);
        }
        return container;
    }
    showPublisher()
    {
        this.setState({
            publisherIsOpen: true
        });
    }
    render() {
        const self = this;
        if(!Auth.isAuthenticated())
            return null;

        // state.refItems
        let triggerClassName = "btn btn-primary";
        let openFunc = this.handlePopup;
        if(!self.state.refItems || self.state.refItems.length === 0)
        {
             triggerClassName = "btn btn-primary disabled";
             openFunc = () => {};
        }


        return (<div className="mo-edit-toolbar">
            <Modal
                style={customStyles((this.state.containerWidth + 30) + 'px')}
                isOpen={self.state.modalIsOpen}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={ true }
                onRequestClose={() => self.setState({ modalIsOpen: false })}

            >
                <div className="mo-edit-add-content">
                    <div className="mo-edit-icon mo-edit-icon-cancel" onClick={ () => self.setState({ modalIsOpen: false }) }>d</div>
                    <h2>Add content to page {this.props.page.Pagetitle}</h2>
                    <div ref={(nodeElement) => {nodeElement && nodeElement.appendChild(this.getRefContainer())}}/>
                </div>
            </Modal>

                <Modal
                    style={customStyles((this.state.containerWidth + 30) + 'px')}
                    isOpen={self.state.publisherIsOpen}
                    contentLabel="Example Modal"
                    shouldCloseOnOverlayClick={ true }
                    onRequestClose={() => self.setState({ publisherIsOpen: false })}

                >
                    <Publisher />
                    <div id="mo-content-add-btn" onClick={() => self.setState({ publisherIsOpen: false })} className="btn btn-default">Close</div>

                </Modal>

            <div id="mo-content-add-btn" onClick={() => (!self.state.refItems || self.state.refItems.length === 0) ? {} : this.setState({modalIsOpen: true})} className={triggerClassName}>Add content</div>
            <Link to={'/pageadmin'} className="btn btn-default mo-edit-btn-page-administration">Site Administration</Link>
            <div onClick={ this.showPublisher } className="btn btn-default mo-edit-btn-page-administration">Publish changes</div>
            <span className="btn btn-default mo-edit-toolbar-logout" onClick={this.handleLogout}>Logout</span>
            <div style={{overflow:'hidden', height: '1px', }} className="container">

                { RichText.createForReference('RichText') }
                { TextImage.createForReference('TextImage') }

                { Teaser.createForReference('Teaser') }



                { TeaserTwoCol.createForReference('TeaserTwoCol')}

                { ContactTeaser.createForReference('ContactTeaser') }
                { Downloads.createForReference('Downloads')}
                { Accordion.createForReference('Accordion') }

                { EventTeaserWithData.createForReference('EventTeaser') }
            </div>
        </div>);
    }
}