import React from "react";
import {useRouteData, useSiteData} from "react-static";
import {getSpecialLink} from "../../containers/Page";
import {Translator} from "../../translator";

export class CookieBanner extends React.Component
{
    render() {
        let className = "m-notification-layer m-notification-layer--secondary m-notification-layer--text-only";
        if(this.props.show)
            className += " m-notification-layer--open";
        return (<div
            className={ className }
            data-t-name="NotificationLayer" data-t-decorator="CookieLayer" data-auto-open="true"
            data-auto-open-delay="0" data-t-id="43">
            <div className="container">
                <button className="m-notification-layer__close js-notification-layer__close" onClick={ this.props.onClose }>
                <span className="icon icon-close">
                <span className="sr-only" onClick={ this.props.onClose }>Close</span>
            </span></button>
                <div className="m-notification-layer__content">
                    <div className="m-notification-layer__content-text">
                        <p><span dangerouslySetInnerHTML={{__html: Translator.translate("COMPANYNAME uses cookies to provide you the best possible browsing experience. By using our services, you consent to our use of cookies.", this.props.language.short)}} />
                            &nbsp;

                            {
                                (!this.props.privacyLink) ? null :  <a href={this.props.privacyLink} target="_blank"
                                    dangerouslySetInnerHTML={{__html: Translator.translate("More information", this.props.language.short)}}
                                    />
                            }

                            &nbsp;<br />
                        </p>

                    </div>
                    <div className="m-notification-layer__action">
                        <button className="btn btn-primary btn-inverted js-notification-layer__close" onClick={ this.props.onClose }>
                            {Translator.translate("Close", this.props.language.short)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default function (props) {
    const {  language } = useRouteData();
    const { primaryLanguage, specialPages } = useSiteData();

    const privacyLink = getSpecialLink(specialPages.privacy, language, primaryLanguage);

    return <CookieBanner language={language} show={props.show} onClose={ props.onClose } privacyLink={(privacyLink && privacyLink.props && privacyLink.props.to) ? privacyLink.props.to : null } />;
}
