import React from "react";
let HeadlineEditor;
if(process.env.NODE_ENV !== 'production') {
    HeadlineEditor = require('../admin/HeadlineEditor').default;
}




export class ModuleHead extends React.Component {
    constructor(props) {
        super(props);
        this.handleHeadlineChange = this.handleHeadlineChange.bind(this);

    }
    handleHeadlineChange(val)
    {
        this.editedValue = val;
        console.log(val);

        if(this.props.onChange)
        {
            this.props.onChange(val);
        }

        if(this.props.binding && this.props.binding.dataBinder)
        {
            this.props.binding.updateValue(val);
        }
    }
    render() {
        const self = this;
        if(!this.props.headline && !this.props.edit)
            return null;

        if(typeof document !== 'undefined' && this.props.edit)
        {
            return (
                <HeadlineEditor onChange={this.handleHeadlineChange} headlineTag="h2" headline={this.props.headline || ""} />
            )
        }

        return (
            <React.Fragment>
                <h2 dangerouslySetInnerHTML={ {__html: this.props.headline } } />
                <div style={{clear:'both'}} />
            </React.Fragment>

        )
    }
}