import React from "react";
import Moment from "react-moment";
import {cookiesAllowed} from "../../../helper";
import {Translator} from "../../../translator";
import {useRouteData} from "react-static";


export default function EventItem (props)
{
    const { page } =  useRouteData();
    const eventPages=page.eventPages;


    return <EventItemWithMenu eventPages={eventPages} { ...props } />;
}

export class EventItemWithMenu extends React.Component {
    constructor(props) {
        super(props);

        this.startDate = new Date(props.item.startdate);
        this.endDate = new Date(props.item.enddate);

    }

    componentDidMount() {

    }
    handleDetailClick()
    {

    }
    render() {

        if(this.props.item.interneturl && this.props.item.interneturl === "-")
            debugger;

        let className = 'm-search-result-item--event ex-event-search-result-item';
        if(this.props.right)
        {
            className += ' ex-event-search-result-item--right';

        }

        const eventItem = this.props.item;
        const endsInOtherMonth = eventItem.startdate && eventItem.enddate && eventItem.startdate.substr(5,2) !== eventItem.enddate.substr(5,2);
        let dateEndClass = "ex-event-search-result-item__date-end-container";
        if(endsInOtherMonth)
            dateEndClass += " text-right";


        let intro = undefined;

        if(eventItem.content && this.props.eventPages)
        {
            let page = this.props.eventPages[eventItem.eventnumber];
            if(page && page[this.props.langKey])
            {
                intro = page[this.props.langKey];
            }
        }

        return (<div
            className={className}>
            <div
                className="ex-event-search-result-item__content">
                <div
                    className="ex-event-search-result-item__image">
                    <div className="a-image">
                        <figure><img alt=""
                                     className="img-responsive ex-event-search-result-item__event-image"
                                     src={(cookiesAllowed() && this.props.item.logoUrl && this.props.item.logoUrl.length > 0) ? this.props.item.logoUrl : "/content/placeholder-event.png"} />
                        </figure>
                    </div>
                </div>
                <div
                    className="ex-event-search-result-item__location-container text-muted">
                                                                                        <span
                                                                                            className="ex-event-search-result-item__location-country">{ eventItem.iso3}</span><span
                    className="ex-event-search-result-item__location-city"> {eventItem.cityname}</span>
                    {
                        (!eventItem.clusterMarketing || eventItem.clusterMarketing.length === 0) ? null : (
                            <span
                                className="ex-event-search-result-item__location-cluster"> {eventItem.clusterMarketing}</span>
                        )
                    }
                </div>
                <div
                    className="ex-event-search-result-item__date-text-container">
                    <div
                        className="ex-event-search-result-item__date-container-mobile">
                                                                                            <span
                                                                                                className="h2 ex-event-search-result-item__date-startday"><Moment format="DD" trim="false">{ this.startDate }</Moment></span><span
                        className="h4 ex-event-search-result-item__date-startmonth text-muted hidden"></span><span
                        className="h4 ex-event-search-result-item__date-startyear text-muted hidden"></span><span
                        className="h2 ex-event-search-result-item__date-separator">–</span><span
                        className="h2 ex-event-search-result-item__date-endday"><Moment format="DD" trim="false">{ this.endDate }</Moment></span><span
                        className="h4 ex-event-search-result-item__date-endmonth text-muted"><Moment format="MMM" trim="false">{ this.startDate }</Moment></span><span
                        className="h4 ex-event-search-result-item__date-endyear text-muted">'<Moment format="YY">{this.startDate}</Moment></span>
                    </div>
                    <div
                        className="ex-event-search-result-item__date-container-desktop">
                        <div
                            className="ex-event-search-result-item__date-start-container">

                            {
                                (!endsInOtherMonth) ? null : (
                                    <React.Fragment>
                                        <span
                                            className="h4 ex-event-search-result-item__date-startmonth text-muted"><Moment format="MMM" trim="false">{ this.startDate }</Moment></span><span
                                        className="h4 ex-event-search-result-item__date-endyear text-muted">'<Moment format="YY">{this.startDate}</Moment></span>
                                    </React.Fragment>
                                )
                            }

                        </div>
                        <div
                            className="ex-event-search-result-item__date-days-container">
                                                                                                <span
                                                                                                    className="h2 ex-event-search-result-item__date-startday"><Moment format="DD" trim="false">{ this.startDate }</Moment></span><span
                            className="h2 ex-event-search-result-item__date-separator">–</span><span
                            className="h2 ex-event-search-result-item__date-endday"><Moment format="DD" trim="false">{ this.endDate }</Moment></span>
                        </div>
                        <div
                            className={ dateEndClass }>
                                                                                                <span
                                                                                                    className="h4 ex-event-search-result-item__date-endmonth text-muted">
                                                                                                    <Moment format="MMM">{ this.endDate || this.startDate }</Moment>
                                                                                                </span><span
                            className="h4 ex-event-search-result-item__date-endyear text-muted">'<Moment format="YY">{ this.endDate || this.startDate }</Moment></span>
                        </div>
                    </div>
                    <div
                        className="ex-event-search-result-item__text-container">
                        {
                            (this.props.item.interneturl && this.props.item.interneturl.length > 5) ? ( <a className="h3 ex-event-search-result-item__headline"
                                                                            href={this.props.item.interneturl}
                                                                            target="_blank"><span>{ eventItem.eventname }</span></a>) :
                                (
                                    <span className="h3 ex-event-search-result-item__headline">{ eventItem.eventname }</span>
                                )
                        }


                        {
                            (!intro) ? null : (
                                <p className="ex-event-search-result-item__copy">

                                    <span dangerouslySetInnerHTML={ {__html: intro }} />
                                </p>
                            )

                        }

                        {
                            (typeof eventItem.content === 'undefined' || !eventItem.content) ? null : (
                                <p className="ex-event-search-result-item__copy">
                                    <a target="_blank" href={`/${this.props.langKey}/event/${eventItem.eventnumber}`}>
                                        <span className="ex-event-search-result-item__calendar-text">
                                            { Translator.translate("More information", this.props.langKey) }
                                        </span>
                                    </a>
                                </p>
                            )

                        }



                    </div>
                </div>

            </div>
        </div>)
    }
}