import EditableModule from "../Module";
import Auth from "../../../modules/Auth";
import React from "react";
import {ModuleHead} from "../ModuleHead";
import {Translator} from "../../../translator";
import EventItems from "../Events/EventItems";
import {Link} from "react-router-dom";
import {EventFilter} from "../Events/Events";
import {useRouteData, useSiteData} from "react-static";
import Strapi from "strapi-sdk-javascript";
import strapi, {createEventFilterQueryString} from "../../../helper";
import Select from 'react-select';
import moment from "moment";

let ContentEditTools;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
}

const EventTeaserIdSelector = (props) => {
    return (
        <React.Fragment>
            <Select
                className="mo-event-filter-dropdown"
                classNamePrefix="mo-event-filter-dropdown"
                placeholder="Enter an eventname to search or click the arrow on the right to select events to display"
                isMulti={true}
                value={ props.selectedEvents }
                onChange={props.onSelectedEventsChanged}
                options={ props.events }
            />
        </React.Fragment>

    )
};

const EventTeaserFilter = (props) =>
{

        return (
            <React.Fragment>
                <Select
                    className="mo-event-filter-dropdown"
                    classNamePrefix="mo-event-filter-dropdown"
                    placeholder="Show events from all industries"
                    isMulti={true}
                    value={ props.selectedIndustries }
                    onChange={props.onClustersChange}
                    options={ props.industries }
                />
                <div className="radiobuttons abstractComponent">


                    <ul className="list-inline">

                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="14">
                            <input type="radio" id="loc_all"
                                   name="Location" value="All" checked={ !props.location || props.location === "All" } onChange={() => props.onLocationChange("All")} />
                            <label htmlFor="loc_all">

                                All locations
                            </label>
                        </li>

                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="15">
                            <input type="radio" id="loc_ffm"
                                   name="Location" value="Frankfurt"  checked={ props.location === "Frankfurt" } onChange={() => props.onLocationChange("Frankfurt")} />
                            <label htmlFor="loc_ffm">

                                Events in Frankfurt

                            </label>
                        </li>
                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="165">
                            <input type="radio" id="loc_world"
                                   name="Location" value="International" checked={ props.location === "International" } onChange={() => props.onLocationChange("International")} />
                            <label htmlFor="loc_world">

                                Events worldwide (except Frankfurt)
                            </label>
                        </li>
                    </ul>


                </div>
            </React.Fragment>

        )
};

export class EventTeaserWithData extends EditableModule {
    constructor(props) {
        super(props);

        this.lang_key = (props.lang) ? props.lang.short : props.language.short;

        let content = props.content;
        if(!content.filter)
        {
            content.filter = content.linkFilter;
        }

        if(!content.isTeaser)
        {
            content.isTeaser = true;
        }

        if(!content.filter)
            content.filter = {};

        if(!content.filter.filterMode)
            content.filter.filterMode = 'filter';



        this.state = {
            content: content,
            dataBinder: null,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            events: props.events,
            allEvents: []
        };

        this.industries = (!this.props.eventClusters) ? [] : this.props.eventClusters.map((item) => {
            return {
                value: item,
                label: item
            }
        });

        this.renderContent = this.renderContent.bind(this);
        this.renderEdit = this.renderEdit.bind(this);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);

        this.getFilterValue = this.getFilterValue.bind(this);

    }
    static createForReference(refName) {
        const year = moment().format('YYYY');

        return <EventTeaser blockEdit={true} referenceName={refName}
                            events={ [
                                {
                                    id: 1,
                                    eventname: 'Event 1',
                                    startdate: year+"-11-09",
                                    enddate: year+"-11-10"
                                },
                                {
                                    id: 2,
                                    eventname: 'Event 2',
                                    startdate: year+"-11-14",
                                    enddate: year+"-11-15"
                                }
                            ]}

                            content={
            {
                isTeaser: true,
                headline: 'Event Teaser'
            }
        }/>
    }
    componentDidMount() {
        if(!this.props.referenceName)
        {
            this.getEvents(0, true);
        }
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {

        if(this.state.edit)
        {
            const changes = this.state.dataBinder.getChanges();

            if(changes)
            {
                let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = changes;

                } else {
                    contentUpdate = {
                        content: changes
                    };

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );

                this.exitEditState(changes);
                this.getEvents(0, true);
            } else {
                this.cancelEditState();
            }

        } else {
            this.enterEditState();

            const localLocale = moment();
            localLocale.locale('en');
            strapi.request("GET", `events?_sort=eventnameComplete&_limit=500&visibleUntil_gte=${localLocale.format('YYYY-MM-DD')}`).then(
                (res) => {
                     let allEvents = res.map((item) => {
                         return {
                             value: item.eventid,
                             label: item.eventnameComplete
                         }
                     });

                    this.setState({

                        allEvents: allEvents
                    });

                }
            );
        }
    }
    getEvents(pageIndex, isInitial)
    {
        if(typeof document === 'undefined')
            return;

        const strapi = new Strapi((process.env.STRAPI_PUBLIC_HOST));
        const self = this;

        let maxItems = this.state.content.maxCount || 3;

        let filter = self.state.content.filter;

        let searchQuery = createEventFilterQueryString(filter, self.state.searchQuery, maxItems, 0);

        strapi.request("GET", `eventsearch/${searchQuery}`).then(
            (res) => {
                let newItems = res;

                this.setState({
                    events: newItems,
                    hasMoreEvents: false
                });

            }
        );
    }
    getEventsearchLink()
    {
        let page = this.props.specialPages.events;

        if(!page)
            return "/";

        let baseLink = "";

        if(this.props.primaryLanguage.short === this.props.language.short)
        {
            baseLink = `/${this.props.primaryLanguage.short}/${page.Slug}`;
        } else {
            if(page.translations[this.props.language.short] && page.translations[this.props.language.short].Slug)
            {
                baseLink = `/${this.props.language.short}/${page.translations[this.props.language.short].Slug}`;
            } else {
                return "/";
            }

        }
        let filter = this.state.content.filter;
// http://localhost:3001/en/event-search?filter_industries=Consumer%20Goods&filter_location=Frankfurt
        if(filter && filter.filterMode === "filter")
        {
            let filters = [];
            if(filter.industries)
            {
                let indFilter = [];
                for(let industry of filter.industries)
                {
                    indFilter.push(encodeURI(industry)
                            .replace(/,/g, ';')
                            .replace(/&/g, '%26')
                        );
                }
                let induFilter = indFilter.join(',');

                filters.push(
                    "filter_industries="+induFilter
                )
            }
            if(filter.location)
            {
                filters.push(
                    "filter_location="+filter.location
                )
            }

            return baseLink + "?" + filters.join("&");
        }


        return baseLink;
    }
    renderContent() {
        return       <div className="">
            <div id="mf-ev-root">
                <div data-reactroot="" className="ex-application">
                    <div>
                        <div>
                            <div className={(this.state.content.isTeaser) ? "o-search-results-container o-search-results-container--eventsearchslim ev-event-search-slim" : "ex-event-search"} >
                                {
                                    (this.state.content.isTeaser) ? null : (
                                        <div className="ex-search-form">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-10 col-md-9">
                                                    <div autoComplete="off"
                                                         className="m-form a-form-input a-form-input--searchfield">
                                                        <div className="ex-input-autosuggest"><input
                                                            type="text"
                                                            className="ex-search-form__searchbar form-control"
                                                            name="q" onChange={ this.handleSearchChange }
                                                            placeholder={ Translator.translate("Enter an event name.", this.lang_key)}
                                                            value={ this.state.searchQueryTemp } onKeyDown={ this.handleSearchKeyDown } /></div>
                                                        <div
                                                            className="ex-search-form__submit btn btn-primary btn-icon-single btn-icon-single-xxs"
                                                            type="submit"  onClick={ this.handleSearchClick }><span
                                                            className="icon icon-magnifier"></span><span
                                                            className="btn-label btn-label-hidden-xxs">{ Translator.translate("Search", this.lang_key) }</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                (this.state.content.isTeaser) ? null : (
                                                    <EventFilter lang={ this.props.language } filter={this.state.content.filter} onFilterChanged={this.handleFilterChanged } eventClusters={this.eventClusters} />
                                                )
                                            }

                                        </div>
                                    )
                                }

                                <h2 style={{display:'none'}} className="ex-search-headline"><span
                                    className="ex-search-headline__text">Ihre Suche ergab 220 Treffer.</span>
                                </h2>
                                <div>

                                    <div className={ (!this.state.content.isTeaser) ? "ex-event-search__container h-background h-background--fullwidth ex-event-search__container_narrow" :  "o-search-results-container__results h-background h-background--fullwidth"}>
                                        <EventItems
                                            langKey={this.lang_key}
                                            isTeaser={this.state.content.isTeaser}
                                            filter={this.state.content.filter}
                                            events={ this.state.events }
                                            searchQuery={ this.state.searchQuery }
                                        />
                                    </div>

                                    {
                                        (!this.state.content.isTeaser) ? null : (
                                            <div
                                                className="o-search-results-container__more-results h-background h-background--fullwidth">
                                                <p><Link className="a-link a-link--single-internal-left"
                                                         to={ this.getEventsearchLink() }
                                                         rel="noopener noreferrer" target="_blank">{ Translator.translate("Go to event search", this.lang_key)}</Link></p></div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    getFilterValue(filterName)
    {
        let bound = this.state.dataBinder.bind("filter");

        return (bound) ? bound.getValue()[filterName] : null;
    }
    setFilterValue(filterName, value)
    {
        let bound = this.state.dataBinder.bind("filter").getValue();
        bound[filterName] = value;
        this.state.dataBinder.bind("filter").updateValue(bound);
        this.forceUpdate();
    }
    renderEdit() {

        let filter = null;

        if(this.getFilterValue("filterMode") === "filter")
        {
            filter =  <EventTeaserFilter industries={this.industries}
                                         selectedIndustries={ this.industries.filter((item) => {return (this.getFilterValue("industries")) ? this.getFilterValue("industries").includes(item.value) : false})}
                                         onClustersChange={ (items) => { this.setFilterValue("industries", items.map((item) => (item.value))); }}
                                         location={ this.getFilterValue("location") }
                                         onLocationChange={ (location) => { this.setFilterValue("location", location)}}
            />;
        } else if(this.getFilterValue("filterMode") === "ids")
        {
            let selected = this.getFilterValue("eventIds") || [];
            filter = <EventTeaserIdSelector events={ this.state.allEvents }
                onSelectedEventsChanged={
                    (items) =>
                    this.setFilterValue("eventIds", items.map((it) => {return it.value}))
                }
                selectedEvents={
                    this.state.allEvents.filter((item) => { return selected.indexOf(item.value) > -1  })
                } />
        }

        return (
            <div className="mo-event-teaser-edit">
            <div className="radiobuttons abstractComponent">
                <div className="a-form-input a-form-input--checkbox" data-t-name="FormInput" data-t-id="61">
                    <p>
                    <input type="checkbox" id="8ae026a439997e0c300c219de067cf1ca425f505c96daba766565e7f8cd318a4_0"
                           name="Anrede" value="herr" onChange={ (ev) => this.setFilterValue("withOwnContent", ev.target.checked) } checked={this.getFilterValue("withOwnContent")} />
                    <label htmlFor="8ae026a439997e0c300c219de067cf1ca425f505c96daba766565e7f8cd318a4_0">
                        Show only events for which I added detail content
                    </label>
                    </p>
                </div>
            </div>
            <div className="a-form-input a-form-input--text " >
                <input className="js-form-input__clear-text" type="number"
                       id="eventCount" name="eventCount"
                       value={this.state.dataBinder.bind("maxCount").getValue()}
                       onChange={(ev) => { this.state.dataBinder.bind("maxCount").updateValue(ev.target.value); this.forceUpdate() }}
                       required="" placeholder="Maximum number of events to show (defaults to 3)"  width={20} pattern="[0-9]*" maxLength="3" />
                <label
                    htmlFor="eventCount" >Maximum number of events to show (defaults to 3)
                   </label>

            </div>
            <div className="radiobuttons abstractComponent">


                <ul className="list-inline">

                    <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="14">
                        <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0"
                               name="Filter" value="all"
                               checked={ this.getFilterValue("filterMode") === 'none' }
                               onChange={() => this.setFilterValue("filterMode", "none") } />
                        <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0">
                            Don't filter displayed events
                        </label>
                    </li>

                    <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="15">
                        <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1"
                               name="Filter" value="whitelist"
                               checked={ this.getFilterValue("filterMode") === 'filter' }
                               onChange={() => this.setFilterValue("filterMode", "filter") }/>
                        <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1">

                            Filter displayed events

                        </label>
                    </li>
                    <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="165">
                        <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2"
                               name="Filter" value="blacklist"
                               checked={ this.getFilterValue("filterMode") === 'ids' }
                               onChange={() => this.setFilterValue("filterMode", "ids") }/>
                        <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2">

                            Only show selected events
                        </label>
                    </li>
                </ul>


            </div>
                <div className="radiobuttons abstractComponent">
                    { filter }
                </div>
            </div>
        )
    }
    render() {

        return (



            <div data-reference={ this.props.referenceName }
                 className={(this.state.content.isTeaser) ? "eventSearchSlimPrefiltered eventSearchSlim abstractComponent" : "eventSearch abstractComponent"}>

                {
                    (!this.state.allowEdit) ?  null :
                        (
                            <ContentEditTools
                                edit={this.state.edit}
                                onCancel={ this.handleCancelClick }
                                onEditClick={this.handleEditClick }
                                onChange={this.handleHeadlineChange}
                                onDelete={this.handleDelete }
                                showMove={false}
                                showSettings={ true }
                                showDelete={this.props.userCreated}
                            />
                        )

                }

                <ModuleHead
                    edit={ this.state.edit }
                    headline={this.state.content.headline}
                    binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : ""}
                />

                { (this.state.edit && this.props.language.short === this.props.primaryLanguage.short) ? this.renderEdit() : this.renderContent() }


            </div>

        );
    }
}

export function EventTeaser (props)
{
    const {  eventData,eventClusters, language  } =  useRouteData();
    const { specialPages, primaryLanguage } = useSiteData();
    let events = [];

    if(props.id && eventData[props.id])
    {
        events = eventData[props.id];
    } else if(props.events)
    {
        events = props.events;
    }

    return <EventTeaserWithData specialPages={specialPages} primaryLanguage={primaryLanguage} language={language} eventClusters={eventClusters} events={ events } { ... props }  />;
}
