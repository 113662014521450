import React from "react";
import Auth from "../../../modules/Auth";
import {ModuleHead} from "../ModuleHead";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";
import strapi, {ResponsiveImage} from "../../../helper";
import {DataBinder} from "../../admin/DataBinder";
import EditableModule from "../Module";
import ContentDragHandle from "../../admin/ContentDragHandle";

let ContentEditTools;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
    useDropzone = require('react-dropzone').useDropzone;
}

function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                <span>Drop a file here, or click to select a file from your local files</span>
            </div>
        )
    }
}

class DownloadItemEdit extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            dataBinder: props.dataBinder
        };

        this.handleRemoveFile = this.handleRemoveFile.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if(prevState.dataBinder !== nextProps.dataBinder){
            return {
                dataBinder: nextProps.dataBinder
            }

        } else {
            return null;
        }
    }
    handleRemoveFile()
    {
        this.state.dataBinder.bind('download').updateValue(null);
        this.forceUpdate();
    }
    async handleDrop(acceptedFiles)
    {
        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id,
                size: files[0].size,
                name: files[0].name
            };

            let imageBound = this.state.dataBinder.bind("download");

            imageBound.updateValue(uploadedFile);

            this.forceUpdate();
        }
    }
    handleDeleteClick()
    {
        this.props.onDelete(this.state.dataBinder);
    }
    handleTitleChange(ev)
    {
        this.state.dataBinder.updateProperty("title", ev.target.value);
        this.forceUpdate();
    }
    render()
    {
        let file = this.state.dataBinder.bind("download").getValue();


        let fileContainer = (
            <div className="textfield abstractComponent">
                <p><strong>File</strong></p>
                <MyDropzone onDrop={this.handleDrop} />
            </div>
        );

        if(file)
        {
            fileContainer = (
                <div className="textfield abstractComponent">
                    <p><strong>File</strong></p>
                    <span>{ file.name }</span>
                    &nbsp;&nbsp;<span className="a-link" onClick={this.handleRemoveFile} >Remove</span>
                </div>
            )
        }
        return <div className="mo-downloads-edit-item">
            <div className="mo-downloads-edit-item-icons">
                <ContentDragHandle />
                <div className="mo-edit-icon" onClick={ this.handleDeleteClick }
                     data-tip="Delete this download">
                    
                </div>
            </div>
            <div className="mo-downloads-edit-item-content">
                <div className="textfield abstractComponent">
                    <p><strong>Title</strong></p>
                    <input
                        className="js-form-input__clear-text" type="text"
                        value={this.state.dataBinder.bind("title").getValue()}
                        id={`downloadtitle_${(this.props.idx).toString()}`}
                        name="name" maxLength="254" onChange={ this.handleTitleChange } />
                </div>
                {fileContainer}
            </div>
        </div>;
    }
}


const SortableItem = SortableElement(({item, idx, formItems, siteUrls, onRemove, binderItems}) => {


    return ( <DownloadItemEdit key={idx}  idx={idx} onDelete={onRemove}  edit={true} siteUrls={siteUrls} dataBinder={item} /> )
});

const SortableList = SortableContainer(({items, formItems, isSorting, onRemove, siteUrls, binderItems}) => {
    let className = "";
    if(isSorting)
    {
        className = "mo-sortable-container mo-sortable-container-active"
    }

    return (
        <div className={className}>
            {
                items.map((item, index) => (
                    <React.Fragment key={`frag-${index}`}>
                        <SortableItem key={`item-${index}`}
                                      item={item}
                                      idx={index}
                                      index={index}
                                      siteUrls={siteUrls}
                                      onRemove={(item) => onRemove(item, index)}
                                      formItems={formItems} />
                    </React.Fragment>

                ))
            }
            <div style={{clear:'both'}}></div>
        </div>
    );
});

class DownloadItem extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            content: props.content,
        };
    }
    static formatFileInfo(download)
    {
        if(!download || !download.ext)
            return null;
        let sizeInt = parseInt(download.size);
        let sizeText = "";
        if(sizeInt < 950)
        {
            sizeText = sizeInt.toString() + " KB"
        } else {
            sizeText = Math.round(sizeInt/1000).toString() + " MB";

        }

        let extText = download.ext.substr(1);

        return <React.Fragment>
            (
                <span className="m-download-list__datatype">{ extText }</span>,&nbsp;
                { sizeText }
            )
        </React.Fragment>
    }
    static getFileUrl(download)
    {
        if(!download || !download.name)
            return "#";

        return `/content/uploads/${download.hash}${download.ext}`
    }
    render()
    {
        if(!this.state.content || !this.state.content.download)
            return null;

        return <li>

            <a className="a-link" target="_blank"
               download={this.state.content.download.name} rel="noopener noreferrer"
               href={DownloadItem.getFileUrl(this.state.content.download)}>{ (this.state.content.title || "Download") }</a>
            &nbsp;
         {DownloadItem.formatFileInfo(this.state.content.download)}

        </li>;
    }
}
export class Downloads extends EditableModule {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content,
            dataBinders: [],
            hasChanges: false,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);
        this.handleDeleteItemClick = this.handleDeleteItemClick.bind(this);

    }
    static createForReference(refName)
    {
        return <Downloads blockEdit={true} referenceName={refName} content={
            {
                headline: 'Downloads',
                items: [
                    {
                        download: {
                            hash: '4a47d8e494014edda20d447b9aa3d3f0',
                            ext: '.jpg',
                            size: '1280',
                        },
                        title: 'Download 1'
                    },
                    {
                        download: {
                            hash: '4a47d8e494014edda20d447b9aa3d3f0',
                            ext: '.jpg',
                            size: '280',
                        },
                        title: 'Download 2'
                    }
                ]
            }
        } />
    }
    onSortEnd = async ({oldIndex, newIndex}) =>  {


        const sortedArray = arrayMove(this.state.dataBinders, oldIndex, newIndex);

        this.setState({
            dataBinders: sortedArray,
            hasChanges: true
        });

    };
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {

            let items = [];
            let hasChanges = this.state.hasChanges || this.state.dataBinder.hasChanges;

            for(let dataBinder of this.state.dataBinders)
            {
                let changes = dataBinder.getChanges();
                if(changes)
                {
                    hasChanges = true;
                } else {
                    changes = dataBinder.getRawContent();
                }

                items.push(changes);


            }

            if(hasChanges)
            {
                let content;

                let contentUpdate = {
                    content: (this.state.dataBinder.hasChanges) ? this.state.dataBinder.getChanges() : this.state.content
                };



                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = this.state.content;
                    contentUpdate.ContentTranslation[this.props.language.short].items = items;


                    content =  contentUpdate.ContentTranslation[this.props.language.short];
                } else {
                    contentUpdate.content.items = items;

                    content = contentUpdate.content;

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(content);
            } else {
                this.cancelEditState();
            }
        } else {
            let dataBinders = [];
            if(this.state.content.items)
            {
                for(let item of this.state.content.items)
                {
                    dataBinders.push(new DataBinder(item));
                }
            }


            this.enterEditState({
                dataBinders: dataBinders
            });
        }
    }
    handleAddItemClick()
    {
        this.state.dataBinders.push(new DataBinder({headline:'New download'}));
        this.setState({
            hasChanges: true
        });
    }
    handleDeleteItemClick(item)
    {
        const itemIdx = this.state.dataBinders.indexOf(item);
        this.state.dataBinders.splice(itemIdx, 1);

        this.setState({
            dataBinders: this.state.dataBinders,
            hasChanges: true
        });
    }
    renderItems()
    {
        return (
            <ul>

                {
                    (!this.state.content.items) ? null : (
                        this.state.content.items.map((item, idx) => {
                            return <DownloadItem onDelete={this.handleDeleteItemClick }
                                                  key={idx}
                                                  edit={this.state.edit}
                                                  siteUrls={this.props.siteUrls}
                                                  content={item}  />
                        }))
                }


            </ul>
        );
    }
    renderEdit()
    {
        const items = this.state.dataBinders;
        return (

            <React.Fragment>
                <SortableList  useDragHandle={true} items={items} formItems={this.formItems}
                               helperClass={"mo-sortable-item mo-sortable-content"}
                               onSortEnd={this.onSortEnd}

                               updateBeforeSortStart={ this.onBeforeStart }
                               useWindowAsScrollContainer={true} isSorting={this.state.sorting}
                               onRemove={this.handleDeleteItemClick}
                               siteUrls={this.props.siteUrls}
                >
                </SortableList><span className="btn btn-default" onClick={this.handleAddItemClick}>Add item</span>
            </React.Fragment>


        );
    }
    render() {
        return (
            <div className="downloadArea abstractComponent" data-reference={ this.props.referenceName }>


                <div className="m-download-list " data-t-name="DownloadList">
                    {
                        (!this.state.allowEdit) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onDelete={this.handleDelete }
                                    onChange={this.handleHeadlineChange}
                                    showMove={!this.state.edit}
                                    showSettings={ false }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }
                    <ModuleHead
                        edit={ this.state.edit }
                        binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                        headline={ this.state.content.headline } />

                    {
                        (this.state.edit) ? this.renderEdit() : this.renderItems()
                    }
                </div>


            </div>
        );
    }
}