import React from "react";
import strapi from "../../helper";
import '../../styles/makaio.css';
import Select from "react-select";
export default class EventDetailAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            eventKeys: [],
            existingEventNumbers: []
        };

        this.newRelations = [];
        this.deletedRelations = [];

        this.handleEventSelected = this.handleEventSelected.bind(this);
        this.updateEventKeys = this.updateEventKeys.bind(this);
    }
    componentDidMount() {
        strapi.request("GET", `eventsearch?ownContent=true`).then(
            async (res) => {
                let existingEventNumbers = [];
                let eventsWithContents = [];
                for(let item of res)
                {
                    if(!existingEventNumbers.includes(item.eventnumber))
                    {
                        existingEventNumbers.push(item.eventnumber);

                        eventsWithContents.push({
                            eventnumber: item.eventnumber,
                            pageId: item.content.pageId,
                            eventname: item.eventname,
                            content: item.content

                        })
                    }
                }


                if(!window.moeventkeys)
                {
                    let req = await strapi.request('GET', `${process.env.STRAPI_PUBLIC_HOST}/eventkeys`);

                    window.moeventkeys = req;
                }

                let eventkeyDict = window.moeventkeys;


                this.updateEventKeys(eventkeyDict, existingEventNumbers, eventsWithContents);
            });

    }
    updateEventKeys(eventkeyDict, existingEventNumbers, eventsWithContents)
    {
        let eventKeys = [];


        for(let key of Object.getOwnPropertyNames(eventkeyDict))
        {
            if(!existingEventNumbers.includes(key))
            {
                eventKeys.push({
                    value: key,
                    label: eventkeyDict[key]
                });
            }

        }


        eventsWithContents.sort(function(a, b) {
            const nameA = a.eventname.toUpperCase();
            const nameB = b.eventname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;

        });

        eventKeys.sort(function(a, b) {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;

        });

        this.setState({
            items: eventsWithContents,
            eventKeys: eventKeys,
            existingEventNumbers: existingEventNumbers
        });
    }
    handleEventDeleted(item)
    {


        if(item.pageId) /* existing item */
        {
            this.deletedRelations.push({
                relationId: item.content.id.toString(),
                pageId: item.pageId
            })

        } else
        {
            let idx = this.newRelations.indexOf(item);
            if(idx > -1)
            {
                this.newRelations.splice(idx, 1);
            }
        }

        let eventsWithContents = this.state.items.filter((existingItem) => {
            return item.eventnumber !== existingItem.eventnumber
        });

        let existingEventNumbers = this.state.existingEventNumbers.filter((existingItem) => {
            return item.eventnumber !== existingItem.eventnumber
        });

        this.props.onChanged(this.newRelations, this.deletedRelations);
        this.updateEventKeys(window.moeventkeys, existingEventNumbers, eventsWithContents);
    }
    handleEventSelected(item)
    {
        // label / value

        let eventsWithContents = this.state.items;
        let existingEventNumbers = this.state.existingEventNumbers;

        existingEventNumbers.push(item.value);

        let newRelation = {
            eventnumber: item.value,
            eventname: item.label,
            pageId: 0
        };

        eventsWithContents.push(newRelation);

        this.newRelations.push(newRelation);


        this.updateEventKeys(window.moeventkeys, existingEventNumbers, eventsWithContents);

        this.props.onChanged(this.newRelations, this.deletedRelations);
    }
    showAlert()
    {
        alert('Please save your current changes to edit the event\'s content');
    }
    getItem(item)
    {
        let entry = <a className="a-link a-link--closure" target="_blank" href={ `/${this.props.primaryLanguage.short}/event/${item.eventnumber}`}>{ item.eventname }</a>;

        if(!item.content)
        {
            entry = <a className="a-link" data-reference={true} href="#" onClick={ this.showAlert }>{ item.eventname } (new)</a>
        }
        return entry;
    }
    render()
    {



        return <div className="textfield abstractComponent">
            <p><strong>Events with added content:</strong></p>
            <ul className="mo-eventdetail-admin-items">
            { this.state.items.map((item, idx) => {
                return <li key={idx} className="mo-eventdetail-admin-item">
                            <div>
                                { this.getItem(item) }
                                &nbsp;&nbsp;&nbsp;
                                <div className="mo-edit-icon mo-edit-icon-remove" onClick={() => {this.handleEventDeleted(item)}}></div>
                            </div>
                        </li>;
            })}
            </ul>
            <p>
                <strong>Add content for event</strong>
                <Select
                    className="mo-event-filter-dropdown"
                    classNamePrefix="mo-event-filter-dropdown"
                    placeholder="Enter an eventname to search or click the arrow on the right to select an event"
                    onChange={this.handleEventSelected}
                    options={ this.state.eventKeys}
                    value={null}
                    isMulti={false}
                />
            </p>
        </div>;
    }
}