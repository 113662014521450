import React from "react";
import {Head} from "react-static";
import {isRTL} from "../helper";

export  default  function  MetaHeader({page, language}) {

    let pagetitle = page.Pagetitle.replace(/&apos;/gm, "'") + " - Messe Frankfurt Salespartner";
    let metaItems = [];

    const useRTL = isRTL(language);

    let description = "";
    if(page.Description)
    {
        description = page.Description + ": ";
    }
    if(page.Intro)
    {
        description += page.Intro;
    }
    if(description.length > 1)
    {
        metaItems.push( <meta key="description" name="description" content={description}/>);
        metaItems.push( <meta key="og-description" name="og:description" content={description}/>);
        metaItems.push( <meta key="twitter-description" name="twitter:description" content={description}/>);
    }

    if(page.Pagetitle)
    {

        metaItems.push( <meta key="og-title"  name="og:title" content={pagetitle}/>);
        metaItems.push( <meta key="twitter-title" name="twitter:title" content={pagetitle}/>);
    }

    if(page.Header)
    {

    }

    if(useRTL)
    {
        metaItems.push(
            <style type="text/css">{`
            body {
               direction:rtl;
            }
            
         
        `}</style>
        )
    }

    return (
        <Head>
            <title>{ pagetitle } </title>
            {metaItems}
        </Head>
    );
}
