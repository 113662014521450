import Strapi from "strapi-sdk-javascript";
import React from "react";
import cookie from "react-cookies";
import moment from "moment";

const axios = require('axios');


export const strapi = new Strapi(process.env.STRAPI_PUBLIC_HOST);

export const strapiShared = axios.create({
  baseURL: process.env.STRAPI_HOST_COMMON,
  headers: {
    common: {},

  },
  transformRequest: [(data, headers) => {
    delete headers.common.Authorization
    return data
  }]
});


export const createEventFilterQueryString = (filter, searchQueryText, pageSize, pageIndex) => {
  let searchQuery = `?_start=${pageSize*pageIndex}`;
  searchQuery += `&_limit=${pageSize}`;

  const localLocale = moment();
  localLocale.locale('en');
  searchQuery += `&visibleUntil_gte=${localLocale.format('YYYY-MM-DD')}`;

  if(searchQueryText)
  {
    searchQuery += "&eventname_contains=";
    searchQuery += searchQueryText;
  }

  if(filter)
  {
    let filterMode = "filter";
    if(filter.filterMode)
    {
      filterMode = filter.filterMode;
    }

    if(filterMode === "filter")
    {
      if(filter.industries)
      {
        for(let industry of filter.industries)
        {
          searchQuery += "&clusterMarketing_in=";
          searchQuery += industry.replace("&","%26");
        }

      }

      if(filter.year)
      {
        searchQuery += "&eventyear_eq=";
        searchQuery += filter.year.toString();
      }

      if(filter.location && filter.location !== 'All')
      {
        if(filter.location === 'Frankfurt')
        {
          searchQuery += "&cityname_eq=Frankfurt+am+Main";
        } else {
          searchQuery += "&cityname_ne=Frankfurt+am+Main";
        }
      }
    } else if(filterMode === "ids") {
      if(filter.eventIds)
      {
        for(let eventId of filter.eventIds)
        {
          searchQuery += "&eventid_in=";
          searchQuery += eventId;
        }
      }
    }

    if(filter.withOwnContent)
    {
      searchQuery += "&ownContent=true";
    }

  }


  if(!searchQuery)
    searchQuery = "";

  return searchQuery;
};

export const createEventFilterQuery = (content) => {
  const localLocale = moment();
  localLocale.locale('en');

  let query = {
    _limit: 25,
    visibleUntil_gte: localLocale.format('YYYY-MM-DD')
  };

  if(content && content.filter)
  {
    const filter = content.filter;
    let filterMode = "filter";
    if(filter.filterMode)
    {
      filterMode = filter.filterMode;
    }

    if(filterMode === "filter")
    {
      if(filter.industries)
      {
        query.clusterMarketing_in = filter.industries;
      }


      if(filter.year)
      {
        query.eventyear_eq = filter.year.toString();
      }

      if(filter.location && filter.location !== 'All')
      {
        if(filter.location === 'Frankfurt')
        {
          query.cityname_eq = "Frankfurt+am+Main";
        } else {
          query.cityname_ne = "Frankfurt+am+Main";
        }
      }
    } else if(filterMode === "ids") {
      if(filter.eventIds)
      {
        query.eventid_in = filter.eventIds;
      }
    }

    if(filter.withOwnContent)
    {
      query.ownContent = true;
    }
  }

  if(content && content.maxCount)
  {
    query._limit = content.maxCount;
  }

  return query;
};

export const isEditing = () => {
  return false;
};

let internalCookiesAllowed = -1;
export const cookiesAllowed = () => {
  if(internalCookiesAllowed === -1)
  {
    internalCookiesAllowed = (cookie.load('cookies-accepted') !== null && cookie.load('cookies-accepted') !== undefined);

  }
  return internalCookiesAllowed;
};

export function slugify(string) {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžżő·/_,:;'
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzzo------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
      .replace(/ő/g, 'o')
}

export const mf_ImageSizes = [
  50,
  150,
  300,
  480,
  768,
  1024,
  1280,
  1920,
  2560
];

export function ResponsiveImage({img, width}) {
  const imageUrlIn = img.hash + img.ext;
  const imageUrl = `/content/uploads/300w/${imageUrlIn}`;
  const imageSet = mf_ImageSizes.map((size) => {
    return  `/content/uploads/${size}w/${imageUrlIn} ${size}w`
  }).join(', ');

  return (
      <img className="img-responsive"
           src={imageUrl}
           srcSet={imageSet}
           width={width || '100%'}
           alt="" />);

}

export const isRTL = (language) => {

  return (language.short === 'ar' || language.long === "Arabic") ||
      (language.short === 'fa' || language.long === "فارسی") ||
      (language.short === 'he' || language.long === "Hebrew");
}

export class AppGlobals {

}

export default strapi;
