import React from 'react'
import '../../styles/app.css'
import {useRouteData, useSiteData} from "react-static";
import {Translator} from "../../translator";
function updatePage(props)
{

    let languageMap = {};
    props.languages.forEach((lang) => {
        languageMap[lang.id] = lang;

        if(props.page && props.page.translations)
        {
            let translation = props.page.translations[lang.id];

            if(translation)
            {
                languageMap[lang.id].pageSlug = translation.Slug;
            } else {
                languageMap[lang.id].pageSlug = '';
            }
        }

    });

    return languageMap;
}
class LangSwitch extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            isActive: false,
            currentLanguage: props.currentLanguage || props.languages[0],
            languageMap: updatePage(props)
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.currentLanguage.short !== nextProps.currentLanguage.short){
            return {  isActive: false,
                currentLanguage: nextProps.currentLanguage,
                languageMap: updatePage(nextProps)
            };
        } else {
            return {
                languageMap: updatePage(nextProps)
            };
        }

    }

    handleClick()
    {
        this.setState({
            isActive: !this.state.isActive
        })
    }
    getLink(lang)
    {

       // if(this.props.page.PageType !== "Home" && this.props.page.translations && this.props.page.translations[lang.short] &&this.props.page.translations[lang.short].Slug) {
        if(this.props.page.translations && this.props.page.translations[lang.short] &&this.props.page.translations[lang.short].Slug)
        {
            if(this.props.page.event)
            {
                return `/${lang.short}/event/${this.props.page.translations[lang.short].Slug}`;
            }
            return `/${lang.short}/${this.props.page.translations[lang.short].Slug}`;
        }

        if(this.props.page.event)
        {
            return `/${lang.short}/event/${this.props.page.event.eventnumber}`;
        }

        if(this.props.page.PageType === "Home" && this.props.primaryLanguage.short === lang.short)
            return '/';
        
       /* } else {
            //if(this.props.primaryLanguage.short === lang.short)
            //    return '/';

            return `/${lang.short}`;
        }*/
    }
    render() {
        if(!this.props.languages || this.props.languages.length === 0)
            return null;


        let cssClasses = "m-dropdown o-header__language-options";

        if(this.state.isActive)
        {
            cssClasses += " o-header__language-options--active";
        }
        return (
            <div className="o-header__language" onClick={this.handleClick}>
                <span className="o-header__language-toggle js-o-header__language-toggle">
                    <span>{this.state.currentLanguage.short.toUpperCase()}</span>
                </span>
                <ul className={cssClasses}>
                    {
                        this.props.languages.map((lang) => {
            
                            return (
                                <li key={lang.short} className={(this.state.currentLanguage.short === lang.short) ? "m-dropdown-item m-dropdown-item--disabled" : "m-dropdown-item" }>
                                    <a href={ this.getLink(lang) } disabled={this.state.currentLanguage.short === lang.short } tabIndex="-1">
                                        {lang.short.toUpperCase()} - {lang.long}
                                    </a>
                                </li>
                            )
                        })
                    }


                </ul>
            </div>
        );
    }
}

function  LangSwitchWithData(props) {
    const {page, language } = useRouteData();
    const { languages, primaryLanguage } = useSiteData();

    const pageToUse = props.page || page;

    if(!languages || languages.length < 2)
        return null;

    return (
        <LangSwitch page={pageToUse} primaryLanguage={primaryLanguage} currentLanguage={ language } languages={languages} { ...props } />
    );
}
export default class ContentHeader extends React.Component {
    constructor(props) {
        super(props);

        this.handleMenuClick = this.handleMenuClick.bind(this);

        this.lang_key = props.lang.short;


    }

    handleMenuClick()
    {
        this.props.onMenuClick();
    }
    render(){

        let className = "o-header o-header--fix";
        if(this.props.isHidden)
        {
            className += " o-header--fix-hidden";
        }

        if(this.props.isDocked)
        {
            className += " o-header--fix-docked";
        }

        return (
            <header className={ className } data-t-name="Header">

                <div className="container">
                    <div className="o-header__wrap">
                        <div className="row">
                            <div className="col-xxs-4 col-xxs-4--displayed-without-content">
                                <a className="o-header__main-navigation-toggle js-o-header__main-navigation-toggle"
                                   accessKey="m" onClick={ this.handleMenuClick }>
                                    <span>{Translator.translate("Menu", this.lang_key)}</span>
                                </a>
                            </div>
                            <div className="col-xxs-4">
                                <a className="o-header__corporate-logo" target="_blank" href="https://www.messefrankfurt.com/frankfurt/de.html">
                                    <picture>

                                        <source srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-big.svg"
                                                media="(min-width: 1024px)" />
                                        <source
                                            srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg"
                                            media="(max-width: 1023px)" />
                                        <img
                                            src="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg" />
                                    </picture>
                                </a>
                            </div>
                            <div className="col-xxs-4 o-header__right">
                                <LangSwitchWithData />
                                <div className="o-header__login">
                                    <div id="infobox" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
        )
    }
}