import React from "react";
import '../../../styles/app.css'
import Auth from "../../../modules/Auth";
import {DataBinder} from "../../admin/DataBinder";
import strapi, {ResponsiveImage} from "../../../helper";
import EditableModule from "../Module";
import MfLink from "../../ui/MfLink";
import EditIconLink from "../../admin/EditIconLink";


let ContentEditTools;
let RichTextEditor;
let HeadlineEditor;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
    RichTextEditor = require('../../admin/RichTextEditor').default;
    HeadlineEditor = require('../../admin/HeadlineEditor').default;
    useDropzone = require('react-dropzone').useDropzone;
}




function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
            accept: 'image/png, image/jpg, image/jpeg'
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                {
                    (img) ? <ResponsiveImage width="200px" img={img}/> : (
                        <span>Drop a file here, or click to select a file from your local files</span>)

                }
            </div>
        )
    }
}

export class TeaserTwoCol extends EditableModule {
    constructor(props) {
        super(props);


        this.state = {
            isToggleOn: true,
            content: props.content,
            edit: false,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            dataBinderLeft: null,
            dataBinderRight: null
        };
;

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }
    static getReferenceContent()
    {
        return   {
            headline: 'Teaser Two Columns',
            left: {
                link: '',
                headline: 'Left teaser',
                button: 'Left button',
                text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>'
            },
            right: {
                link: '',
                headline: 'Right teaser',
                button: 'Right button',
                text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>'
            }
        };
    }
    static createForReference(refName)
    {
        return <TeaserTwoCol blockEdit={true} referenceName={refName}  siteUrls={[]} content={
              this.getReferenceContent()
            }
            />
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {

        if(this.state.edit)
        {

            const changesLeft = this.state.dataBinderLeft.getChanges();
            const changesRight = this.state.dataBinderRight.getChanges();
            if(changesLeft || changesRight )
            {
                const changes = {
                    left: changesLeft || this.state.content.left,
                    right: changesRight || this.state.content.right
                };

                let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.lang.short] = changes;

                } else {
                    contentUpdate = {

                        content: changes

                    };

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(changes);
            } else {
                this.cancelEditState();
            }

        } else {

            let newState = {
                dataBinderLeft: new DataBinder(this.state.content["left"]),
                dataBinderRight: new DataBinder(this.state.content["right"])
            };
            this.enterEditState(newState);
        }
    }
    handleRemoveImage(dataBinder)
    {

        dataBinder.updateProperty("image", null);
        this.forceUpdate();
    }
    async handleDrop(acceptedFiles, dataBinder)
    {

        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id
            };

            await strapi.request('GET', `/generateThumbnail/${uploadedFile.hash}${uploadedFile.ext}`);


            dataBinder.updateProperty("image", uploadedFile);
            this.forceUpdate();
        }
    }
    getContent(leftOrRight, state)
    {

        const isRight = leftOrRight === "right";
        const content = state.content[leftOrRight] || {};
        return (
            <div className={ (isRight) ? "col-sm-6 m-teaser-container__right-column" : "col-sm-6 m-teaser-container__left-column" }>
                <div className={ `m-teaser h-background h-background--halfwidth-${(isRight) ? "right" : "left"} `} data-t-name="Teaser"
                     data-t-id="36">
                    <a className="m-teaser__link" target="_blank" rel="noopener noreferrer"
                       href={ content.link }>
                        <div className="m-teaser__inner">

                            {
                                (!content.image) ? null : (
                                    <div className="a-image" data-t-name="Image">
                                        <figure>
                                            <ResponsiveImage img={content.image} />
                                        </figure>
                                    </div>
                                )
                            }

                            <div className="m-teaser__content">
                                <h3 dangerouslySetInnerHTML={ {__html: content.headline } }/>
                                <div dangerouslySetInnerHTML={ {__html: content.text } } />


                                <span className="btn btn-default"  dangerouslySetInnerHTML={ {__html: content.button } } />
                            </div>
                        </div>
                    </a>
                </div>
            </div>);
    }
    getContentEdit(leftOrRight, state)
    {
        const isRight = leftOrRight === "right";
        const dataBinder = (isRight) ? this.state.dataBinderRight : this.state.dataBinderLeft;
        return (
            <div className={ (isRight) ? "col-sm-6 m-teaser-container__right-column" : "col-sm-6 m-teaser-container__left-column" }>
                <div className={ (isRight) ? "m-teaser h-background h-background--halfwidth-right" : "m-teaser h-background h-background--halfwidth-left"  } data-t-name="Teaser"
                     data-t-id="36">
                        <div className="m-teaser__inner">


                            <div className="m-teaser__content">
                                <div className="image-edit-container">
                                    <strong>Image:</strong><br/>

                                    {
                                        (!dataBinder.bind("image").getValue()) ? null : (
                                            <div className="mo-edit-icon-container">
                                                <div className="mo-edit-icon-container-items">
                                                    <div className="mo-edit-icon" onClick={() => this.handleRemoveImage(dataBinder)}></div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <MyDropzone img={dataBinder.bind("image").getValue()} onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles, dataBinder)} />
                                </div>


                                <HeadlineEditor binding={dataBinder.bind("headline")} headlineTag="h3"/>

                                <RichTextEditor binding={ dataBinder.bind("text")} />


                                <MfLink enabled={!this.state.edit} link={this.state.content.link}>
                                    <div className="btn btn-default">
                                        {
                                            (this.state.edit) ?  <HeadlineEditor binding={dataBinder.bind("button")} headlineTag="span" /> : (
                                                <span dangerouslySetInnerHTML={ {__html: this.state.content.button }} />
                                            )
                                        }

                                    </div>
                                </MfLink>

                                {
                                    (!this.state.edit) ? null : (
                                        <EditIconLink  link={ dataBinder.bind("link")} />
                                    )
                                }

                            </div>
                        </div>
                </div>
            </div>);
    }
    render() {

        return (
            <div data-reference={ this.props.referenceName } className="teaserContainer abstractTeaser abstractComponent">

                <div className="m-teaser-container">
                    {
                        (!this.state.allowEdit) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onDelete={this.handleDelete }
                                    onChange={this.handleHeadlineChange}
                                    showMove={!this.state.edit}
                                    showSettings={ false }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }
                    <div className="row">


                        {
                            (this.state.edit) ? this.getContentEdit("left", this.state) : this.getContent("left", this.state)
                        }

                        {
                            (this.state.edit) ? this.getContentEdit("right", this.state) : this.getContent("right", this.state)
                        }


                    </div>

                </div>
            </div>
        )
    }
}