import React from "react";
import '../styles/makaio.css';
import {ModuleWrapper} from "../components/modules/ModuleWrapper";
import Auth from "../modules/Auth";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from "array-move";
import strapi from "../helper";

import Reference from "../components/modules/Reference/Reference";

let ContentEditTools;
let HeadlineEditor;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../components/admin/ContentEditTools').default;
    HeadlineEditor = require('../components/admin/HeadlineEditor').default;
}


const SortableItem = SortableElement(({pageContent, language, primaryLanguage, siteUrls}) => {
    let pageContentJson = (typeof pageContent.content === "string") ? JSON.parse(pageContent.content) : pageContent.content;

return (   <div><ModuleWrapper
    key={pageContent.id} id={pageContent.id}
    contentType={ pageContent.contentType }
    content={ pageContentJson }
    isSorting={true}
    language={language}
    primaryLanguage={primaryLanguage}
    siteUrls={siteUrls }
    userCreated={pageContent.userCreated }
/></div>)
});


const SortablePlaceholder = function()
{
    return null;
    return (
        <div data-drag={false}>Placeholder</div>
    )
};

//
const SortableList = SortableContainer(({items, isSorting, language, primaryLanguage, siteUrls}) => {
    let className = "";
    if(isSorting)
    {
        className = "mo-sortable-container mo-sortable-container-active"
    }

    return (
        <div className={className}>
            {

                items.map((pageContent, index) => (

                    <React.Fragment key={`frag-${index}`}>
                        <SortableItem key={`item-${index}`}
                                      language={language}
                                      primaryLanguage={primaryLanguage}
                                      siteUrls={siteUrls }
                                      index={index} pageContent={pageContent} />
                        <SortablePlaceholder />
                    </React.Fragment>

                ))
            }
        </div>
    );
});

export default class SortableContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: Auth.isAuthenticated(),
            items: props.page.pagecontents,
            page: props.page,
            sorting: false,
            isEditingIntro: false
        };

        this.onSortEnd = this.onSortEnd.bind(this);
        this.onSortStart = this.onSortStart.bind(this);
        this.onBeforeStart = this.onBeforeStart.bind(this);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleHeadlineChange = this.handleHeadlineChange.bind(this);
        this.shouldCancelSortStart = this.shouldCancelSortStart.bind(this);
        this.editedValue = null;

    }
    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.page.id !== prevState.page.id){
            return {
                items: nextProps.page.pagecontents,
                page: nextProps.page
            };
        }
        else return null;
    }


    onSortEnd = async ({oldIndex, newIndex}) =>  {

        const sortedArray = arrayMove(this.state.items, oldIndex, newIndex);

        this.setState({
            sorting: false,
            items: sortedArray,
        }, async () => {
            await strapi.request("POST", `pagecontents/move`, {
                data: sortedArray.map((item, index) => (
                    {
                        position: index,
                        id: item.id
                    }
                ))
            });
        });



    };
    onSortStart({node, index, collection, isKeySorting}, event)
    {

    }
    onBeforeStart()
    {
        this.setState({
            sorting: true
        })
    }
    shouldCancelSortStart(ev)
    {
        /*let target = ev.currentTarget;
        let isEdit = this.state.edit;
      console.log('isEdit:' + isEdit);*/
        return false;
    }
    handleCancelClick()
    {
        this.setState({
            edit: false
        });
    }
    async handleEditClick()
    {

        if(this.state.edit)
        {
            let val = this.editedValue;

            if(typeof val === 'string')
            {
                const regex = /^<[^>]*>|<[^>]*>$/gm;
                val = val.replace(regex, '');

                if(this.props.language.id === this.props.primaryLanguage.id)
                {
                    await strapi.updateEntry("pages", this.props.page.id, {
                        Intro: val
                    } );
                } else {
                    let contentUpdate = this.props.page.translations;


                    delete contentUpdate[this.props.primaryLanguage.id];
                    if(!contentUpdate[this.props.language.short])
                        contentUpdate[this.props.language.short] = {};

                    contentUpdate[this.props.language.short].Intro = val;

                    await strapi.updateEntry("pages", this.props.page.id, {
                        ContentTranslation: contentUpdate
                    } );


                }

                this.props.page.Intro = val;
                this.setState({
                    edit: false
                });

                setTimeout(async () =>  {
                    await strapi.request("GET", `pages/${this.props.page.id}/rebuild`);

                }, 100);

            } else {
                this.setState({
                    edit: false
                });
            }


        } else {
            this.setState({
                edit: true
            });
        }
    }
    handleHeadlineChange(val)
    {
        this.editedValue = val;
    }
    render() {


        return (
            <div id="content mo-content-sortable" className="container">
                <div>
                    {
                        (this.state.isEditing) ?  <ContentEditTools
                            edit={this.state.edit}

                            onCancel={ this.handleCancelClick }
                            onEditClick={this.handleEditClick }  /> : null
                    }


                                <div
                                    className="richtext abstractComponent a-copy-lead">
                                    <div
                                        className="m-rich-text"
                                        data-t-name="RichText"
                                        >


                                        {
                                            (this.state.edit) ? (
                                                <span className="a-copy-lead">

                                                    <HeadlineEditor
                                                        onChange={this.handleHeadlineChange}
                                                        headline={this.props.page.Intro}
                                                        headlineTag={ "span" } />

                                                </span>
                                            ) : (
                                                <span className="a-copy-lead" dangerouslySetInnerHTML={ {__html:  ( this.props.page.Intro || ("(No intro text)") ) } } />

                                            )
                                        }
                                    </div>
                                </div>



                    {
                        (!this.state.items) ? null
                            : (
                                <SortableList helperClass={"mo-sortable-item mo-sortable-content"}
                                              useWindowAsScrollContainer={true} isSorting={this.state.sorting}
                                              updateBeforeSortStart={ this.onBeforeStart }
                                              shouldCancelStart={this.shouldCancelSortStart}
                                              useDragHandle={true}
                                              siteUrls={this.props.siteUrls }
                                              language={this.props.language}
                                              primaryLanguage={this.props.primaryLanguage}

                                    items={this.state.items} onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}
                                />
                            )
                    }



                </div>
                <Reference language={this.props.language} />
            </div>
        )
    }
}

/*

                    <div className="anchorList abstractComponent">
                        <div className="m-anchor-list" data-t-name="AnchorList" data-t-id="14">
                            <span>
                                <a href="#flughafen">Vom Flughafen</a>
                            </span>
                            <span>
                                <a href="#hbf">Vom Hauptbahnhof</a>
                            </span>
                        </div>
                    </div>
                    <Module anchorName="flughafen">

                    </Module>
                    <Module anchorName="hbf">
                        <h2>Anreise vom Hauptbahnhof</h2>
                        <div className="m-rich-text " data-t-name="RichText" data-t-id="15">

                            <p>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                            </p>
                            <p>
                                Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>
                            <p>
                                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                            </p>
                            <p>
                                Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                            </p>
                            <p>
                                Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.
                            </p>
                        </div>

                    </Module>

                    <Teaser />

                    <Table />
                    <Downloads />
                    <Accordion />
                    <ContactTeaser />
                    <Contact />
 */