import React from "react";
import EventItems from "./EventItems";

import '../../../styles/eventsearch.css'
import '../../../styles/eventsearchslim.css'
import '../../../styles/app.css';
import {useRouteData, useSiteData} from "react-static";
import Auth from "../../../modules/Auth";
import {ModuleHead} from "../ModuleHead";
import {DataBinder} from "../../admin/DataBinder";
import strapi from "../../../helper";
import Select from 'react-select';
import moment from "moment";
import {Translator} from "../../../translator";
import EditableModule from "../Module";
const queryString = require('query-string');

let ContentEditTools;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
}


export class EventFilter extends React.Component {
    constructor(props)
    {
        super(props);

        let eventYears = [];
        let newDateObj = moment(new Date()).add(18, 'M').toDate();
        let firstYear= moment(new Date()).year();
        let lastYear = newDateObj.getFullYear();

        this.lang_key = props.lang.short;

        for(let i = firstYear; i <= lastYear; i++)
        {
            eventYears.push(i);
        }

        this.state = {
            location: 'All',
            filter: props.filter || {},
            eventYears: eventYears
        };
        this.filter = this.state.filter;
        this.industries = (!this.props.eventClusters) ? [] :  this.props.eventClusters.map((item) => {
            return {
                value: item,
                label: item
            }
        });

        if(this.filter.location)
        {
            this.state.location = this.filter.location;
        }

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.triggerFilterChanged = this.triggerFilterChanged.bind(this);
        this.handleClustersChange = this.handleClustersChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }
    triggerFilterChanged()
    {
        this.props.onFilterChanged(
            {
                location: this.filter.location,
                industries: this.filter.industries,
                year: this.filter.year
            }
        )
    }
    handleLocationChange(ev)
    {
        this.filter.location = ev.target.value;

        this.setState({
            location: ev.target.value
        });

        this.triggerFilterChanged();
    }
    handleClustersChange = selectedOption => {
        this.filter.industries = (selectedOption) ? selectedOption.map((item) => {
            return item.value
        }) : null;

        this.setState({
            industries: this.filter.industries
        });
        this.triggerFilterChanged();
    };
    getInitialValue(items, filter)
    {

        if(!filter)
            return [];
        return items.filter((item) => {
            return filter.includes(item.value);
        });
    }
    handleYearChange(ev)
    {
        const selectedYear = (isNaN( ev.target.value)) ? 0 : ev.target.value;

        this.setState({
            selectedYear: selectedYear
        });
        this.filter.year = selectedYear;
        this.triggerFilterChanged();
    }
    render() {
        const selectedYear = (this.state.selectedYear) ? parseInt( this.state.selectedYear) : 0;
        return (
            <div className="mo-event-filter">
                <div className="row">
                    <div className="col-xs-12 col-sm-10 col-md-9">
                        <Select
                            className="mo-event-filter-dropdown"
                            classNamePrefix="mo-event-filter-dropdown"
                            placeholder={ Translator.translate("All industries", this.lang_key)}
                            isMulti={true}
                            value={this.getInitialValue(this.industries, this.filter.industries)}
                            onChange={this.handleClustersChange}
                            options={ this.industries }
                        /></div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-10 col-md-9">
                    <div className="radiobuttons abstractComponent">


                        <ul className="list-inline">

                            <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="14">
                                <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0"
                                       name="Location" value="All" checked={this.state.location === 'All'} onChange={this.handleLocationChange} />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0">

                                    { Translator.translate("All events", this.lang_key)}
                                </label>
                            </li>

                            <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="15">
                                <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1"
                                       name="Location" value="Frankfurt"  checked={this.state.location === 'Frankfurt'} onChange={this.handleLocationChange} />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1">

                                    { Translator.translate("Events in Frankfurt", this.lang_key)}

                                </label>
                            </li>
                            <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="165">
                                <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2"
                                       name="Location" value="International" checked={this.state.location === 'International'} onChange={this.handleLocationChange} />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2">

                                    { Translator.translate("Events worldwide", this.lang_key)}
                                </label>
                            </li>
                        </ul>


                    </div>
                    </div>
                </div>

                <div className="row">
                    <form className="m-search-filter m-search-filter--options">
                        <div className="m-search-filter__select-option">
                            <input
                                type="radio" data-label={ Translator.translate("All years", this.lang_key) } id="optionAll"
                                name="year" value="" onChange={ this.handleYearChange } /><label
                            className={ (!selectedYear) ? "m-search-filter__item--active" : ""}
                            htmlFor="optionAll"><span>{ Translator.translate("All years", this.lang_key) }</span></label>

                            {

                                this.state.eventYears.map((year, idx) => {

                                    //"m-search-filter__item--active"
                                    // (selectedYear === year)
                                    const yearItem =  (selectedYear === year) ? (
                                        <label className="m-search-filter__item--active"
                                               htmlFor={ `option-year-${year}`}><span>{year}</span></label>
                                        ) : (
                                        <label
                                                htmlFor={ `option-year-${year}`}><a>{year}</a></label>
                                        )
                                    ;

                                    return (
                                        <React.Fragment key={idx}>
                                            <input
                                                type="radio"
                                                id={ `option-year-${year}`} name="year"

                                                value={year} onChange={ this.handleYearChange } />{yearItem}
                                        </React.Fragment>
                                    )
                                })
                            }

                        </div>
                    </form>
                </div>

            </div>
        );
    }
}
export class EventModule extends EditableModule {
    constructor(props) {
        super(props);

        this.lang_key = props.language.short;

        this.state = {
            content: props.content
        };

        this.eventClusters = props.eventClusters;
        this.eventClusters.sort();
        this.state.searchQuery = "";
        this.state.searchQueryTemp = "";
        this.state.events = props.events;



        this.state.filter = {
            industries: props.content.industries
        };

        if(typeof document !== 'undefined')
        {
            let windowLoc = window.location;
            if(windowLoc && windowLoc.search)
            {
                let qs = queryString.parse(windowLoc.search);
                if(qs.filter_industries)
                {
                    this.state.filter.industries = qs.filter_industries.split(',').map((item) => (
                        item.replace(/;/g, ',')
                            .replace(/%26/g, '&')
                    ));

                }

                if(qs.filter_location)
                {
                    this.state.filter.location = qs.filter_location;

                }

                if(qs.filter_industries || qs.filter_location)
                {
                    // remove default events because of querystring
                    this.state.events = [];
                }
            }
        }

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleFilterChanged = this.handleFilterChanged.bind(this);


    }
    handleFilterChanged(filter){


        this.setState({
            filter:
                {
                    industries: filter.industries,
                    location: filter.location,
                    year: filter.year
                }
        });

    }
    handleCancelClick()
    {
        this.setState({
            edit: false
        });
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {

            const changes = this.state.dataBinder.getChanges();

            if(changes)
            {
                let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = changes;

                } else {
                    contentUpdate = {

                        content: changes

                    };

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(changes);
            } else {
                this.cancelEditState();
            }

        } else {
            this.enterEditState();
        }
    }
    handleSearchClick()
    {

        this.setState(
            {
                searchQuery: this.state.searchQueryTemp
            }
        );
    }
    handleSearchChange(src)
    {

        this.setState(
            {
                searchQueryTemp: src.target.value
            }
        )
    }
    handleSearchKeyDown(e)
    {
        if(e.keyCode === 13)
        {
            this.handleSearchClick();
        }
    }
    render() {

        return (



                <div className={(this.state.content.isTeaser) ? "eventSearchSlimPrefiltered eventSearchSlim abstractComponent" : "eventSearch abstractComponent"}>

                    {
                        (!Auth.isAuthenticated()) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onChange={this.handleHeadlineChange}
                                    onDelete={this.handleDelete }
                                    showMove={false}
                                    showSettings={ true }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }

                    <ModuleHead
                        edit={ this.state.edit }
                        headline={this.state.content.headline}
                        binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                         />

                    <div className="">
                        <div id="mf-ev-root">
                            <div data-reactroot="" className="ex-application">
                                <div>
                                    <div>
                                        <div className={(this.state.content.isTeaser) ? "o-search-results-container o-search-results-container--eventsearchslim ev-event-search-slim" : "ex-event-search"} >
                                            {
                                                (this.state.content.isTeaser) ? null : (
                                                    <div className="ex-search-form">
                                                        <div className="row">
                                                            <div className="col-xs-12 col-sm-10 col-md-9">
                                                                <div autoComplete="off"
                                                                     className="m-form a-form-input a-form-input--searchfield">
                                                                    <div className="ex-input-autosuggest"><input
                                                                        type="text"
                                                                        className="ex-search-form__searchbar form-control"
                                                                        name="q" onChange={ this.handleSearchChange }
                                                                        placeholder={ Translator.translate("Enter an event name.", this.lang_key)}
                                                                        value={ this.state.searchQueryTemp } onKeyDown={ this.handleSearchKeyDown } /></div>
                                                                    <div
                                                                        className="ex-search-form__submit btn btn-primary btn-icon-single btn-icon-single-xxs"
                                                                        type="submit"  onClick={ this.handleSearchClick }><span
                                                                        className="icon icon-magnifier"></span><span
                                                                        className="btn-label btn-label-hidden-xxs">{ Translator.translate("Search", this.lang_key) }</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                            {
                                                                (this.state.content.isTeaser) ? null : (
                                                                    <EventFilter lang={ this.props.language } filter={this.state.filter} onFilterChanged={this.handleFilterChanged } eventClusters={this.eventClusters} />
                                                                )
                                                            }

                                                    </div>
                                                )
                                            }

                                            <h2 style={{display:'none'}} className="ex-search-headline"><span
                                                className="ex-search-headline__text">Ihre Suche ergab 220 Treffer.</span>
                                            </h2>
                                            <div>

                                                <div className={ (!this.state.content.isTeaser) ? "ex-event-search__container h-background h-background--fullwidth ex-event-search__container_narrow" :  "o-search-results-container__results h-background h-background--fullwidth"}>
                                                    <EventItems

                                                        langKey={this.lang_key}
                                                        isTeaser={this.state.content.isTeaser}
                                                        filter={this.state.filter}
                                                        events={ this.state.events }
                                                        searchQuery={ this.state.searchQuery }
                                                    />
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

        );
    }
}

export default function Events (props)
{
    const {  eventData,eventClusters, language  } =  useRouteData();
    const { specialPages, primaryLanguage } = useSiteData();
    let events = [];

    if(props.id && eventData && eventData[props.id])
    {
        events = eventData[props.id];
    }

    return <EventModule specialPages={specialPages} primaryLanguage={primaryLanguage} language={language} eventClusters={eventClusters} events={ events } { ... props }  />;
}
