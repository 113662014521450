import ReCAPTCHA from "react-google-recaptcha";

import React from "react";
import {Translator} from "../../../translator";
import '../../../styles/app.css';
import {DataBinder} from "../../admin/DataBinder";
import {RadioButtons, TextAreaInput, TextInput} from "../../ui/FormItems";
const axios = require('axios');


function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            response : null,
            payload : {},
            contactdata: {
                salutation: '',
                company: ''
            },
            shouldCheck: false,
            sent: false


        };
        this.lang_key = props.language.short;

        this.dataBinder = new DataBinder(this.state.contactdata);


        let translation_firstname =  Translator.translate("firstname", this.lang_key);
        let translation_lastname = Translator.translate("lastname", this.lang_key);
        let translation_company =  Translator.translate("company", this.lang_key);
        let translation_mail =  Translator.translate("mail", this.lang_key);
        let translation_message = Translator.translate("message", this.lang_key);


        let translation_m = Translator.translate("male", this.lang_key);
        let translation_w = Translator.translate("female", this.lang_key);

        let show_gender = true;
        if(!translation_m || translation_m.length === 0 || translation_m === "n/a")
        {
            show_gender = false;
        }

        this.formItems = (!show_gender) ? [] : [
            {
                required: true,
                key: 'salutation',
                itemType: 'radio',
                values: [{
                    title: translation_w,
                    value: 'female'
                }, {
                    title: translation_m,
                    value: 'male'
                }],
                value: this.dataBinder.bind('salutation')
            }
        ];

        this.formItems.push(
            {
                required: true,
                key: 'firstname',
                title: translation_firstname,
                itemType: 'text',
                value: this.dataBinder.bind('firstname')
            }
        );

        this.formItems.push(
            {
                required: true,
                key: 'lastname',
                title: translation_lastname,
                itemType: 'text',
                value: this.dataBinder.bind('lastname')
            }
        );

        this.formItems.push(
            {
                required: true,
                key: 'mail',
                title: translation_mail,
                itemType: 'text',
                value: this.dataBinder.bind('mail')
            }
        );

        this.formItems.push(
            {
                required: true,
                key: 'company',
                title: translation_company,
                itemType: 'text',
                value: this.dataBinder.bind('company')
            }
        );

        this.formItems.push(
            {
                required: true,
                key: 'company',
                title: translation_message,
                itemType: 'textarea',
                value: this.dataBinder.bind('message')
            }
        );


        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSalutationChange = this.handleSalutationChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }
    onCaptchaChange(value) {
        console.log("Captcha value:", value);
        this.setState({
            response: value,
            payload: this.state.contactdata
        });
    }
    handleSalutationChange(ev)
    {
        if(ev.target.checked) {
            const val = ev.target.value;
            let contactdata = this.state.contactdata;
            contactdata.salutation = val;
            this.setState({
                contactdata: contactdata,
                shouldCheck: false
            });
        }
    }
    handleCompanyChange(ev)
    {
        const val = ev.target.value;
        let contactdata = this.state.contactdata;
        contactdata.company = val;
        this.setState({
            contactdata: contactdata
        });
    }
    validateFields()
    {
        let isInvalid = false;
        for(let item of this.formItems)
        {
            isInvalid =(!item.value.getValue() || item.value.getValue().length === 0);

            if(!isInvalid && item.key === 'mail' && !validateEmail(item.value.getValue()))
            {
                isInvalid = true;
            }

            if(isInvalid)
                break;
        }
        return !isInvalid;
    }
    handleSubmit()
    {
        this.setState({
            shouldCheck: true
        });

        const validSuccess = this.validateFields();

        if(validSuccess)
        {
            axios.post(`${process.env.STRAPI_PUBLIC_HOST}/sendMail`, {
                response: this.state.response,
                payload: this.dataBinder.changeSet
            })
                .then((res) => {
                    if(res.status === 200)
                    {
                        this.setState({sent: true});
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }
    render() {
        let translation_submit =  Translator.translate("Submit", this.lang_key);
        let translation_error = Translator.translate("Please fill out this field.", this.lang_key);

        if(this.state.sent)
        {
            let translation_contactthank = Translator.translate("contactthank", this.lang_key);
            return (
                <div className="m-form-captcha abstractComponent">
                    { translation_contactthank }
                </div>
            )
        }


        return (
            <div>
                {
                    this.formItems.map((item, index) => {
                        switch (item.itemType) {
                            case 'radio':
                                return <RadioButtons key={index} item={item} shouldCheck={this.state.shouldCheck} errorMessage={translation_error} />;
                            case 'text':
                                return <TextInput key={index} item={item} shouldCheck={this.state.shouldCheck} errorMessage={translation_error} />;
                            case 'textarea':
                                return <TextAreaInput key={index} item={item} shouldCheck={this.state.shouldCheck} errorMessage={translation_error} />

                        }
                        return null;
                    })
                }

                <div className="m-form-captcha abstractComponent">
                    <ReCAPTCHA key="recaptcha" sitekey="6Le4uq0UAAAAAANOqmLY8dylFNgg_xs-0NyBs-Ss" onChange={this.onCaptchaChange} />
                    {
                        (this.state.response || this.state.shouldCheck === false) ? null :  <div className="a-form-input--error">{ translation_error }</div>
                    }
                </div>
                {
                        (
                            <span onClick={ this.handleSubmit } className="btn btn-default">{ translation_submit }</span>
                        )
                }
            </div>
        )
        //}
    }
}

//  a-form-textarea--invalid