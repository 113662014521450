import React from "react";
import ReactTooltip from 'react-tooltip';
import LinkEditor from "./LinkEditor";
import Modal from 'react-modal';
const customStyles = {
    overlay: {
        zIndex: 99999
    },
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '50%'
    }
};
export default class EditIconLink extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            link: props.link
        };
        this.handlePopup = this.handlePopup.bind(this);
    }
    handlePopup(ev)
    {
        window.setTimeout(() => {
            let popups = document.getElementsByClassName('popup-overlay');
            for(let popup of popups)
            {
                popup.parentNode.removeChild(popup);
                document.getElementById('root').appendChild(popup);
            }
        }, 10);
    }
    handlePopupClose(ev)
    {
     debugger;
    }

    render()
    {
        const self = this;
        return (
            <React.Fragment>
                <Modal
                    style={customStyles}
                    isOpen={self.state.modalIsOpen}
                    contentLabel="Example Modal"
                    shouldCloseOnOverlayClick={ false }
                    onRequestClose={() => self.setState({ modalIsOpen: false })}
                >
                    <LinkEditor close={() => self.setState({ modalIsOpen: false })} link={this.state.link} />
                </Modal>
                <div
                    className="mo-edit-icon mo-edit-icon-link"
                    data-tip="Modify link of this button"
                    onClick={() => { self.setState({modalIsOpen:true})}}
                >.</div>
            </React.Fragment>



        );
    }
}