import React from "react";
import {RichText} from "./RichText";
import {Teaser} from "./Teaser/Teaser";
import {Table} from "./Table/Table";
import {Downloads} from "./Downloads/Downloads";
import Accordion from "./Accordion/Accordion";
import ContactTeaser from "./ContactTeaser/ContactTeaser";
import Contact from "./Contact/Contact";
import {TextImage} from "./TextImage";
import Events from "./Events/Events";
import {TeaserTwoCol} from "./TeaserTwoCol/TeaserTwoCol";
import Newsletter from "./Newsletter/Newsletter";
import {EventTeaser} from "./EventTeaser/EventTeaser";

export class ModuleWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        switch(this.props.contentType)
        {
            case "Accordion":
                return <Accordion {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "Contact":
                return <Contact {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "ContactTeaser":
                return <ContactTeaser {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "Downloads":
                return <Downloads {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "EventTeaser":
                return <EventTeaser {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "Events":
                if(this.props.content.isTeaser)
                {
                    return <EventTeaser {... this.props } lang={ this.props.language } id={ this.props.id } />;
                }
                return <Events {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "Newsletter":
                return <Newsletter {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "RichText":
                return <RichText{... this.props } lang={ this.props.language } id={ this.props.id }  />;
            case "Table":
                return <Table {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "Teaser":
                return <Teaser {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "TeaserTwoCol":
                return <TeaserTwoCol {... this.props } lang={ this.props.language } id={ this.props.id } />;
            case "TextImage":
                return <TextImage {... this.props } lang={ this.props.language } id={ this.props.id } />;
            default:
                return null;
        }

    }
}