import ContentHeader from "./ContentHeader";
import PageHeader from "./PageHeader";
import Content from "../../containers/Content";
import React from 'react'
import Auth from "../../modules/Auth";
import Navigation from "./Navigation";
import StageSmall from "./StageSmall";
import '../../styles/app.css'
import ToTop from "./ToTop";
import {useRouteData, useSiteData} from "react-static";
import {Translator} from "../../translator";

import PageSettings from '../admin/PageSettings';
import CookieBanner from "./CookieBanner";
import cookie from 'react-cookies';
import {cookiesAllowed} from "../../helper";
import {useLocation} from "react-router-dom";

export  class PageContainerWithTranslation extends React.Component {
    constructor(props)
    {
        super(props);

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.handleShowSettings = this.handleShowSettings.bind(this);
        this.handleSettingsCancel = this.handleSettingsCancel.bind(this);
        this.handleSettingsSave = this.handleSettingsSave.bind(this);

        this.handleCookieBannerClose = this.handleCookieBannerClose.bind(this);
        this.initTracking = this.initTracking.bind(this);

        let showCookieBanner = true;
        let storedCookie = cookie.load('cookies-accepted');

        if(storedCookie)
        {
            showCookieBanner = false;
        }

        this.state = {
            navOpen: false,
            navWidth: 0,
            headerHidden: false,
            headerDocked: true,
            hideToTop: true,
            showSettings: false,
            dataBinder: {},
            showCookieBanner: showCookieBanner,
            page: props.page
        };

        this.scrollStart = 0;
        this.lastY = 0;


    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        if(error.message.indexOf('could not find any data for this route') > -1)
        {
            alert('It seems that you changed the title of the current page and the link changed. You will be redirected to the start page now.')
            window.location.href = '/';
        }
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        debugger;
    }
    initTracking(tracking)
    {

        const trackingElement = document.getElementById('tracking');
        if(trackingElement)
        {
            const self = this;
            trackingElement.onload = function() {
                const loc = window.location;

                if(tracking["google"])
                {
                    window.ga('create', tracking["google"].id, 'auto');
                }

                trackPageView(loc, self.state.page);
            };
            if(tracking["hubspot"])
            {
                trackingElement.src = `https://js.hs-scripts.com/${tracking["hubspot"].id}.js`
            } else if(tracking["google"])
            {
                trackingElement.src = `https://www.google-analytics.com/analytics.js`;

            } else if(tracking["albacross"])
            {
                trackingElement.src = `https://serve.albacross.com/track.js`;
                window._nQc=tracking["albacross"].id;
            }
        }

    }
    componentDidMount() {
        const languages = this.props.languages;
        const primaryLanguage = this.props.primaryLanguage;

        if(languages.length > 1 && typeof window !== 'undefined')
        {
            let storedLang = cookie.load('sp-lang');

            if(!storedLang)
            {
                const userLang = navigator.language || navigator.userLanguage;

                if(window.location.pathname === "/" && userLang !== null && userLang !== primaryLanguage.short)
                {
                    let targetLang = languages.find((item) => item.short === userLang);
                    if(targetLang)
                    {
                        cookie.save('sp-lang', targetLang.short);


                        window.location.href = window.location.href + `${targetLang.short}/`;
                    }
                }
            }
            console.log('lang:' + storedLang);
        }

        if (!window.polyfilled && typeof document !== 'undefined') {

            if(this.props.tracking["google"])
            {
                window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
            }

            if(cookiesAllowed())
                this.initTracking(this.props.tracking);

            window.polyfilled = true;
        } else {
        }

        if(window)
        {
            window.addEventListener("scroll", this.handleScroll);
            this.header = document.getElementsByTagName('header')[0];
        }

    }
    componentDidUpdate(prevProps) {

        if (this.state.page.id !== prevProps.page.id) {
            if(this.state.navWidth > 0)
                this.handleMenuClose();

            //if(prevProps.page.PageType !== 'Eventpage' && this.state.page.PageType !== 'Events')
            //{
                window.scrollTo(0,0);
            //}
        }
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {

        if(prevState.page.id !== nextProps.page.id){
            return {
                showSettings: false,
                page: nextProps.page
            };
        } else {
            return null;
        }

    }
    handleShowSettings()
    {
        this.setState({
            showSettings: true
        })
    }
    handleSettingsCancel()
    {
        this.setState({
            showSettings: false
        })
    }
    handleSettingsSave()
    {
        let dataBinder = this.state.dataBinder;
        if(dataBinder && dataBinder.save)
        {
            dataBinder.save().then((res) => {
                this.setState({
                    showSettings: false
                })
            });
        }

    }
    handleScroll(e)
    {
        let scrollY = e.currentTarget.scrollY;
        let headerY = this.header.clientHeight;

        if(scrollY === 0)
        {
            this.setState(
                {
                    headerHidden: false,
                    headerDocked: true,
                    hideToTop: true
                }
            );

            this.scrollStart = 0;
            this.lastY = 0;
            return;
        }
        if(this.scrollStart > 0)
        {
            if(this.lastY > 0 && scrollY > this.lastY)
            {
                this.scrollStart = 0;
                this.lastY = 0;

                this.setState({
                    headerHidden: true,
                    hideToTop: true
                }
                );

            } else {
                this.lastY = scrollY;


                if(this.scrollStart - scrollY > 30)
                {
                    this.setState(
                        {
                            headerHidden: false,
                            headerDocked: false,
                            hideToTop: false
                        }
                    )
                }
            }
        } else {
            this.scrollStart = scrollY;
            if(!this.state.headerHidden && scrollY > headerY)
            {
                this.setState({
                    headerHidden: true}
                );
            }
        }
    }
    updateDimensions()
    {
        let nav = document.getElementById('navigation').childNodes[0];

        this.setState( {
            navWidth: nav.clientWidth
        });
    }
    handleMenuClose()
    {
        window.removeEventListener("resize", this.updateDimensions);
        this.setState( {
            navOpen: false,
            navWidth: 0
        });
    }
    handleMenuClick()
    {
        let nav = document.getElementById('navigation').childNodes[0];

        this.setState( {
            navOpen: true,
            navWidth: nav.clientWidth
        });

        window.addEventListener("resize", this.updateDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    /*
    handleHeaderChanged()
    {
        this.props.page.Header = {
            hash: "c932f86215274c78b2767a06596fa875",
            ext: ".jpg"
        };
        this.forceUpdate();
    }
     */
    handleCookieBannerClose()
    {
        let expiration = new Date();
        expiration.setDate(expiration.getDate()+365);
        cookie.save('cookies-accepted', 'ok', {
            expires: expiration
        });
        this.setState({
            showCookieBanner: false,
            allowTracking: true
        }, () => {
            window.location.reload();
        });

        this.initTracking(this.props.tracking);

    }
    render() {

        let stage =  <div><StageSmall page={this.state.page} /></div>;

        if((!this.state.page.Header || !this.state.page.Header.hash) &&
            !(
                this.state.page.event && this.state.page.event.headerImage
            ))
        {
            if(!Auth.isAuthenticated())
                stage = null;
        }

        return (
            <React.Fragment>
                <Navigation onMenuClose={ this.handleMenuClose } isOpen={ this.state.navOpen } baseUrl={ this.props.baseUrl } />
                <main style={{left: this.state.navWidth+'px'}}>
                    <ToTop hidden={ this.state.hideToTop } />
                    <ContentHeader
                        lang={this.props.language}
                        isHidden={ this.state.headerHidden }
                        isDocked={ this.state.headerDocked }
                        onMenuClick={ this.handleMenuClick } />
                    <div className="o-header__color-bar" />

                    { stage }

                    <PageHeader
                        page={this.state.page}
                        language={this.props.language}
                        primaryLanguage={this.props.primaryLanguage}
                        inSettings={this.state.showSettings}
                        onShowSettings={ this.handleShowSettings }
                        onSettingsCancel={ this.handleSettingsCancel }
                        onSettingsSave={this.handleSettingsSave }
                        />

                    {
                        (!this.state.showSettings) ? this.props.children : <PageSettings onHeaderChanged={ this.handleHeaderChanged } dataBinder={this.state.dataBinder} />
                    }

                </main>
                <CookieBanner show={ this.state.showCookieBanner } onClose={ this.handleCookieBannerClose } />


            </React.Fragment>)
    }
}
/*
                {
                    (!this.state.showCookieBanner && this.props.tracking["block"]) ? <script  dangerouslySetInnerHTML={{ __html: this.props.tracking["block"]}}>
                    </script> : null
                }
*/
function trackPageView(loc, page)
{
    let trackingElement = document.getElementById('tracking');
    if(trackingElement)
    {
        if(trackingElement.src.indexOf('hs-scripts.com') > -1)
        {
            const _hsq = window._hsq = window._hsq || [];

            _hsq.push(['setPath', loc.pathname]);
            _hsq.push(['trackPageView']);
        }
        if(window.ga)
        {
            window.ga('set', {
                page: loc.pathname,
                title: page.Navigationtitle
            });

            window.ga('send', 'pageview');
        }
        /*
        if(window.gtag)
        {
            setTimeout(() => {
                window.gtag('config', 'UA-153084743-1', {'page_path': loc.pathname, 'page_title': page.Navigationtitle});
            }, 100);
        }*/
    }
}

function usePageViews(page) {
    let location = useLocation();


    React.useEffect(() => {
        const loc = location;

        trackPageView(loc, page);
    }, [location]);
}

export default function PageContainer(props) {
    const { translations, tracking, languages, primaryLanguage } =  useSiteData();
    usePageViews(props.page, tracking);




    return <PageContainerWithTranslation tracking={tracking} languages={ languages} primaryLanguage={primaryLanguage} translations={translations} {...props } />;
}