import React from "react";
import EditableModule, {Module} from "../Module";
import {ModuleHead} from "../ModuleHead";
import strapi, {mf_ImageSizes, ResponsiveImage} from "../../../helper";
import Auth from "../../../modules/Auth";
import {DataBinder} from "../../admin/DataBinder";
import {replaceLinks} from "../../../containers/Page";

let ContentEditTools;
let RichTextEditor;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
    RichTextEditor = require('../../admin/RichTextEditor').default;
    useDropzone = require('react-dropzone').useDropzone;
}


export class RichText extends EditableModule {
    constructor(props) {
        super(props);

        if (props.anchorName) {

        }

        this.state = {
            isToggleOn: true,
            content: props.content,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            dataBinder: new DataBinder(props.content)
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        // This binding is necessary to make `this` work in the callback

    }
    handleRemoveImage()
    {
        this.state.dataBinder.bind('image').updateValue(null);
        this.forceUpdate();
    }
    static createForReference(refName)
    {
        return <RichText blockEdit={true} referenceName={refName} content={
            {
                headline: 'RichText',
                text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. \n' +
                '</p><p>' +
                'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>'}} siteUrls={[]} />;
    }

    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {

        if(this.state.edit)
        {
            const changes = this.state.dataBinder.getChanges();

            if(changes)
            {

                let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = changes;

                } else {
                    contentUpdate = {

                        content: changes

                    };

                }

                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(changes);
            } else {
                this.cancelEditState();
            }

        } else {
            this.enterEditState();
        }
    }
    async handleTextChange(val)
    {
        this.editedValue = val;
    }
    render() {

        let className = 'richtext abstractComponent';


        const rawContent = this.state.content.text || "";

        let textContent = replaceLinks(rawContent, this.props.siteUrls);

        let editor;
        let contentContainer = (<div className="m-rich-text " data-t-name="RichText" data-t-id="15"  dangerouslySetInnerHTML={ {__html: textContent } }  />);
        if(this.state.edit)
        {

            editor = (<RichTextEditor binding={ this.state.dataBinder.bind("text")} />);


            contentContainer = (<div className="m-rich-text " data-t-name="RichText">
                {editor}

            </div>)
        }

        let content = (<React.Fragment>

            <div data-reference={ this.props.referenceName }  className={ className } >
                {
                    (!this.state.allowEdit) ?  null :
                    (
                        <ContentEditTools
                            edit={this.state.edit}
                            onCancel={ this.handleCancelClick }
                            onEditClick={this.handleEditClick }
                            onDelete={this.handleDelete }
                            onChange={this.handleHeadlineChange}
                            showMove={!this.state.edit}
                            showSettings={ false }
                            showDelete={this.props.userCreated}
                        />
                    )

                }

                <ModuleHead
                    edit={ this.state.edit }
                    binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                    headline={ this.state.content.headline } />
                { contentContainer }
            </div>

        </React.Fragment>);


        return content;
    }
}