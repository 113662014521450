import React from "react";
import strapi from "../../helper";
import {DataBinder} from "./DataBinder";

class TranslationInput extends React.Component {
    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(ev)
    {
        let val = ev.target.value;
        this.props.databinder.updateValue(val);
        this.forceUpdate();
        this.props.onChange();
    }
    render() {

        return (<React.Fragment>
            <input className="js-form-input__clear-text " type="text"
                   value={this.props.databinder.getValue()}
                   name={this.props.Comment} onChange={this.handleChange}
                   required="" placeholder={this.props.Comment}  maxLength="254" />
            <label
                >{ this.props.Comment }</label>
        </React.Fragment>)
    }
}
export default class TextBlocks extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            translation_ref: [],
            translations: {},
            databinder: null,
            lang_key: props.lang.short
        };


        this.renderTextBlocks = this.renderTextBlocks.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    async componentDidMount() {
        const translation_ref = await strapi.request('GET', 'https://data.salespartner.network/uploads/formfields.json');

        const sitesettings = await strapi.getEntries("sitesettings", { key: 'translations'});
        let translations = {};

        if(sitesettings.length > 0)
            translations = sitesettings[0].data;

        let databinder = new DataBinder(translations);
        databinder.id = sitesettings[0].id;
        this.setState({
            translations: translations,
            translation_ref: translation_ref,
            databinder: databinder
        });
    }
    renderTextBlocks(langKey, onChange)
    {
        let elements = [];
        let translations = (this.state.databinder && this.state.databinder[langKey]) ? this.state.databinder[langKey]  : [];

        let idx = 0;
        for(let translationRef of this.state.translation_ref)
        {
            elements.push(
                <div className="a-form-input a-form-input--text" data-t-name="FormInput"
                     data-t-decorator="TextField" key={idx}>
                    <TranslationInput Comment={translationRef.Comment}
                                      onChange={onChange}
                                      databinder={this.state.databinder.bind(`${langKey}.${translationRef.Key}`)} />
                </div>);
            idx++;
        }

        return (<React.Fragment>
            {elements}

        </React.Fragment>)
    }
    handleChange()
    {
        this.props.onChange(this.state.databinder);
    }
    render() {
        return (
            <div>
                <div className="textfield abstractComponent">
                    {
                        this.renderTextBlocks(this.state.lang_key, this.handleChange)
                    }
                </div>

            </div>
        );
    }
}