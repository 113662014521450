import React from "react";

export default class LinkEditor extends React.Component {
    constructor(props)
    {
        super(props);
        this.state ={
            link: props.link.getValue()
        };
        this.handleLinkChanged = this.handleLinkChanged.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);

        this.closePopup = props.close;

    }
    handleLinkChanged(ev)
    {


        this.setState({
            link: ev.target.value
        });
    }
    handleSaveClick()
    {
        this.props.link.updateValue(this.state.link);
        this.closePopup();
    }
    handleCancelClick()
    {
        this.closePopup();
    }
    render()
    {

        return (
            <div className="mo-link-editor">


                <div className="textfield abstractComponent">


                    <div className="a-form-input a-form-input--text" data-t-name="FormInput"
                         data-t-decorator="TextField" data-t-id="16">
                        <input className="js-form-input__clear-text " type="text" onChange={ this.handleLinkChanged} value={ this.state.link }
                               required=""  maxLength="254" />
                        <label>Link </label>

                    </div>
                </div>
                <div className="mo-edit-icon-container">
                    <div className="mo-edit-icon-container-items">
                        <div className="mo-edit-icon" onClick={ this.handleSaveClick }>
                            n
                        </div>
                        <div className="mo-edit-icon mo-edit-icon-cancel" onClick={ this.handleCancelClick }>
                            d
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}