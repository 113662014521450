import React, {useCallback} from 'react';
import {useRouteData, useSiteData} from "react-static";
import {DataBinder} from "./DataBinder";
import strapi, {slugify} from "../../helper";
import { strapiShared } from "../../helper";

import Modal from "react-modal";


const customStyles = {
    overlay: {
        zIndex: 99999
    },
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '50%',
        maxHeight: '90%',
    }
};

class Textfield extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            value: props.binding.getValue()
        };
        this.handleValueChange = this.handleValueChange.bind(this);
    }
    handleValueChange(ev)
    {
        const val = ev.target.value;

        if(this.props.binding && this.props.binding.dataBinder)
        {
            this.props.binding.updateValue(val);
            this.setState({
                value: this.props.binding.getValue()
            })
        }

        if(this.props.onChange)
        {
            this.props.onChange(val);
        }
    }
    render() {

        return ( <div className="a-form-input a-form-input--text" data-t-name="FormInput"
                      data-t-decorator="TextField" data-t-id="16">
            <input className="js-form-input__clear-text " type="text"
                   id="18fa545ece28232fefd052bbd39c8298d3f9e787cf0ab897aa026b191b740fb4" name="Vorname"
                   required="" placeholder="Vorname" onChange={ this.handleValueChange } value={this.state.value} maxLength="254" />
            <label>{ this.props.title }</label>

        </div>);
    }
}




class StagePreview extends React.Component {
    constructor(props)
    {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);

        this.previewContainer = React.createRef();

        const stage = window.stageImage;


        const stageImage = (stage.current) ? stage.current : null;

        this.state = {
            stageWidth: (stageImage) ? stageImage.width/2 : 0,
            stageHeight: (stageImage) ? stageImage.height/2 : 0,
            imageList: []
        };
    }
    handleResize()
    {
        const stage = window.stageImage;

        if(!stage)
            return;
        const stageImage = (stage.current) ? stage.current : null;
        const previewContainer = this.previewContainer;
        const ratio = previewContainer.current.clientWidth / (stageImage.width-10);
        this.setState({
            stageWidth: (stageImage) ? (stageImage.width*ratio) : 0,
            stageHeight: (stageImage) ? (stageImage.height*ratio) : 0,
        });
    }
    async loadImages()
    {


        const resp = await   strapiShared.get(`${process.env.STRAPI_HOST_COMMON}/headers`, this.state)
        let parsedImages = [];

        if(resp.data && resp.data.length > 0)
        {
            const images = resp.data;
            for(let imageData of images)
            {
                const image = imageData.image;
                const img = {
                    url: `${process.env.STRAPI_HOST_COMMON}/uploads/${image.hash}${image.ext}`,
                    hash: image.hash,
                    ext: image.ext
                };
                parsedImages.push(img);
            }

            this.setState({
                imageList: parsedImages
            });
        }

    }
    async componentDidMount() {
        this.handleResize();
        await this.loadImages();
        window.addEventListener("resize", this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleImageClick(img)
    {
        if(this.props.onImageSelect)
        {
            this.props.onImageSelect(img);
        }

    }
    render() {

        let items = [];
        for(let image of this.state.imageList)
        {
            items.push(
                <div>
                    <img
                    className="img-responsive"
                    style={{height: this.state.stageHeight + 'px', maxHeight: this.state.stageHeight + 'px', maxWidth: this.state.stageWidth + 'px', width: this.state.stageWidth + 'px' }}
                    width={this.state.stageWidth}
                    height={this.state.stageHeight}
                    ref={this.stagePreview}
                    src={ image.url }
                    data-hash={ image.hash }
                    onClick={ () => this.handleImageClick(image) }
                    />

                </div>);

        }

        return (
            <div className="m-stage-item mo-stage-preview">
                <div className="mo-edit-icon mo-edit-icon-cancel" onClick={ this.props.close }>d</div>
                <h2>Please select an image</h2>
                <div className="mo-stage-preview-inner-container" ref={this.previewContainer}>
                    { items }

                </div>
            </div>
        );
    }
}

class PagePropEditor extends React.Component {
    constructor(props)
    {
        super(props);
        this.dataBinder = props.dataBinder;
    }

    render() {
        if(!this.props.visible)
            return null;
        return (
            <React.Fragment>
                <h2>{ this.props.title }</h2>
                {
                    Object.getOwnPropertyNames(this.props.languageMap).map((item) => {
                    let lang = this.props.languageMap[item];

                    return (
                    <Textfield
                        key={lang.short}
                    title={lang.long}
                    onChange={ (e) => { this.props.onChange(this.props.pageProp, e,lang) } }
                    binding={ this.dataBinder[lang.short.toString()].bind(this.props.pageProp)} />
                    )
                })
                }
            </React.Fragment>

        );
    }

}
export class PageSettings extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            NavigationTitles: Object.getOwnPropertyNames(this.props.languageMap).map((lang) => {
                let langContainer = {};
                langContainer[lang] = "test";
                return langContainer;
                })
            };

        this.dataBinder = props.dataBinder;//{};
        for(let lang of props.languages) {
            let content = null;

            /*if (lang.short === props.primaryLanguage.short)
            {
                content = props.page;
            } else */
            if(this.props.page.translations[lang.short])
            {
                let langContent = this.props.page.translations[lang.short];

                if(langContent )
                    content = langContent;

                if(this.props.primaryLanguage.short === lang.short)
                {
                    content.HeaderImage = this.props.page.HeaderImage || this.props.page.Header;
                }

            }
            this.dataBinder[lang.short] = new DataBinder( content);
        }
        this.dataBinder.save = this.handleDataBinderSave.bind(this);
        this.handleHeaderPopup = this.handleHeaderPopup.bind(this);
        this.handlePagePropChange = this.handlePagePropChange.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);

        this.stagePreview = React.createRef();
        //this.handleDataBinderSave = this.handleDataBinderSave.bind(this);

    }
    async handleDataBinderSave()
    {

        let contentUpdate = { };
        let translationLangs = [];

        let changeSet = this.dataBinder[this.props.primaryLanguage.short].getChanges();
       /* Pagetitle won't be changed here */
        /*
        if(changeSet && changeSet.Pagetitle)
        {
            changeSet.Slug = slugify(this.dataBinder[this.props.primaryLanguage.short].Pagetitle || changeSet.Pagetitle);
            debugger;
        }*/
        let translationChanges = {};
        let translationChanged = false;

        for(let lang of Object.getOwnPropertyNames(this.dataBinder)) {
            if(lang !== this.props.primaryLanguage.short.toString())
            {
                translationLangs.push(lang);
                if (lang !== "save" && this.dataBinder[lang].getChanges) {
                    translationChanges[lang] = this.dataBinder[lang].getChanges() ;
                    if(translationChanges[lang])
                    {
                        translationChanged = true;
                    }
                }
            }
        }

        if(!changeSet && !translationChanged)
        {
            /* no change */
            return true;
        }

        if(!changeSet && translationChanged)
        {
            /* primary lang didn't change, so create empty container to assign translations */
            changeSet = {};
        }

        if(translationChanged)
        {
            changeSet.ContentTranslation = {};
            for(let lang of translationLangs)
            {
                changeSet.ContentTranslation[lang] = this.dataBinder[lang].content;
                if(translationChanges[lang])
                {
                    Object.assign(changeSet.ContentTranslation[lang], translationChanges[lang]);
                }

            }
        }


        await strapi.updateEntry("pages", this.props.page.id, changeSet );
        setTimeout(async () =>  {
            await strapi.request("GET", `pages/${this.props.page.id}/rebuild`);

        }, 100);



        return true;
    }
    handlePagePropChange(prop, val, lang)
    {
        if(this.dataBinder)
        {
            const dataBinder = this.dataBinder[lang.short];
            dataBinder.updateProperty(prop, val);

            if(prop === "Pagetitle" && val && val.length > 2)
            {
                dataBinder.updateProperty("Slug", slugify(val));
                dataBinder.updateProperty("Navigationtitle", val );
            }
        }

        //const val = ev.target.value;

    }
    handleHeaderPopup(ev1,ev2)
    {
        window.setTimeout(() => {
            let popups = document.getElementsByClassName('popup-overlay');
            for(let popup of popups)
            {
                popup.parentNode.removeChild(popup);
                document.getElementById('root').appendChild(popup);
            }
        }, 10);
    }
    shouldShowStageSelector()
    {

        return (this.props.page.PageType !== "Home" && this.props.page.PageType !== "Eventpage");
    }
    shouldShowPageDescription()
    {
        return this.props.page.PageType === "Normal" || this.props.page.PageType === "Events";
    }
    handleImageSelect(img, close)
    {
        const stage = window.stageImage;

        if(stage)
        {
            stage.current.src = img.url;
            stage.current.removeAttribute("srcset");

            this.setState({
                selectedImage: img,
                modalIsOpen: false
            });

            this.dataBinder[this.props.primaryLanguage.short].updateProperty("HeaderImage", img);
        } else {
            this.setState({ modalIsOpen: false });
        }
    }
    // <h2 onClick={ this.props.onHeaderChanged }>Header click</h2>
    render() {
        const self = this;
        return (
            <div id="content" className="container">
                <div>
                    <div className="mo-stage-select">

                        <div className="textfield abstractComponent">
                            <PagePropEditor
                                title="Page title"
                                onChange={ this.handlePagePropChange}
                                visible={true}
                                pageProp="Pagetitle"
                                dataBinder={this.dataBinder}
                                {... this.props} />


                            {
                                (self.props.page.parent) ? null :  <PagePropEditor
                                    title="Description of page in menu"
                                    onChange={ this.handlePagePropChange}
                                    visible={this.shouldShowPageDescription()}
                                    pageProp="Description"
                                    dataBinder={this.dataBinder}
                                    {... this.props} />
                            }



                            {
                                (!this.shouldShowStageSelector()) ? null : (
                                    <React.Fragment>
                                        <Modal
                                            style={customStyles}
                                            isOpen={self.state.modalIsOpen}
                                            contentLabel="Example Modal"
                                            shouldCloseOnOverlayClick={ true }
                                            onRequestClose={() => self.setState({ modalIsOpen: false })}

                                        >
                                            <StagePreview close={() => self.setState({ modalIsOpen: false })} onImageSelect={ (e) => this.handleImageSelect(e) } />
                                        </Modal>
                                        <div className="btn btn-primary" onClick={() => self.setState({ modalIsOpen: true })}>Select new stage image</div>
                                    </React.Fragment>

                                )
                            }

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

//     <MyDropzone />
export default (props) =>
{
    const {  menu, page, language } = useRouteData();
    const { primaryLanguage, languages, languageMap } = useSiteData();

    return (
       <PageSettings
           dataBinder={props.dataBinder}
           page={page}
           menu={menu}
           languages={languages}
           language={language}
           languageMap={languageMap}
           onHeaderChanged={ props.onHeaderChanged }
           primaryLanguage={primaryLanguage}
       />
    )
};