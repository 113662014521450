// Imports
import plugin0 from '/webs/messe-sp-web/platform/frontend/node_modules/react-static-plugin-react-router/browser.api.js'

// Plugins
const plugins = [{
        location: "/webs/messe-sp-web/platform/frontend/node_modules/react-static-plugin-source-filesystem",
        plugins: [],
        hooks: {}
      },
{
        location: "/webs/messe-sp-web/platform/frontend/node_modules/react-static-plugin-react-router",
        plugins: [],
        hooks: plugin0({})
      },
{
        location: "/webs/messe-sp-web/platform/frontend",
        plugins: [],
        hooks: {}
      }]

// Export em!
export default plugins