import React from 'react'
import {Root, Routes, addPrefetchExcludes, useRouteData, useSiteData} from 'react-static'
//
import { Switch, Route, useLocation } from 'react-router-dom'
import Dynamic from 'containers/Dynamic'

import './styles/app.css'
import './styles/async_fonts.css'
import './styles/makaio.css'

addPrefetchExcludes(['dynamic'])

const FontFaceObserver = require('fontfaceobserver');
const moment = require('moment');


class App extends React.Component {

    componentDidMount() {

        const iconFont = new FontFaceObserver('mef-iconset-bold');
        const font2 =  new FontFaceObserver('Roboto-Regular');
        const font3 =  new FontFaceObserver('Roboto-Bold');
        const font4 =  new FontFaceObserver('Roboto-Light');

        Promise
            .all([
                iconFont.load(),
                font2.load(),
                font3.load(),
                font4.load()
            ])
            .then(function(){
                    document.documentElement.classList.remove("fonts-loading");
                    document.documentElement.className += " fonts-loaded";
            });
    }

    render() {

    return (
        <Root>
            <React.Suspense fallback={<em>Loading...</em>}>
                <Switch>
                    <Route path="/dynamic" component={Dynamic} />
                    <Route render={() => <Routes />} />
                </Switch>
            </React.Suspense>
        </Root>
    )
  }
}

export default App
