import React from "react";
import '../../../styles/makaio.css';
import {Translator} from "../../../translator";
import {getSpecialLinkUrl} from "../../../containers/Page";
import {useRouteData, useSiteData} from "react-static";
class ReferenceWithSpecials extends React.Component {
    constructor(props)
    {
        super(props);
        this.lang_key = props.language.short;

    }
    render()
    {

        let exh_page, vis_page, press_page;

        for(let menuItem of this.props.menu[this.lang_key])
        {
            if(menuItem.id === 4)
            {
                exh_page = menuItem.Slug;
            } else if(menuItem.id === 6)
            {
                vis_page = menuItem.Slug;
            } else if(menuItem.id === 8)
            {
                press_page = menuItem.Slug;
            }
        }


       let exh_headline = Translator.translate("For exhibitors", this.lang_key);
       let exh_text =  Translator.translate("Are you an exhibitor and would like to register for one of the upcoming trade fairs?", this.lang_key);
       let exh_link = `/${this.lang_key}/${exh_page}`;

       let vis_headline = Translator.translate("For visitors", this.lang_key);
       let vis_text = Translator.translate("Are you a visitor and would like to find out what possibilities you have?", this.lang_key);
       let vis_link = `/${this.lang_key}/${vis_page}`;


       let press_headline = Translator.translate("For journalists", this.lang_key);
       let press_text = Translator.translate("Always up to date. Here you can find general information for press at Messe Frankfurt.", this.lang_key);
       let press_link = `/${this.lang_key}/${press_page}`;



       let read_more_text = Translator.translate("More information", this.lang_key);


        return (
            <div className="reference abstractComponent">


                <div style={{display:"inline" }} className="cq-dd-paragraph">
                    <div className="teaserconversion_cop teaserConversion abstractComponent">


                        <div data-t-name="ConversionTeaser"
                             className="m-conversion-teaser h-background h-background--primary h-background--fullwidth"
                             data-t-id="74">


                            <div className="m-conversion-teaser__content">
                                <div className="m-conversion-teaser__item
                        m-conversion-teaser__item--first
                        ">



                                    <img src="/content/dam/exhibitors.svg" />

                                    <h4 dangerouslySetInnerHTML={ {__html: exh_headline } } />
                                    <p dangerouslySetInnerHTML={ {__html: exh_text } } />


                                    <a
                                       className="btn btn-inverted btn-default"
                                       href={exh_link}  dangerouslySetInnerHTML={ {__html: read_more_text } } />



                                </div>

                                <div className="m-conversion-teaser__item

                        ">


                                    <img src="/content/dam/visitors.svg" />

                                    <h4 dangerouslySetInnerHTML={ {__html: vis_headline } } />
                                    <p dangerouslySetInnerHTML={ {__html: vis_text } } />


                                    <a
                                       className="btn btn-inverted btn-default"
                                       rel="noopener noreferrer"
                                       href={vis_link}  dangerouslySetInnerHTML={ {__html: read_more_text } }/>


                                </div>

                                <div className="m-conversion-teaser__item">



                                    <img src="/content/dam/press.svg" />


                                    <h4 dangerouslySetInnerHTML={ {__html: press_headline } } />
                                    <p dangerouslySetInnerHTML={ {__html: press_text } } />

                                    <a
                                       className="btn btn-inverted btn-default" href={press_link} dangerouslySetInnerHTML={ {__html: read_more_text } }/>


                                </div>
                            </div>


                        </div>


                    </div>
                </div>


            </div>



        );
    }
}

export default function Reference(props){
    const { specialPages } = useSiteData();
    const { menu } =  useRouteData();

    return <ReferenceWithSpecials specialPages={specialPages} menu={menu} { ... props} />;
}