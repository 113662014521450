import React from 'react'
import {useRouteData, useSiteData} from 'react-static'
import Strapi from "strapi-sdk-javascript";
import Auth from "../modules/Auth";
import strapi from "../helper";
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
    withGoogleReCaptcha
} from "react-google-recaptcha-v3";


export  class LogoutForm extends React.Component {
    constructor(props)
    {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit()
    {
        if(this.props.onLogout)
        {
            this.props.onLogout();
        }
    }
    render()
    {
        return (

                <div>
                    <div className="m-rich-text " data-t-name="RichText">
                        You are currently logged in
                    </div>
                    <div className="textfield abstractComponent">

                        <span onClick={ this.handleSubmit } className="btn btn-default">Logout</span>

                    </div>
                </div>
        )
    }
}

export  class LoginForm extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            username: (this.props.user) ? this.props.user : '',
            pin: '',
            working: false,
            invalidLogin: false,
            errorCounter: 0
        };

        this.handlePinChange = this.handlePinChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.strapiConnection = new Strapi(process.env.STRAPI_PUBLIC_HOST);
    }
    handleUsernameChange(event)
    {
       this.setState({
           username: event.target.value
       });
    }
    handlePinChange(event)
    {
        const regex = /\d+$/gm;

        if(event.target.value && event.target.value.length > 0 && !event.target.value.match(regex))
            return;


        this.setState({
            pin: event.target.value
        });
    }
    async handleSubmit()
    {
        try {
            if(this.state.working)
                return;

            this.setState({
                working: true,
                invalidLogin: false
            });

            let token = this.props.token;
            const response = await this.strapiConnection.axios.post('/login', {
                identifier: this.state.username,
                password: this.state.pin,
                token
            });
            const login = response.data;

            console.log(`err: ${login.err}`);

            if(login.err)
            {
                if(login.err === 'INVALID_TOKEN')
                {
                    window.location = window.location;
                } else {
                    let errorCounter = this.state.errorCounter + 1;
                    this.setState({
                        working: false,
                        invalidLogin: true,
                        errorCounter: errorCounter
                    });

                    if(errorCounter === 3)
                    {
                        await strapi.request('POST', `${process.env.STRAPI_PUBLIC_HOST}/recover`);
                    }
                }
            } else if(login.jwt)
            {
                Auth.setToken(login.jwt);
                const location = window.location;
                window.location = location.origin;
            }

        }

        catch(e)
        {

        }

    }

    render()
    {
        return (
            <div className="radiobuttons abstractComponent">



                <div className="textfield abstractComponent">


                    <div className="a-form-input a-form-input--text" data-t-name="FormInput"
                         data-t-decorator="TextField" data-t-id="16">
                        <input className="js-form-input__clear-text " type="text" onChange={ this.handleUsernameChange} value={this.state.username}
                               required=""  maxLength="254" />
                        <label>Username </label>

                    </div>
                    <div className="a-form-input a-form-input--text" data-t-name="FormInput"
                         data-t-decorator="TextField" data-t-id="16">
                        <input className="js-form-input__clear-text " type="password" onChange={ this.handlePinChange } value={this.state.pin}
                               required="" maxLength="254" />
                        <label>Pin </label>

                    </div>

                    {
                        (!this.state.invalidLogin) ? null :
                            (
                                <div className="a-form-input--error">Invalid credentials.</div>
                            )
                    }
                    {
                        (this.state.pin && this.state.pin.length >= 6 && this.state.username && this.state.username.length > 0) ?
                            (
                                <span onClick={ this.handleSubmit } className="btn btn-default">Submit</span>
                            ) : null
                    }

                </div>
            </div>
        )
    }
}




export class LoginPage extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            token: null
        }

        this.handleLogout = this.handleLogout.bind(this);
    }
     componentDidMount() {
        this.props.googleReCaptchaProps.executeRecaptcha().then((token) => {
            this.setState({
                token
            });
        });
    }

    handleLogout()
    {
        Auth.setToken();
        this.forceUpdate();
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <header className="o-header o-header--fix" data-t-name="Header">

                        <div className="container">
                            <div className="o-header__wrap">
                                <div className="row">
                                    <div className="col-xxs-4 col-xxs-4--displayed-without-content" />
                                    <div className="col-xxs-4">
                                        <a className="o-header__corporate-logo" target="_blank" href="https://www.messefrankfurt.com/frankfurt/de.html">
                                            <picture>

                                                <source srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-big.svg"
                                                        media="(min-width: 1024px)" />
                                                <source
                                                    srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg"
                                                    media="(max-width: 1023px)" />
                                                <img
                                                    src="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg" />
                                            </picture>
                                        </a>
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>


                    </header>
                    <div className="o-header__color-bar" />
                    <div id="heading">
                        <div className="m-page-heading" data-t-name="PageHeading" data-t-id="9">
                            <div className="container">
                                <h1>Login</h1>
                            </div>
                        </div>
                    </div>
                    <div id="content" className="container">
                        <div>
                            <div>
                                {
                                    ( Auth.isAuthenticated()) ?
                                        (
                                            <LogoutForm onLogout={ this.handleLogout }/>
                                        ) : (
                                            <LoginForm siteId={this.props.siteId} token={this.state.token} user={this.props.user} executeRecaptcha={this.props.executeRecaptcha}/>
                                        )

                                }

                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        )
    }
}


const LoginPageWithCaptcha = withGoogleReCaptcha(LoginPage);

export  default  function  Login() {

    const { siteId, owner } = useSiteData();
    const { user } = useRouteData();

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lcm2qcZAAAAAK9ZPNqS2K0ZBse23hZG7zqexbBd" useRecaptchaNet={true}>
            <LoginPageWithCaptcha siteId={siteId} user={user} />
        </GoogleReCaptchaProvider>
        )

}
//
//         <GoogleReCaptchaProvider reCaptchaKey="6Lcm2qcZAAAAAK9ZPNqS2K0ZBse23hZG7zqexbBd" useRecaptchaNet={true}>
//             <YourReCaptchaComponent /></GoogleReCaptchaProvider>

//                                            <LoginForm siteId={this.props.siteId} user={this.props.user} executeRecaptcha={this.props.executeRecaptcha}/>