import React from "react";
import '../../styles/app.css';
export class TextAreaInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            shouldCheck: props.shouldCheck
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFocusLost = this.handleFocusLost.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.shouldCheck!==prevState.shouldCheck){
            return { shouldCheck: nextProps.shouldCheck};
        }
        else return null;
    }

    handleChange(ev)
    {
        this.props.item.value.updateValue(ev.target.value);
        this.setState(
            {
                value: ev.target.value
            }
        );
    }
    handleFocusLost()
    {
        this.setState(
            {
                shouldCheck: true
            }
        )
    }
    render() {
        let className = "a-form-textarea";
        let isInvalid = (this.state.shouldCheck && (!this.props.item.value.getValue() || this.props.item.value.getValue().length === 0));
        if(isInvalid)
            className += " a-form-textarea--invalid";

        let title = this.props.item.title;

        if(this.props.item.required)
            title += '*';

        return (
            <div className="textarea abstractComponent">

                        <textarea className={className} data-t-name="FormTextarea" onBlur={this.handleFocusLost}
                                  onChange={this.handleChange}
                                  rows="10" maxLength="1500" name={this.props.item.key} required
                                  placeholder={ title  } value={this.props.item.value.getValue()} />
                {
                    (isInvalid) ? (
                        <div className="a-form-input--error">{ this.props.errorMessage }</div>
                    ) : null
                }



            </div>
        );
    }
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            shouldCheck: props.shouldCheck
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFocusLost = this.handleFocusLost.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if(prevState.shouldCheck !== nextProps.shouldCheck){
            return {
                shouldCheck: nextProps.shouldCheck
            };
        } else {
            return null;
        }

    }

    handleChange(ev)
    {
        this.props.item.value.updateValue(ev.target.value);
        this.setState(
            {
                value: ev.target.value
            }
        );
    }
    handleFocusLost()
    {
        console.log("lost focus");
        this.setState(
            {
                shouldCheck: true
            }
        )
    }
    render() {
        let className ="a-form-input a-form-input--text ";
        let isInvalid = (this.state.shouldCheck && (!this.props.item.value.getValue() || this.props.item.value.getValue().length === 0));

        if(this.state.shouldCheck &&  (!isInvalid && this.props.item.key === 'mail' && !validateEmail(this.props.item.value.getValue())))
        {
            isInvalid = true;
        }
        if(isInvalid)
            className += "a-form-input--invalid";

        return (
            <div className="textfield abstractComponent">


                <div className={className} >
                    <input className="js-form-input__clear-text" type="text"
                           id={this.props.item.key + '_input' } name={this.props.item.key}
                           required="" placeholder={this.props.item.title} value={this.props.item.value.getValue()} onChange={ this.handleChange } onBlur={ this.handleFocusLost } maxLength="254" />
                    <label
                        htmlFor={this.props.item.key + '_input' } >{ this.props.item.title }
                        {
                            (this.props.item.required) ? '*' : ''
                        } </label>

                </div>
                {
                    (isInvalid) ? (
                        <div className="a-form-input--error">{ this.props.errorMessage }</div>
                    ) : null
                }
            </div>
        );
    }
}

export class RadioButtons extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            selectedValue: null,
            shouldCheck: props.shouldCheck
        };
        this.handleChange = this.handleChange.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.shouldCheck !== nextProps.shouldCheck){
            return {
                shouldCheck: nextProps.shouldCheck
            };
        } else {
            return null;
        }

    }
    handleChange(ev)
    {
        if(ev.target.checked) {
            this.props.item.value.updateValue(ev.target.value);

            this.setState({
                selectedValue: ev.target.value
            })
        }

    }
    render() {
        let isInvalid = (this.state.shouldCheck && !this.state.selectedValue);
        return (
            <div className="radiobuttons abstractComponent">
                <ul className="list-inline">
                    {
                        this.props.item.values.map((item,idx) => {
                            return (
                                <li className="a-form-input a-form-input--radio" key={idx}>
                                    <input type="radio" id={ `${item.key}_${idx}`}
                                           name={item.key} value={item.value} checked={this.state.selectedValue === item.value} onChange={this.handleChange} />
                                    <label htmlFor={`${item.key}_${idx}`}>
                                        { item.title }
                                    </label>
                                </li>)
                        })
                    }
                </ul>
                {
                    (isInvalid) ? (
                        <div className="a-form-input--error">{ this.props.errorMessage }</div>
                    ) : null
                }
            </div>
        )
    }
}