import React from 'react'
import {onReloadClientData, useRouteData, useSiteData} from 'react-static'
import Strapi from "strapi-sdk-javascript";
import Auth from "../modules/Auth";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {ModuleWrapper} from "../components/modules/ModuleWrapper";
import arrayMove from "array-move";
import strapi, {slugify} from "../helper";
import ContentDragHandle from "../components/admin/ContentDragHandle";
import '../styles/makaio.css';
import TextBlocks from "../components/admin/TextBlocks";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import EventAdmin from "../components/admin/EventAdmin";
import EventDetailAdmin from "../components/admin/EventDetailAdmin";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";

const uuidv1 = require('uuid/v1');

function bubbleOnAdd(onAdd, item, parentId, refContainer)
{
    onAdd(item, parentId);
    if(refContainer.current)
    {
        refContainer.current.classList.add("mo-page-add-container-blocked");
        refContainer.current.style = 'height: 20px';

        refContainer.current.onmouseout = function(ev)
        {
            ev.target.classList.remove("mo-page-add-container-blocked");
            ev.target.style = '';
        }
    }
}

const SortableItem = SortableElement(({menuItem,  isSubItem,  parentIdx, onAdd, onSorted, onDelete, onChange, onPagesChanged}) => {


    let subItems = menuItem.children;

    let className = "mo-page-admin page-item";
    if(isSubItem)
        className += " page-item-sub";

    let subContainer = null;

    let refAddContainer = React.createRef();

    let addItem = <React.Fragment>
        <div className="mo-edit-icon-container">
            <div className="mo-edit-icon-container-items" onClick={() => { bubbleOnAdd(onAdd, menuItem, parentIdx, refAddContainer)}}>
                <div className="mo-edit-icon">Y</div>
            </div>
        </div>
        <div className="mo-text-button" onClick={() => { bubbleOnAdd(onAdd, menuItem, parentIdx, refAddContainer)}}>Add new { (isSubItem) ? "subpage" : "page" } here</div>
    </React.Fragment>;


    if((!subItems || !subItems.length))
    {
        if(!isSubItem)
        {
            subContainer = <div className="mo-page-add-container" ref={refAddContainer}>
                <div className="mo-page-add-container-sub">
                    <div className="mo-edit-icon-container">
                        <div className="mo-edit-icon-container-items" onClick={() => { bubbleOnAdd(onAdd, menuItem, -1, refAddContainer)}}>
                            <div className="mo-edit-icon">Y</div>
                        </div>
                    </div>
                    <div className="mo-text-button" onClick={() => { bubbleOnAdd(onAdd, menuItem, -1, refAddContainer )}}>Add new subpage here</div>
                </div>
               <div className="mo-page-add-container-main">
                  { addItem }
               </div>

            </div>;
        } else {
            subContainer =  <div className="mo-page-add-container" ref={refAddContainer}>
                { addItem }
            </div>;
        }
    } else {
        subContainer =  <React.Fragment>
            <SortableSubPages parentId={menuItem.id} items={subItems}
                                          onSorted={ onSorted } onAdd={onAdd}
                                          onDelete={ (subItem) => { onDelete(subItem, menuItem) }} onChange={ onChange }
                                          onPagesChanged={ onPagesChanged }/>

            <div className="mo-page-add-container">{ addItem }</div>
        </React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className={className}>
                <ContentDragHandle />
                <div className="textfield abstractComponent">
                    <div className="a-form-input a-form-input--text" data-t-name="FormInput">
                        <input type="text" onChange={(ev) => onChange(menuItem, ev.target.value)} value={menuItem.Pagetitle} className="js-form-input__clear-text"  />
                    </div>

                    { subContainer}

                </div>
                {
                    (!menuItem.UserCreated) ? null : (
                        <div className="mo-edit-icon mo-edit-icon-remove" onClick={ () => onDelete(menuItem, parentIdx) }></div>
                    )
                }

            </div>

        </React.Fragment>
        )
});


const SortableList = SortableContainer(({items, siteTree, isSorting, parentId, isSubItem, onAdd, onSorted, onDelete, onChange, onPagesChanged}) => {
    let className = "";
    if(isSorting)
    {
        className = "mo-sortable-container mo-sortable-container-active"
    }

    if(!items)
        return null;

    return (
        <div className={className}>
            {
                items.filter((item) => !item.Deleted).map((item, index) => (
                    <React.Fragment key={`frag-${index}`}>
                        <SortableItem key={`item-${index}`}
                                      index={ index}
                                      idx={index}
                                      menuItem={item}
                                      onPagesChanged={(subitems) => onPagesChanged(subitems, item.id) }
                                      isSubItem={isSubItem}
                                      parentIdx={parentId}
                                      onDelete={onDelete}
                                      onSorted={ onSorted }
                                      onChange={ onChange }
                                      onAdd={(parentItem, parentIndex, bubblingListItems) => {onAdd(parentItem, parentIndex, bubblingListItems || items)}}
                            />

                    </React.Fragment>

                ))
            }
        </div>
    );
});

function parseTree(siteTree)
{
    /* get 1st level items */
    let mainPages = siteTree.filter((page) => {
        return (!page.parentId ) && (page.PageType === "Normal" );
    });

    for(let mainPage of mainPages)
    {
        let subItems = (siteTree) ?  siteTree.filter((page, idx) => {
            return page.parentId === mainPage.id;
        }) : null;

        if(subItems && subItems.length)
        {
            subItems.sort(function(a, b) {
                return a.Position - b.Position;
            });
        }

        mainPage.children = subItems;
    }

    return mainPages;
}
export class SortablePages extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            isEditing: Auth.isAuthenticated(),
            siteTree: props.siteTree,
            items: (props.siteTree) ? parseTree(props.siteTree) : props.items,
            sorting: false
        };

        this.onSortEnd = this.onSortEnd.bind(this);
        this.onSortStart = this.onSortStart.bind(this);
        this.onBeforeStart = this.onBeforeStart.bind(this);
        this.handlePageDelete = this.handlePageDelete.bind(this);
        this.handlePageAdd = this.handlePageAdd.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSubPagesChanged = this.handleSubPagesChanged.bind(this);
    }


    onSortEnd ({oldIndex, newIndex}) {
        const sortedArray = arrayMove(this.state.items, oldIndex, newIndex);
        let sort = 0;


        for(let item of sortedArray)
        {
            item.Position = 10+ (sort++);
            console.log(item.Pagetitle + ' Pos ' + item.Position.toString());
        }

        this.props.onPagesChanged(sortedArray);

        this.setState({
            items: sortedArray,
            isSorting: false
        });


        /*
        await strapi.request("POST", `pagecontents/move`, {
            data: sortedArray.map((item, index) => (
                {
                    position: index,
                    id: item.id
                }
            ))
        });
        */

        //this.props.onSorted(this.state.items, sortedArray);


    };
    onSortStart({node, index, collection, isKeySorting}, event)
    {

    }
    onBeforeStart()
    {
        this.setState({
            sorting: true
        })
    }
    shouldCancelSortStart(ev)
    {
        let target = ev.currentTarget;
    }
    handlePageDelete(item, parentItem)
    {
        if(item.UserCreated)
        {
            item.Deleted = true;
        }
        this.props.onPagesChanged(this.state.items);
        //this.props.onPagesChanged()
    }
    handlePageAdd(prevItem, parentIndex, items)
    {

        const newItem = {
            id: 0,
            PageType: 'Normal',
            Pagetitle: 'New page',
            parentId: parentIndex,
            parent: {
                id: parentIndex
            },
            Slug: uuidv1(),
            UserCreated: true
        };

        if(parentIndex === -1)
        {
            /* should create new subitem below previously child-less main menu item */
            let parentItem = prevItem;
            if(!parentItem.children)
                parentItem.children = [];

            newItem.parent = {
                id: parentItem.id
            };

            parentItem.children.push(newItem);
        } else {
            let prevIndex = items.indexOf(prevItem);

            items.splice(prevIndex+1, 0, newItem);

            /* refresh sort */
            let sort = 0;
            for (let item of items)
            {
                item.Position = sort++;
            }
        }



/*
        this.setState({
            items: items
        });*/


        this.props.onPagesChanged(items);
    }
    handlePageChange(item, title)
    {
        item.Pagetitle = title;
        item.Navigationtitle = item.Pagetitle;

        let slug = slugify(title);
        if(slug.length < 5)
        {
            slug = (item.id) ? item.id.toString()  : uuidv1();
        }
        item.Slug = slug;

        this.props.onPagesChanged(this.state.items);

        this.forceUpdate();
    }
    handleSubPagesChanged(subitems, parentItemId)
    {
        let itemsToBubble = this.state.items;

        let parentItemInState = itemsToBubble.find((item) => {
            return item.id === parentItemId;
        });

        parentItemInState.children = subitems;
        this.props.onPagesChanged(this.state.items);
    }
    render() {

        return <SortableList helperClass={"mo-sortable-item mo-sortable-content"}

                             useWindowAsScrollContainer={true} isSorting={this.state.sorting}
                             updateBeforeSortStart={ this.onBeforeStart }
                             shouldCancelStart={this.shouldCancelSortStart}
                             useDragHandle={true}
                             parentId={null}
                             onDelete={ this.handlePageDelete }
                             onAdd={ this.handlePageAdd }
                             onChange={ this.handlePageChange }
                             onPagesChanged={ this.handleSubPagesChanged }
                             items={this.state.items}  onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}
        />
    }
}

export class SortableSubPages extends SortablePages {
    constructor(props)
    {
        super(props);
    }

    render() {

        return   (
            <div className="mo-sortable-subpages">

                <SortableList helperClass={"mo-sortable-item mo-sortable-content"}
                              useWindowAsScrollContainer={true} isSorting={false}
                              updateBeforeSortStart={ this.onBeforeStart }
                              shouldCancelStart={this.shouldCancelSortStart}
                              useDragHandle={true}
                              onAdd={this.props.onAdd}
                              siteUrls={this.props.siteUrls }
                              language={this.props.language}
                              primaryLanguage={this.props.primaryLanguage}
                              parentId={this.props.parentId}
                              isSubItem={true}
                              onSorted={ this.props.onSorted }
                              onChange={ this.props.onChange }
                              onDelete={ this.props.onDelete }
                              items={this.state.items} onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}
                />
            </div>)
    }
}

export class PageAdmin extends React.Component {
    dataBinderTextblocks;
    modifiedPages;
    addedEventDetails;
    deletedEventDetails
    constructor(props)
    {
        super(props);

        this.state = {
            response: null,
            updating: false,
            selectedTab: 0
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTextblocksChanged = this.handleTextblocksChanged.bind(this);
        this.handlePagesChanged = this.handlePagesChanged.bind(this);
        this.confirmLeave = this.confirmLeave.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleEventDetailsChanged = this.handleEventDetailsChanged.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);
        const self = this;



        onReloadClientData(() => {

            self.state.hasChanges = false;
            if(self && !self.state.hasChanges)
            {
                if(!window.location.hash.includes('updated'))
                {
                    window.location.hash += (window.location.hash.startsWith('#')) ? 'updated' : '#updated';
                }
                window.location.reload();
            }
        });
    }
    componentDidMount() {
        const self = this;

        if(window.location.hash && window.location.hash.length > 1)
        {
            let hash = window.location.hash;
            let maybeIndex = Number(hash[1]);

            if(!isNaN(maybeIndex))
            {
                this.setState({
                    selectedTab: maybeIndex
                });

            }

            if(hash.includes('updated'))
            {
                toast.success('Saved successfully',{containerId: 'A'});
                window.location.hash = '#' + (isNaN(maybeIndex)) ? '' : maybeIndex.toString();
            }

        }
        window.onbeforeunload = function() {
            return self.confirmLeave();

        };
    }
    confirmLeave()
    {
        if(this.state.hasChanges)
        {
            return "You have unsaved changes, do you really want to leave the page?";
        }
    }
    handleLogout()
    {
        this.forceUpdate();
    }
    handleTextblocksChanged(dataBinder)
    {
        this.dataBinderTextblocks = dataBinder;

        this.setState({
            hasChanges: true
        });
    }
    handlePagesChanged(modifiedPages)
    {
        this.modifiedPages = modifiedPages;

        this.setState({
            hasChanges: true
        });
    }
    async handleSave(closeAfterSave)
    {


        await this.setState({
            updating: (this.modifiedPages || (this.dataBinderTextblocks &&
                this.dataBinderTextblocks.hasChanges &&
                this.dataBinderTextblocks.id))
        }, async () => {

            let rebuildRequired = false;

            if(this.dataBinderTextblocks &&
                this.dataBinderTextblocks.hasChanges &&
                this.dataBinderTextblocks.id)
            {
                let changes = this.dataBinderTextblocks.getChanges();

                await strapi.updateEntry('sitesettings', this.dataBinderTextblocks.id,  { data: changes })
                rebuildRequired = true;
            }

            if(this.eventsettings)
            {

                if(!this.eventsettings.id)
                {
                    this.eventsettings.id = (await strapi.createEntry('sitesettings',  this.eventsettings )).id;

                } else {
                    await strapi.updateEntry('sitesettings', this.eventsettings.id, this.eventsettings);
                }


                this.eventsettings = null;
            }


            if(this.addedEventDetails && this.addedEventDetails.length > 0)
            {
                for(let detail of this.addedEventDetails)
                {
                    let page = {
                        Pagetitle: detail.eventname,
                        Navigationtitle: detail.eventname,
                        Slug: detail.eventnumber,
                        PageType: 'Eventpage'
                    };
                    page = await strapi.createEntry('pages',  page );

                    let relation = {
                        pageId: page.id,
                        title: detail.eventname,
                        eventnumber: detail.eventnumber,
                    };
                    await strapi.createEntry('eventcontents',  relation );
                }

                this.addedEventDetails = null;
                rebuildRequired = true;
            }

            if(this.deletedEventDetails && this.deletedEventDetails.length > 0)
            {
                for(let event of this.deletedEventDetails)
                {
                    await strapi.deleteEntry("pages", event.pageId);
                    await strapi.deleteEntry('eventcontents',  event.relationId);
                }
                this.deletedEventDetails = null;
                rebuildRequired = true;
            }

            if(this.modifiedPages)
            {

                /* find new top level pages first */
                let modifiedPages = this.modifiedPages;
                let newTopLevels = modifiedPages.filter((item) => {
                    return item.id === 0 && item.parentId === null;
                });
                for(let newTopLevel of newTopLevels)
                {
                    delete newTopLevel.id;
                    newTopLevel.Description = "";
                    newTopLevel.Navigationtitle = newTopLevel.Pagetitle;
                    newTopLevel.Header = {};
                    newTopLevel.HeaderImage = {};
                    newTopLevel.ContentTranslation = {};

                    let res = await strapi.createEntry('pages', newTopLevel);
                    newTopLevel.id = res.id;
                    modifiedPages.splice(modifiedPages.indexOf(newTopLevel),1);



                    /* rescue subpages of new item */
                    if(newTopLevel.children)
                    {
                        for(let sub of newTopLevel.children)
                        {
                            if(!sub.parent)
                                sub.parent = {};

                            sub.parent.id = newTopLevel.id;
                            modifiedPages.push(sub);
                        }
                    }



                }
                let flattenedPages = [];
                for(let item of modifiedPages)
                {
                    flattenedPages.push(item);
                    if(item.children)
                    {
                        for(let child of item.children)
                        {
                            flattenedPages.push(child);
                        }
                    }
                }

                modifiedPages = flattenedPages;
                for(let page of modifiedPages)
                {
                    if(page.id > 0)
                    {
                        await strapi.updateEntry('pages', page.id, page);
                    } else {
                        page.Description = "";
                        page.Navigationtitle = page.Pagetitle;
                        page.Header = {};
                        page.HeaderImage = {};
                        page.ContentTranslation = {};

                        let res = await strapi.createEntry('pages', page);
                        page.id = res.id;
                    }
                }
                this.modifiedPages = null;
                rebuildRequired = true;
            }

            let hadChanges = this.state.hasChanges;
            this.setState({
                hasChanges: false
            }, () => {


                if(rebuildRequired)
                {

                    setTimeout(async () =>  {
                        await strapi.request("GET", `pages/1/rebuild`);
                        console.log('after rebuild');

                        if(closeAfterSave)
                            window.location.href= '/';
                    }, 100);
                } else if(hadChanges) {
                    this.setState({
                        updating: false
                    }, () => {
                        if(closeAfterSave)
                        {
                            window.location.href= '/';

                        } else {
                            toast.success('Saved successfully',{containerId: 'A'});
                        }

                    })
                }


            });



        });

    }
    handleEventChange(eventsettings)
    {
        this.eventsettings = eventsettings;
        this.setState({
            hasChanges: true
        });

    }
    handleEventDetailsChanged(addedRelations, deletedRelations)
    {
        this.addedEventDetails = addedRelations;
        this.deletedEventDetails = deletedRelations;

        this.setState({
            hasChanges: true
        })
    }
    handleTabSelect(index)
    {
        console.log('handleTabSelect: ' +  index.toString());
        if(typeof window.location !== 'undefined')
            window.location.hash = '#' + index.toString();

        this.setState({
            selectedTab: index
        })
    }
    render() {
        let buttonClass = "btn btn-primary btn-admin-save";

        if(!this.state.hasChanges)
        {
            buttonClass += " disabled";
        }

        let content = (
            <React.Fragment>
                <div className="o-header__color-bar" />
                <div id="heading">
                    <div className="m-page-heading" data-t-name="PageHeading" data-t-id="9">
                        <div className="container">
                            <span onClick={ () => this.handleSave(false) } className={buttonClass}>Save</span>
                            <span onClick={ () => this.handleSave(true) } className={buttonClass}>Save and close</span>
                            <h1>Admin</h1>

                        </div>
                    </div>
                </div>
                {
                    (!this.state.updating) ? null : (
                        <div className="container mo-page-admin">

                            <h2>Saving changes</h2>

                        </div>
                    )
                }
                {
                    ( Auth.isAuthenticated()) ?
                        (
                            <React.Fragment>
                                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                                <div id="content" className="container mo-page-admin" style={(!this.state.updating) ? {} : {visibility: 'hidden'}}>
                                    <Tabs selectedIndex={ this.state.selectedTab } forceRenderTabPanel={true} onSelect={ (index) => this.handleTabSelect(index) }>
                                        <TabList>
                                            <Tab>Pages & Navigation</Tab>
                                            <Tab>Text blocks</Tab>
                                            <Tab>Eventimport</Tab>
                                            <Tab>Eventdetails</Tab>
                                        </TabList>

                                        <TabPanel>
                                            <SortablePages onPagesChanged={this.handlePagesChanged} siteTree={this.props.siteTree} />
                                        </TabPanel>
                                        <TabPanel>
                                            <TextBlocks lang={this.props.primaryLanguage} onChange={this.handleTextblocksChanged} />
                                        </TabPanel>
                                        <TabPanel>
                                            <EventAdmin onChange={ this.handleEventChange } />
                                        </TabPanel>
                                        <TabPanel>
                                            <EventDetailAdmin primaryLanguage={this.props.primaryLanguage} onChanged={ this.handleEventDetailsChanged } />
                                        </TabPanel>
                                    </Tabs>


                                </div>

                            </React.Fragment>
                        ) : (
                            <h2>Access denied</h2>
                        )

                }
            </React.Fragment>
        );



        return (
            <React.Fragment>
                <main>
                    <header className="o-header o-header--fix" data-t-name="Header">

                        <div className="container">
                            <div className="o-header__wrap">
                                <div className="row">
                                    <div className="col-xxs-4 col-xxs-4--displayed-without-content" />
                                    <div className="col-xxs-4">
                                        <a className="o-header__corporate-logo" target="_blank" href="https://www.messefrankfurt.com/frankfurt/de.html">
                                            <picture>

                                                <source srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-big.svg"
                                                        media="(min-width: 1024px)" />
                                                <source
                                                    srcSet="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg"
                                                    media="(max-width: 1023px)" />
                                                <img
                                                    src="/content/dam/messefrankfurt-redaktion/ebu23/logo/messefrankfurt-small.svg" />
                                            </picture>
                                        </a>
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>


                    </header>
                    { content }

                </main>
            </React.Fragment>
        )
    }
}
export  default  function  () {

    const { siteId, owner, languages, primaryLanguage } = useSiteData();

    const {  menu, page, language, pages } = useRouteData();


    return (
        <PageAdmin siteTree={pages} language={language} primaryLanguage={primaryLanguage}/>
        )

}