export class Translator
{
    static dicts;
    static init(lang_dicts)
    {
        this.dicts = lang_dicts
    }
    static translate(key, lang)
    {
        return this.dicts[lang][key];
    }
}