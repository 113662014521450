/*  '50w': [50, 50],
  '150w': [150, 150],
  '300w': [300, 300],
  '480w': [480, 480],
  '768w': [768, 768],
  '1024w': [1024, 1024],
  '1280w': [1280, 1280],
  '1920w': [1920, 1920],
  '2560w': [2560, 2560],*/

import React from "react";
import { mf_ImageSizes } from "../../helper";
import Auth from "../../modules/Auth";



export default class StageSmall extends React.Component {
    constructor(props) {
        super(props);


        if(typeof document !== 'undefined')
        {
            window.stageImage = React.createRef();
        } else {
            this.stageImage = React.createRef();
        }



    }

    render() {

        let imageUrl;
        let headerItem;
        let cssClass = "m-stage--small";

        if(this.props.page.event && this.props.page.event.headerImage)
        {

            headerItem =   <img className="img-responsive"
                                src={this.props.page.event.headerImage }
                                ref={(typeof document !== 'undefined') ? window.stageImage : this.stageImage}
                                alt="" />;
            //cssClass = "m-stage--large";
        } else {
            if(this.props.page.Header)
            {
                imageUrl = this.props.page.Header.hash + this.props.page.Header.ext;
                if(this.props.page.Header.isShared)
                {

                    this.imageUrl = `${process.env.STRAPI_HOST_COMMON}/uploads/300w/${imageUrl}`;
                    this.imageSet = mf_ImageSizes.map((size) => {
                        return  `${process.env.STRAPI_HOST_COMMON}/uploads/${size}w/${imageUrl} ${size}w`
                    }).join(', ');
                } else {
                    this.imageUrl = `/content/uploads/300w/${imageUrl}`;
                    this.imageSet = mf_ImageSizes.map((size) => {
                        return  `/content/uploads/${size}w/${imageUrl} ${size}w`
                    }).join(', ');
                }


                headerItem =   <img className="img-responsive"
                                    src={this.imageUrl}
                                    srcSet={this.imageSet}
                                    ref={(typeof document !== 'undefined') ? window.stageImage : this.stageImage}
                                    alt="" />;
            } else if (Auth.isAuthenticated())
            {
                headerItem = <div ref={(typeof document !== 'undefined') ? window.stageImage : this.stageImage} className="img-responsive" style={{backgroundColor: 'red'}} />
            }
        }





        return (
            <div className={`m-stage ${cssClass}`} data-t-name="Stage" data-t-id="10">
                <div className="m-stage-item" data-t-name="StageItem">
                    <div className="a-image" data-t-name="Image" data-t-id="11">
                        <div className="a-image" data-t-name="Image" data-t-id="12">
                                <figure>
                                    { headerItem }
                                </figure>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}