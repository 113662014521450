

  
// Template Map
export default {
  '/webs/messe-sp-web/platform/frontend/node_modules/react-static/lib/browser/components/Default404': require('/webs/messe-sp-web/platform/frontend/node_modules/react-static/lib/browser/components/Default404').default,
'/webs/messe-sp-web/platform/frontend/src/containers/Page': require('/webs/messe-sp-web/platform/frontend/src/containers/Page').default,
'/webs/messe-sp-web/platform/frontend/src/containers/Login': require('/webs/messe-sp-web/platform/frontend/src/containers/Login').default,
'/webs/messe-sp-web/platform/frontend/src/containers/PageAdmin': require('/webs/messe-sp-web/platform/frontend/src/containers/PageAdmin').default
}

export const notFoundTemplate = '/webs/messe-sp-web/platform/frontend/node_modules/react-static/lib/browser/components/Default404'

