import React from "react";
import strapi from "../../helper";

class EventAdminItemList extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            items: props.items,
            selectedItems: props.selectedItems
        };

        this.isSelected = this.isSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {

        if(nextProps.items !== prevState.items ||
            nextProps.selectedItems !== prevState.selectedItems
            )
        {
            return {
                items: nextProps.items,
                selectedItems: nextProps.selectedItems
            }
        }
        return null;
    }
    isSelected(eventnumber)
    {
        return !!(this.state.selectedItems && this.state.selectedItems.indexOf(eventnumber) > -1);
    }
    handleChange(ev)
    {
        let eventnumber = ev.target.value;
        let selectedItems = this.state.selectedItems;

        if(ev.target.checked && selectedItems.indexOf(eventnumber) < 0)
        {
            selectedItems.push(eventnumber);
        } else if(!ev.target.checked)
        {
            let idx = selectedItems.indexOf(eventnumber);
            if(idx > -1)
            {
                selectedItems.splice(idx,1);
            }
        }
        this.forceUpdate();

        this.props.onChange(selectedItems);
    }
    render() {
        let items = [];

        for(let event of this.state.items)
        {
            items.push(
                <div key={event.eventnumber} className="mo-event-admin-item">
                    <input type="checkbox" value={ event.eventnumber }
                           onChange={ this.handleChange }
                           id={event.eventnumber}
                           name={event.eventnumber}
                           checked={this.isSelected(event.eventnumber)} />
                    <label htmlFor={event.eventnumber}>{event.eventname}</label>
                </div>
            )
        }
        return (
            <div>
                {
                    items
                }
            </div>
        )
    }
}
class EventAdminList extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            eventSettings: {
                data: {
                    importMode: '',
                    whitelist: [],
                    blacklist: [],
                },
                key: 'eventsettings',
            },
            eventItems: [],
        };

        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleItemsChange = this.handleItemsChange.bind(this);
    }
    async componentDidMount() {

        const sitesettings = await strapi.getEntries("sitesettings", { key: 'eventsettings'});
        let eventKeys = [];
        if(typeof window !== 'undefined')
        {
            if(!window.moeventkeys)
            {
                let req = await strapi.request('GET', `${process.env.STRAPI_PUBLIC_HOST}/eventkeys`);

                let allEventkeys = req;


                window.moeventkeys = allEventkeys;
            }
            //eventKeys = window.moeventkeys;
            for(let eventnumber of Object.getOwnPropertyNames( window.moeventkeys))
            {
                eventKeys.push(
                    {
                        eventnumber: eventnumber,
                        eventname: window.moeventkeys[eventnumber]
                    }
                )
            }
            eventKeys.sort(function(a, b) {
                const nameA = a.eventname.toUpperCase();
                const nameB = b.eventname.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;

            });
        }

        let eventSettings = {
            data: {
                importMode: 'all',
                whitelist: [],
                blacklist: []
            },
            key: 'eventsettings',
            id: 0
        };

        if(sitesettings && sitesettings.length > 0)
        {
            eventSettings = sitesettings[0];

        }
        this.setState({
            eventSettings: eventSettings,
            eventItems: eventKeys
        });
    }
    handleModeChange(ev)
    {

        let eventSettings = this.state.eventSettings;

        eventSettings.data.importMode = ev.target.value;
        this.props.onChange(this.state.eventSettings);
        this.setState({
            eventSettings: eventSettings
        });
    }
    handleItemsChange(selectedItems)
    {
        this.props.onChange(this.state.eventSettings);
    }
    render() {
        let list = null;

        if(this.state.eventSettings.data.importMode === 'whitelist')
        {
            list = <EventAdminItemList items={this.state.eventItems}
                                       onChange={this.handleItemsChange}
                                       selectedItems={this.state.eventSettings.data.whitelist} />;
        } else if(this.state.eventSettings.data.importMode === 'blacklist') {
            list = <EventAdminItemList items={this.state.eventItems}
                                       onChange={this.handleItemsChange}
                                       selectedItems={this.state.eventSettings.data.blacklist} />;
        }

        return (
            <div>
                <div className="textfield abstractComponent">
                    <p>Events are imported every night from messefrankfurt.com into your website's database. You can choose, if you want to import all events or only specific events.</p>
                    <p>The imported events will be shown at your Event Search page.</p>
                </div>
                <div className="radiobuttons abstractComponent">


                    <ul className="list-inline">

                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="14">
                            <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0"
                                   name="Location" value="all" checked={this.state.eventSettings.data.importMode === 'all'} onChange={this.handleModeChange} />
                            <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0">

                                Import&nbsp;<strong>all events</strong>&nbsp;from messefrankfurt.com
                            </label>
                        </li>

                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="15">
                            <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1"
                                   name="Location" value="whitelist"  checked={this.state.eventSettings.data.importMode === 'whitelist'} onChange={this.handleModeChange} />
                            <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1">

                                Import&nbsp;<strong>only selected</strong>&nbsp;events

                            </label>
                        </li>
                        <li className="a-form-input a-form-input--radio" data-t-name="FormInput" data-t-id="165">
                            <input type="radio" id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2"
                                   name="Location" value="blacklist" checked={this.state.eventSettings.data.importMode === 'blacklist'} onChange={this.handleModeChange} />
                            <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2">

                                <strong>Don't import selected</strong>&nbsp;events
                            </label>
                        </li>
                    </ul>


                </div>
                <div className="textfield abstractComponent">
                    { list }


                </div>

            </div>
        );
    }
}

export default (props) => {
    return <EventAdminList onChange={ props.onChange } />;
}