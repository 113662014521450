import React, {useCallback} from "react";
import Obfuscate from "react-obfuscate";
import Auth from "../../../modules/Auth";
import strapi, {ResponsiveImage} from "../../../helper";
import {DataBinder} from "../../admin/DataBinder";
import ContentDragHandle from "../../admin/ContentDragHandle";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";
import {ModuleHead} from "../ModuleHead";

import EditableModule from "../Module";

let ContentEditTools;
let useDropzone;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
    useDropzone = require('react-dropzone').useDropzone;
}



function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
            accept: 'image/png, image/jpg, image/jpeg'
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                {
                    (img) ? <ResponsiveImage width="200px" img={img}/> : (
                        <span>Drop a file here, or click to select a file from your local files</span>)

                }
            </div>
        )
    }
}

class ContactTeaserItemEdit extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            item: props.item,
            index: props.index
        };
        this.handleItemEdit = this.handleItemEdit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.item !== prevState.item || nextProps.index !== prevState.index)
        {
            return {
                item: nextProps.item,
                index: nextProps.index
            };
        } else {
            return null;
        }
    }
    handleItemEdit(prop, val)
    {
        let item = this.state.item;
        item[prop] = val;

        this.setState({
            item: item
        });
    }
    handleRemove()
    {
        this.props.onRemove(this.state.item);
    }
    handleRemoveImage()
    {
        let item = this.state.item;
        item.image = null;
        this.setState({
            item: item
        });
    }
    async handleDrop(acceptedFiles)
    {

        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id
            };

            await strapi.request('GET', `/generateThumbnail/${uploadedFile.hash}${uploadedFile.ext}`);

            let item = this.state.item;
            item.image = uploadedFile;
            this.setState({
               item: item
            });
        }
    }
    render() {
        return (
            <div className="mo-contact-item-edit">
                <div className="mo-edit-icon-container">
                    <div className="mo-edit-icon-container-items">
                        <ContentDragHandle />
                        <div className="mo-edit-icon" onClick={this.handleRemove}></div>

                    </div>
                </div>
                <div className="textfield abstractComponent image-edit-container mo-contact-image-edit">
                    <strong>Image:</strong><br/>

                    {
                        (!this.state.item.image) ? null : (
                            <div className="mo-edit-icon-container">
                                <div className="mo-edit-icon-container-items">
                                    <div className="mo-edit-icon" onClick={this.handleRemoveImage}></div>
                                </div>
                            </div>
                        )
                    }

                    <MyDropzone img={this.state.item.image} onDrop={this.handleDrop} />

                </div>

                {
                    this.props.formItems.map((formItem) => {

                        return (
                            <div className="textfield abstractComponent">
                                <div className="a-form-input a-form-input--text "><input
                                    className="js-form-input__clear-text" type="text" id={`${formItem.prop}_${(this.state.index || "new").toString()}`}
                                    name="name" placeholder={formItem.title} maxLength="254" onChange={(e) => this.handleItemEdit(formItem.prop, e.target.value)} value={this.state.item[formItem.prop]} /><label
                                    htmlFor={`${formItem.prop}_${(this.state.index || "new").toString()}`}>{formItem.title}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )

    }
}

const SortableItem = SortableElement(({item, index, formItems, onRemove}) => {

    return (   <ContactTeaserItemEdit index={index} item={item} formItems={formItems} onRemove={onRemove}  />)
});
const SortableList = SortableContainer(({items, formItems, isSorting, onRemove}) => {
    let className = "";
    if(isSorting)
    {
        className = "mo-sortable-container mo-sortable-container-active"
    }
    /*
     {
                (!items) ? null : items.map((item, idx) => {
                   return <ContactTeaserItemEdit item={item} idx={idx} formItems={formItems} />
                })
            }
     */
    return (
        <div className={className}>
            {
                items.map((item, index) => (
                    <React.Fragment key={`frag-${index}`}>
                        <SortableItem key={`item-${index}`}
                                      item={item}
                                      index={index}
                                      onRemove={(item) => onRemove(item, index)}
                                      formItems={formItems} />

                    </React.Fragment>

                ))
            }
        </div>
    );
});

export default class ContactTeaser extends EditableModule {
    constructor(props) {
        super(props);

        if(!props.content.items)
            props.content.items = [];


        this.state = {
            content: props.content,
            itemsOriginal: props.content.items,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
            dataBinder: new DataBinder(props.content)
        };

        this.formItems = [
            {
                prop: 'name',
                title: 'Name'
            },
            {
                prop: 'title',
                title: 'Title'
            },
            {
                prop: 'phone',
                title: 'Phone'
            },
            {
                prop: 'fax',
                title: 'Fax'
            },
            {
                prop: 'mail',
                title: 'Mail'
            },
        ];

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);

        this.renderItems = this.renderItems.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);
        this.onBeforeStart = this.onBeforeStart.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleItemRemove = this.handleItemRemove.bind(this);
    }
    static createForReference(refName)
    {
        return <ContactTeaser blockEdit={true} referenceName={refName} content={
            {
                headline: "Contacts",
                items: [
                    {
                        name: 'Jane Doe',
                        title: 'Marketing'
                    },
                    {
                        name: 'John Doe',
                        title: 'Sales'
                    }

                ]
            }} siteUrls={[]} />;
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {




            //this.state.dataBinder.updateProperty("items", this.state.content.items);

            let changes =this.state.dataBinder.getChanges();

            if(changes)
            {
                let content;

                let contentUpdate = {
                    content: this.state.content
                };




                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = this.state.content;
                    contentUpdate.ContentTranslation[this.props.language.short].headline = changes.headline;
                    contentUpdate.ContentTranslation[this.props.language.short].items = changes.items;


                    content =  contentUpdate.ContentTranslation[this.props.language.short];
                } else {
                    contentUpdate.content.headline = changes.headline;
                    contentUpdate.content.items = changes.items;

                    content = contentUpdate.content;

                }



                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );
                this.exitEditState(content);
            } else {
                this.cancelEditState();
            }



        } else {
            this.enterEditState();
        }
    }
    renderItems() {

        return (
            <React.Fragment>

                {
                    this.state.content.items.map((item, index) => {
                        return (<div key={index} className="contactTeaserItem abstractComponent">


                            <div className="m-contact-teaser-item" data-t-name="ContactTeaserItem" data-t-id="17">
                                <div className="m-contact-teaser-item__contact-person">
                                    <h4>{item.name}</h4>
                                    <p>{item.title}</p>
                                </div>
                                {
                                    (!item.image) ? null : (
                                        <div className="m-contact-teaser-item__image">


                                            <div className="a-image" data-t-name="Image" data-t-id="18">
                                                <figure>
                                                   <ResponsiveImage img={item.image} />
                                                </figure>
                                            </div>


                                        </div>
                                    )
                                }

                                <div className="m-contact-teaser-item__contact-data">
                                    <div className="m-contact-teaser-item__contact-data--phone">
                                        <p>

                                            <Obfuscate
                                                tel={item.phone} />
                                        </p>
                                    </div>
                                    {
                                        (!item.fax) ? null : (
                                            <div className="m-contact-teaser-item__contact-data--fax">
                                                <p>
                                                    <Obfuscate
                                                        tel={item.fax} />
                                                </p>
                                            </div>
                                        )
                                    }

                                    <div className="m-contact-teaser-item__contact-data--email">
                                        <p className="visible-xs">
                                            <Obfuscate
                                                email={item.mail} />
                                        </p>
                                        <p className="hidden-xs">
                                            <Obfuscate
                                                email={item.mail} />
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>)
                    })
                }



            </React.Fragment>
        )
    }
    handleAddItemClick() {
        let items = this.state.dataBinder.bind("items").getValue();
        if(!items)
            items = [];

        items.push({});
       
        this.state.dataBinder.updateProperty("items", items);
        this.forceUpdate();
    }
    handleItemRemove(item, index) {
        let items = this.state.dataBinder.bind("items").getValue();
        items.splice(index,1);


        this.state.dataBinder.updateProperty("items", items);
        this.forceUpdate();
    }
    handleItemEdit(item, prop) {

    }
    onBeforeStart()
    {
        this.setState({
            sorting: true
        })
    }
    onSortEnd = async ({oldIndex, newIndex}) =>  {

        let items = this.state.dataBinder.bind("items").getValue();

        const sortedArray = arrayMove(items, oldIndex, newIndex);


        /*
        await strapi.request("POST", `pagecontents/move`, {
            data: sortedArray.map((item, index) => (
                {
                    position: index,
                    id: item.id
                }
            ))
        });
        */

        this.state.dataBinder.updateProperty("items", sortedArray);
        this.forceUpdate();

    };
    renderEdit() {

        let items = this.state.dataBinder.bind("items").getValue() || [];


        return <div><SortableList  useDragHandle={true} items={items} formItems={this.formItems}
                                   helperClass={"mo-sortable-item mo-sortable-content"}
                                   onSortEnd={this.onSortEnd}
                                   updateBeforeSortStart={ this.onBeforeStart }
                                   useWindowAsScrollContainer={true} isSorting={this.state.sorting}
                                   onRemove={this.handleItemRemove}
        >


        </SortableList><span className="btn btn-default" onClick={this.handleAddItemClick}>Add contact</span></div>;
    }
    render() {

        return (<div className="contactTeaser abstractComponent" data-reference={ this.props.referenceName }>

                <div className="m-contact-teaser" data-t-name="ContactTeaser" data-t-id="16">
                    {
                        (!this.state.allowEdit) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onDelete={this.handleDelete }
                                    showMove={!this.state.edit}
                                    showSettings={ false }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }

                    <ModuleHead
                        edit={ this.state.edit }
                        binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null }
                        headline={ this.state.content.headline } />
                    <div className="m-contact-teaser__container">


                        {
                            (this.state.edit) ? this.renderEdit() : this.renderItems()
                        }
                    </div>
                </div>


        </div>)
    }
}