export class DataBinder
{
    constructor(content, callback) {
        if(!content)
        {
           this.content = {};
        } else if(typeof content === "string")
        {
            this.content = JSON.parse(content);
        } else {
            this.content = content;
        }
        this.callback = callback;
        this.update(this.content);
    }
    getRawContent()
    {
        let content = this.content;
        if(this.changeSet)
        {
            Object.assign(content, this.changeSet);
        }
        return content;
    }
    update(source)
    {
        this.changeSet = {};
        Object.assign(this.changeSet, source);
        this.hasChanges = false;
    }
    bind(property)
    {
        const self = this;
        return {
            dataBinder: this,
            property: property,
            updateValue: (val) => {
               self.updateProperty(property, val);
            },
            getValue: () => {
                let propSplit = property.split('.');
                if(propSplit.length > 1)
                {
                    let targetObject = self.changeSet;
                    let sub_prop = property.replace(propSplit[0]+'.', '');

                    targetObject = targetObject[propSplit[0]][sub_prop];

                    if(!targetObject)
                        return '';
                    return targetObject;
                }

                return self.changeSet[property];
            }
        }
    }
    updateProperty(property, val)
    {
        const self = this;
        let content = self.changeSet;
        self.hasChanges = true;

        let propSplit = property.split('.');
        if(!content)
            content = {};

        if(propSplit.length > 1)
        {
            let sub_prop = property.replace(propSplit[0] + '.', '');
            content[propSplit[0]][sub_prop] = val;
        } else {
            content[property] = val;
        }

        if(self.callback)
            self.callback(self);
    }
    getChanges(){
        if(!this.hasChanges)
            return null;

        let changes = this.changeSet;

        this.hasChanges = false;

        return changes;
    }
}
