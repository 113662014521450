import React from "react";
import {ModuleHead} from "../ModuleHead";
import strapi, {mf_ImageSizes, ResponsiveImage} from "../../../helper";
import Auth from "../../../modules/Auth";
import '../../../styles/makaio.css';
import MfLink from "../../ui/MfLink";
import {replaceLinks} from "../../../containers/Page";
import EditableModule from "../Module";
import {useDropzone} from "react-dropzone";

let ContentEditTools;
let RichTextEditor;
let HeadlineEditor;
let EditIconLink;
if(process.env.NODE_ENV !== 'production') {
    ContentEditTools = require('../../admin/ContentEditTools').default;
    RichTextEditor = require('../../admin/RichTextEditor').default;
    HeadlineEditor = require('../../admin/HeadlineEditor').default;
    EditIconLink = require('../../admin/EditIconLink').default;

}


function MyDropzone({img, onDrop}) {
    if(process.env.NODE_ENV !== 'production') {
        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            onDrop,
            accept: 'image/png, image/jpg, image/jpeg'
        });

        return (
            <div {...getRootProps()} className="mo-image-upload">
                <input {...getInputProps()} />
                {
                    (img) ? <ResponsiveImage width="100%" img={img}/> : (
                        <span>Drop a file here, or click to select a file from your local files</span>)

                }
            </div>
        )
    }
}


export class Teaser extends EditableModule {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content,
            dataBinder: null,
            allowEdit: (props.blockEdit) ? false : Auth.isAuthenticated(),
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);

        this.handleDrop = this.handleDrop.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);

        this.handleClick = this.handleClick.bind(this);
    }
    static createForReference(refName)
    {
        return <Teaser blockEdit={true} referenceName={refName} content={
            {
                headline: 'Teaser with image',
                image: {
                    hash: '4a47d8e494014edda20d447b9aa3d3f0',
                    ext: '.jpg'
                },
                button: 'Button',
                text: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. \n' +
                    '</p>'}} siteUrls={[]} />;
    }
    handleRemoveImage()
    {
        this.state.dataBinder.bind('image').updateValue(null);
        this.forceUpdate();
    }
    async handleDrop(acceptedFiles)
    {

        if(acceptedFiles.length > 0)
        {

            let file = acceptedFiles[0];

            const form = new FormData();
            form.append('files', file, file.name);
            const files = await strapi.upload(form);
            const uploadedFile = {
                hash: files[0].hash,
                ext: files[0].ext,
                id: files[0].id
            };

            await strapi.request('GET', `/generateThumbnail/${uploadedFile.hash}${uploadedFile.ext}`);

            let imageBound = this.state.dataBinder.bind("image");

            imageBound.updateValue(uploadedFile);

            this.forceUpdate();
        }
    }
    handleCancelClick()
    {
        this.cancelEditState();
    }
    async handleEditClick()
    {
        if(this.state.edit)
        {
            const changes = this.state.dataBinder.getChanges();

            if(changes)
            {let contentUpdate = { };
                if(this.props.language && this.props.language.short !== this.props.primaryLanguage.short)
                {
                    contentUpdate = await this.prepareContentTranslation();
                    contentUpdate.ContentTranslation[this.props.language.short] = changes;

                } else {
                    contentUpdate = {

                        content: changes

                    };

                }
                await strapi.updateEntry("pagecontents", this.props.id, contentUpdate );

                this.exitEditState(changes);

            } else {
                this.cancelEditState();
            }

        } else {
            this.enterEditState();
        }
    }
    handleClick()
    {
        if(!this.state.allowEdit)
        {

        }
    }
    render() {


        if(this.state.content.image)
        {
            const imageUrl = this.state.content.image.hash + this.state.content.image.ext;
            this.imageUrl = `/content/uploads/300w/${imageUrl}`;
            this.imageSet = mf_ImageSizes.map((size) => {
                return  `/content/uploads/${size}w/${imageUrl} ${size}w`
            }).join(', ');
        } else {

        }

        let imageContainer;


        if(this.state.edit)
        {
            if(this.state.dataBinder.bind("image").getValue())
            {
                imageContainer =
                    <div className="image-edit-container">
                        <strong>Image:</strong><br/>
                        <div className="mo-accordion-item-inner mo-accordion-item-inner-edit">
                            <div className="mo-edit-icon-container">
                                <div className="mo-edit-icon-container-items">
                                    <div className="mo-edit-icon" onClick={this.handleRemoveImage}></div>
                                </div>
                            </div>
                            <MyDropzone img={this.state.dataBinder.bind("image").getValue()} onDrop={this.handleDrop} />
                        </div>
                    </div>
            } else {
                imageContainer = <div className="image-edit-container">
                    <strong>Image:</strong><br/>
                    <MyDropzone onDrop={this.handleDrop} />
                </div>;
            }
        } else {
            imageContainer = <figure>
                <img className="img-responsive"
                     src={this.imageUrl}
                     srcSet={this.imageSet}
                     alt="" />
            </figure>;
        }


        let textContent = replaceLinks(this.state.content.text, this.props.siteUrls);
        const content = (
            <div className="m-teaser__inner" >

                <div className="a-image" data-t-name="Image" data-t-id="16">
                    {
                        imageContainer
                    }

                </div>

                <div className="m-teaser__content">
                    {
                        (!this.state.allowEdit) ?  null :
                            (
                                <ContentEditTools
                                    edit={this.state.edit}
                                    onCancel={ this.handleCancelClick }
                                    onEditClick={this.handleEditClick }
                                    onDelete={this.handleDelete }
                                    onChange={this.handleHeadlineChange}
                                    showMove={!this.state.edit}
                                    showSettings={ false }
                                    showDelete={this.props.userCreated}
                                />
                            )

                    }

                    <ModuleHead
                        edit={ this.state.edit }
                        binding={ (this.state.dataBinder) ? this.state.dataBinder.bind("headline") : null}
                        headline={ this.state.content.headline } />

                    {
                        (this.state.edit) ? <RichTextEditor binding={ this.state.dataBinder.bind("text")} /> :
                            (
                                <div dangerouslySetInnerHTML={ {__html: textContent } }  />
                            )
                    }



                        <div className="btn btn-default">
                            {
                                (this.state.edit) ?  <HeadlineEditor binding={this.state.dataBinder.bind("button")} headlineTag="span" /> : (
                                    <span dangerouslySetInnerHTML={ {__html: this.state.content.button }} />
                                )
                            }

                        </div>


                    {
                        (!this.state.edit) ? null : (
                            <EditIconLink  link={ this.state.dataBinder.bind("link")} />
                        )
                    }

                </div>
            </div>
        );

        const contentWrapped = <MfLink enabled={!this.state.edit} link={this.state.content.link}>
            { content }
        </MfLink>;

        return (
            <div className="teaserFullWidth abstractTeaser abstractComponent" data-reference={ this.props.referenceName }>

                <div className="m-teaser m-teaser--single h-background h-background--fullwidth"
                     data-t-name="Teaser" data-t-id="15">
                    { ( Auth.isAuthenticated() ) ? content :
                        contentWrapped
                    }

                </div>


            </div>
        )
    }
}

/*
    /<Link className="m-teaser__link" to="/">
                            { content }
                        //</Link>
 */