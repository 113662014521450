import React from "react";
import Auth from "../../modules/Auth";

export default class Publisher extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            output: []
        };


    }
    componentDidMount() {

        // Create WebSocket connection.
        const socket = new WebSocket('wss://publisher.salespartner.network');

        const self = this;
// Connection opened

        socket.addEventListener('close', function (event) {
            let output = self.state.output;
            output.push("Disconnected");

            self.setState({
                output: output
            });

        });

        socket.addEventListener('error', function (event) {
            let output = self.state.output;
            output.push("Error:"+JSON.stringify(event, ["message", "arguments", "type", "name"]));
            self.setState({
                output: output
            });

        });

        socket.addEventListener('open', function (event) {
            let output = self.state.output;
            output.push("Connected");

            self.setState({
                output: output
            });

            const payload = {
                "action": "publish",
                "site":  process.env.SUBDOMAIN,
                "token":  Auth.getToken()
            };


            socket.send(JSON.stringify(payload));
        });

// Listen for messages
        socket.addEventListener('message', function (event) {
            let output = self.state.output;

            let msg = event.data.toString();
            if(msg.length > 2)
            {
                output.push(event.data);
            } else {
                output[output.length-1] += msg;
            }

            self.setState({
                output: output
            })
        });
    }

    render()
    {

        return (
            <div>
                <h1>Publish</h1>
                <p>
                    {
                        this.state.output.map((item) => {
                            return <div>{item}</div>;
                        })
                    }
                </p>
            </div>
        );
    }
}