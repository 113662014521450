import React from "react";
import '../../styles/makaio.css';
import {useRouteData, useSiteData} from "react-static";
import {isRTL} from "../../helper";

const CKEditor = require('@ckeditor/ckeditor5-react');
const InlineEditor = require('@ckeditor/ckeditor5-build-inline');

function plainTextToHtml( text, wrapTag ) {
    text = text
    // Encode <>.
        .replace(/<[^>]*>/gm, '')
        .replace( /</g, '&lt;' )
        .replace( />/g, '&gt;' )
        // Creates paragraphs for double line breaks and change single line breaks to <br>s.
        //.replace( /\n\n/g, '</p><p>' )
        .replace( /\n/g, '<br>' )
        // Preserve trailing spaces (only the first and last one – the rest is handled below).
        .replace( /^\s/, '&nbsp;' )
        .replace( /\s$/, '&nbsp;' )
        // Preserve other subsequent spaces now.
        .replace( /\s\s/g, ' &nbsp;' );


        // If we created paragraphs above, add the trailing ones.
        text = `<${wrapTag}>${ text }</${wrapTag}>`;



    // TODO:
    // * What about '\nfoo' vs ' foo'?

    return text;
}

class BoldToB {
    constructor( editor ) {
        this.editor = editor;
    }

    init() {
        this.editor.model.schema.register( 'div', {
            inheritAllFrom: '$block',
            isBlock: true,
        } );
        this.editor.conversion.elementToElement( { model: 'div', view: 'div' });

        this.editor.model.schema.register( 'span', {
            inheritAllFrom: '$block',
            isBlock: true,
        } );
        this.editor.conversion.elementToElement( { model: 'span', view: 'span' });

    }
}

class HeadlineEditorInternal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (<CKEditor
            editor={ InlineEditor }
            config={{
                language: (isRTL(this.props.language)) ? 'ar' : 'en',
                toolbar: (isRTL(this.props.language)) ? ['direction'] : [],
                extraPlugins: [BoldToB],
                placeholder: '(Empty headline)',
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                    ]
                }
            }
            }
            data={`<${this.props.headlineTag}>${(this.props.binding && this.props.binding.getValue()) ? this.props.binding.getValue() : this.props.headline || ""}</${this.props.headlineTag}>`}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                if(this.props.onChange)
                {
                    const regex = /^<[^>]*>|<[^>]*>$/gm;
                    const dataWithoutWrap = data.replace(regex, ''); // remove wrap

                    this.props.onChange(dataWithoutWrap);
                }

                if(this.props.binding)
                {
                    const regex = /^<[^>]*>|<[^>]*>$/gm;
                    const dataWithoutWrap = data.replace(regex, ''); // remove wrap

                    this.props.binding.updateValue(dataWithoutWrap);
                }
            } }
            onInit={ editor => {
                const clipboardPlugin = editor.plugins.get( 'Clipboard' );
                const editingView = editor.editing.view;

                const panel = editor.ui.view.panel.element;

                if (!isRTL(this.props.language))
                    panel.setAttribute("data-hide","true");

                editor.editing.view.focus();

                editor.editing.view.document.on( 'enter', ( evt, data ) => {
                    if ( data.isSoft ) {
                        editor.execute( 'enter' );
                    } else {
                        editor.execute( 'shiftEnter' );
                    }

                    data.preventDefault();
                    evt.stop();
                    editor.editing.view.scrollToTheSelection();
                }, { priority: 'high' } );


                editingView.document.on( 'clipboardInput', ( evt, data ) => {
                    if ( editor.isReadOnly ) {
                        return;
                    }

                    const dataTransfer = data.dataTransfer;

                    let content = plainTextToHtml( dataTransfer.getData( 'text/plain' ), this.props.headlineTag);

                    content = clipboardPlugin._htmlDataProcessor.toView( content );

                    clipboardPlugin.fire( 'inputTransformation', { content, dataTransfer } );

                    editingView.scrollToTheSelection();
                    evt.stop();
                } );

                editor.plugins.get( 'Clipboard' ).on( 'inputTransformation', ( evt, data ) => {
                    const inData = editor.getData();
                    const outData = plainTextToHtml(inData,this.props.headlineTag);


                    const model = editor.model;
                    const schema = model.schema;

                    editor.setData(outData);

                    model.change( writer => {
                        //debugger;
                    } );
                } , { priority: 'lowest' });

            }}
        />)
    }
}

export default function HeadlineEditor (props)
{
    const {  language  } =  useRouteData();
    const { primaryLanguage } = useSiteData();


    return <HeadlineEditorInternal primaryLanguage={primaryLanguage} language={language} { ... props }  />;
}
